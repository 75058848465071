














import { CategoryUserModel, CorrectionManagerModel, TenantUserBaseModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import Page from '@/Page.vue';
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';

@Component({})
export default class CategoryUserList extends Page {

    @Prop() tenantId!: string;
    @Prop() categoryId!: string;
    
    @VModel() tenantUsers!: TenantUserBaseModel[];

}
