import NotificationService from '@/libs/core/notifications/notification-service';
import {Plugin} from 'vuex'
import {createStoreAction} from '@/libs/core/+state/functions/create-store-action';
import {createStoreGetter} from '@/libs/core/+state/functions/create-store-getter';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {CrudEntity} from '@/libs/core/+state/models/crud-service';
import {CrudAction} from '@/libs/core/+state/models/crud-action';
import {BusinessModule} from '@/libs/core/models/business-module';
import globalStore from '@/libs/core/+state/store';
import { PredicateBuilder } from '../functions/predicate-builder';

export function createNotificationPlugin(): Plugin<any> {
    const getStore = (storeName: BusinessModule) => ({
        action: createStoreAction(storeName),
        getter: createStoreGetter(storeName)
    });

    return store => {
        NotificationService.created.subscribe({
            next: notification => {
                const state = globalStore.state as any;
                if (state[notification.objectType] && state[notification.objectType].data) {
                    Object.keys(state[notification.objectType].data).forEach((e: string) => {
                        const query = state[notification.objectType].dataQuery[e].data.predicates;
                        if (query.length == 0 || PredicateBuilder.GetExpression(query)(notification.item)) {
                            const store = getStore(notification.objectType);
                            const data: CrudEntity[] = store.getter(CrudGetter.Data, notification.relations[e]);
                            if (data !== undefined) {
                                store.action(CrudAction.GetAll, {
                                    key: notification.relations[e], query: 'same', extra: {
                                        ignoreKeySuffix: true,
                                        command: notification.command
                                    }
                                })
                            }
                            /*const dataBasic: CrudEntity[] = store.getter(CrudGetter.DataBasic, notification.relations[e]);
                            if (data !== undefined) {
                                store.action(CrudAction.GetAllBasic, {
                                    key: notification.relations[e], query: 'same', extra: {
                                        ignoreKeySuffix: true,
                                        command: notification.command
                                    }
                                })
                            }*/
                        }
                    });
                }
                /*debugger;
                const store = getStore(notification.objectType);
                const relations = notification.relations || {'': null};
                const allData = store.getter(CrudGetter.Data);
                Object.keys(relations).forEach(relationKey => {
                    const data: CrudEntity[] = store.getter(CrudGetter.Data, notification.relations[relationKey]);
                    if (data !== undefined) {
                        store.action(CrudAction.GetAll, {
                            key: notification.relations[relationKey], query: 'same', extra: {
                                ignoreKeySuffix: true,
                                command: notification.command
                            }
                        })
                    }
                });*/
            },
            complete() {
                console.log('STORE NOTIFICATION', 'CREATED COMPLETE');
            },
            error(err: any) {
                console.error('STORE NOTIFICATION CREATED ERROR', err);
            }
        });

        NotificationService.updated.subscribe({
            next: notification => {
                const store = getStore(notification.objectType);
                const relations = notification.relations || {'': null};
                Object.keys(relations).forEach(relationKey => {
                    const data: CrudEntity[] = store.getter(CrudGetter.Data, notification.relations[relationKey]);
                    if (data !== undefined) {
                        store.action(CrudAction.GetAll, {
                            key: notification.relations[relationKey], query: 'same', extra: {
                                ignoreKeySuffix: true,
                                command: notification.command
                            }
                        })
                    }
                });
                store.action(CrudAction.PartialUpdate, {
                    id: notification.objectId,
                    key: notification.objectId,
                    diff: notification.diff,
                    item: notification.item,
                    extra: {
                        local: true,
                        ignoreKeySuffix: true,
                        command: notification.command
                    }
                })
            },
            complete() {
                console.log('STORE NOTIFICATION', 'UPDATE COMPLETE');
            },
            error(err: any) {
                console.error('STORE NOTIFICATION UPDATE ERROR', err);
            }
        });

        NotificationService.deleted.subscribe({
            next: notification => {
                const store = getStore(notification.objectType);

                const relations = notification.relations || {'': null};
                Object.keys(relations).forEach(relationKey => {
                    const data: CrudEntity[] = store.getter(CrudGetter.Data, notification.relations[relationKey]);
                    const detail: CrudEntity[] = store.getter(CrudGetter.Detail, notification.relations[relationKey]);

                    if (data?.find(item => item.id === notification.objectId) || detail) {
                        store.action(CrudAction.Delete, {
                            id: notification.objectId,
                            key: notification.relations[relationKey],
                            extra: {
                                ignoreKeySuffix: true,
                                command: notification.command
                            }
                        })
                    }
                });
            }, complete() {
                console.log('STORE NOTIFICATION', 'DELETE COMPLETE');
            },
            error(err: any) {
                console.error('STORE NOTIFICATION DELETE ERROR', err);
            }
        })
    };
}
