









































































































































































































































import { ApplicationUserModel, ImplementationStateEnum, MeasureModel, TenantUserModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { measuresStore } from '@/libs/measures/+state/store';
import { verificationsStore } from '@/libs/verification/+state/store';

@Component({
    components: {
    }
})
export default class Measures extends Page {

    filterCategory: Array<string> | null = null;
    filterAudit: Array<string> | null = null;
    filterUser: Array<string> | null = null;
    filterDepartment: Array<string> | null = null;
    filterPosition: Array<string> | null = null;
    filterWorkplace: Array<string> | null = null;
    filterImplementationState: Array<string> | null = null;
    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    filterIsImplementationClosed = null;
    mobileFiltersShow = false;
    pageIndex = 1;
    pageSize = 25;
    pagingChanged = false;

    get headers() {
        return [
            /*{ text: this.$i18n.t("issues.time"), value: "time", align: "left", sortable: false },
            { text: this.$i18n.t("issues.audit.name"), value: "audit.name", align: "left", sortable: false },
            { text: this.$i18n.t("issues.user.displayName"), value: "user.displayName", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.workplace.name"), value: "workplace.name", align: "left", sortable: false },
            { text: this.$i18n.t("issues.implementationState"), value: "isImplementationClosed", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },*/
            { text: this.$i18n.t("issues.time"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.audit.name"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.audit.number"), value: "evidenceNumber", align: "left", sortable: false },
            { text: this.$i18n.t("issues.user.displayName"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.workplace.name"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.shiftType.name"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.question.code"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.repairmentDescription"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.implementer"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.implementUntil"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.implementationTime"), value: "id", align: "left", sortable: false },
            { text: this.$i18n.t("issues.implementationState"), value: "id", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false }
        ];
    }

    @Prop() tenantId!: string;

    @Watch("filterCategory")
    @Watch("filterAudit")
    @Watch("filterUser")
    @Watch("filterDepartment")
    @Watch("filterPosition")
    @Watch("filterWorkplace")
    @Watch("filterImplementationState")
    filtersChanged () {
        this.pageIndex = 1;
        this.load();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged () {
        this.saveQuery();
        if (this.pagingChanged) {
            this.pagingChanged = false;
            return;
        }
        this.load();
    }

    get loading(): boolean {
        return measuresStore.useGetter(CrudGetter.Pending);
    }
    
    get issues (): MeasureModel[] {
        return [...measuresStore.useGetter(CrudGetter.Data)];
    }

    get totalRows (): number {
        return ({...measuresStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
    }

    get downloading(): boolean {
        return verificationsStore.useGetter(CrudGetter.Downloading);
    }

    mounted () {
        if (this.getQueryInt("pageSize") != null) {
            this.pageSize = this.getQueryInt("pageSize") ?? 25;
        }
        else {
            if (window.localStorage.ptPageSize && Number(window.localStorage.ptPageSize)) {
                this.pageSize = Number(window.localStorage.ptPageSize);
            }
            this.saveQuery();
        }
        this.load();
    }

    load () {
        measuresStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        const tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
        let filters: CrudQueryPredicate[] = [
            /*{ field: "responseLock", op: "eq", comparand: true },
            { field: "hasCorrection", op: "eq", comparand: true },
            */
           { field: "implementationState", op: "in", comparand: [ ImplementationStateEnum.WaitingForVerification, ImplementationStateEnum.Verified ] },
           { field: "measureVerificationUsers.tenantUserId", op: "eq", comparand: tenantUser.id! }
        ];
        let auditFilters: CrudQueryPredicate[] = [];
        let userFilters: CrudQueryPredicate[] = [];
        let departmentFilters: CrudQueryPredicate[] = [];
        let positionFilters: CrudQueryPredicate[] = [];
        let workplaceFilters: CrudQueryPredicate[] = [];
        let categoryFilters: CrudQueryPredicate[] = [];
        let implementationStateFilters: CrudQueryPredicate[] = [];
        if (!this.tenantUser.accessAllCategories) {
            filters.push({ field: "finding.response.planItem.audit.category.categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
        if (this.filterCategory && this.filterCategory.length > 0) {
            this.filterCategory.forEach((e: string) => {
                if (e) {
                    categoryFilters.push({
                        field: "finding.response.planItem.audit.categoryId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterAudit && this.filterAudit.length > 0) {
            this.filterAudit.forEach((e: string) => {
                if (e) {
                    auditFilters.push({
                        field: "finding.response.planItem.auditId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterUser && this.filterUser.length > 0) {
            this.filterUser.forEach((e: string) => {
                if (e) {
                    userFilters.push({
                        field: "finding.response.planItem.tenantUserId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterDepartment && this.filterDepartment.length > 0) {
            this.filterDepartment.forEach((e: string) => {
                if (e) {
                    departmentFilters.push({
                        field: "finding.response.planItem.tenantUser.departmentId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterPosition && this.filterPosition.length > 0) {
            this.filterPosition.forEach((e: string) => {
                if (e) {
                    positionFilters.push({
                        field: "finding.response.planItem.tenantUser.positionId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterWorkplace && this.filterWorkplace.length > 0) {
            this.filterWorkplace.forEach((e: string) => {
                if (e) {
                    workplaceFilters.push({
                        field: "finding.response.planItem.workplaceId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterImplementationState && this.filterImplementationState.length > 0) {
            this.filterImplementationState.forEach((e: string) => {
                if (e) {
                    implementationStateFilters.push({
                        field: "implementationState",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (auditFilters.length > 0) {
            filters.push({
                field: "finding.response.planItem.auditId",
                op: "wrap",
                comparand: auditFilters
            });
        }
        if (categoryFilters.length > 0) {
            filters.push({
                field: "finding.response.planItem.audit.categoryId",
                op: "wrap",
                comparand: categoryFilters
            });
        }
        if (userFilters.length > 0) {
            filters.push({
                field: "finding.response.planItem.tenantUserId",
                op: "wrap",
                comparand: userFilters
            });
        }
        if (departmentFilters.length > 0) {
            filters.push({
                field: "finding.response.planItem.tenantUser.departmentId",
                op: "wrap",
                comparand: departmentFilters
            });
        }
        if (positionFilters.length > 0) {
            filters.push({
                field: "finding.response.planItem.tenantUser.positionId",
                op: "wrap",
                comparand: positionFilters
            });
        }
        if (workplaceFilters.length > 0) {
            filters.push({
                field: "finding.response.planItem.workplaceId",
                op: "wrap",
                comparand: workplaceFilters
            });
        }
        if (implementationStateFilters.length > 0) {
            filters.push({
                field: "implementationState",
                op: "wrap",
                comparand: implementationStateFilters
            });
        }
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<MeasureModel>([ { field: "implementationState", index: 1, order: "asc" }, { field: "finding.response.planItem.time", index: 2, order: "asc" } ], this.getFilters(), {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        });
    }

    exportClick () {
        const filters = this.getFilters();
        verificationsStore.dispatch(CrudAction.Download, createCrudQueryPayload<MeasureModel>({ field: "implementUntil", index: 1, order: "asc" }, filters));
    }

    saveQuery () {
        this.setQuery("pageSize", this.pageSize?.toString());
        window.localStorage.ptPageSize = this.pageSize?.toString();
    }

}
