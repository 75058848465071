




















import { GroupUserModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import Page from '@/Page.vue';
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';

@Component({})
export default class GroupUserList extends Page {

    @Prop() tenantId!: string;
    @Prop() groupId!: string;
    @Prop() exceptUsers!: string[];
    
    @VModel() groupUsers!: GroupUserModel[];

    private addClick () {
        const items = [...this.groupUsers];
        items.push({
            id: Guid.EmptyGuid(),
            tenantUserId: Guid.EmptyGuid(),
            groupId: this.groupId
        } as GroupUserModel);
        this.$emit("input", items);
    }

    mounted () {
        if (this.groupUsers.length == 0) {
            this.addClick();
        }
    }

    private deleteItem (index: number) {
        this.groupUsers.splice(index, 1);
    }

}
