var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-picker"},[(!_vm.readonly)?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:{ 'dense': _vm.dense, 'mt-0': _vm.dense, 'pt-0': _vm.dense },style:({
            maxWidth: _vm.maxWidth > 0 ? _vm.maxWidth + 'px' : 'auto'
          }),attrs:{"append-icon":"mdi-calendar","rules":_vm.rules,"required":_vm.required,"hide-details":_vm.hideDetails,"placeholder":_vm.placeholder,"dense":_vm.dense},on:{"click:append":function($event){_vm.menu = true}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.label)?_c('span',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"red--text"},[_vm._v("*")]):_vm._e()]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},'v-text-field',attrs,false),on))]}}],null,false,2595479073),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$t('common.localeProp'),"min":_vm.min,"max":_vm.max,"first-day-of-week":1},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_c('v-text-field',{class:{ 'dense': _vm.dense, 'mt-0': _vm.dense, 'pt-0': _vm.dense },style:({
        maxWidth: _vm.maxWidth > 0 ? _vm.maxWidth + 'px' : 'auto'
      }),attrs:{"readonly":"","rules":_vm.rules,"required":_vm.required,"hide-details":_vm.hideDetails,"placeholder":_vm.placeholder,"dense":_vm.dense},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.label)?_c('span',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"red--text"},[_vm._v("*")]):_vm._e()]):_vm._e()]},proxy:true}]),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }