










import { FileModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import Page from '@/Page.vue';
import axios from 'axios';
import { getAccessToken } from 'axios-jwt';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

@Component({})
export default class FilePreview extends Page {

	deleteDialog = false;
	blobUrl = "";
	loading = false;

	@Prop() file!: FileModel;

	@Watch("file")
	fileChanged() {
		this.getThumbnail(this.file);
	}

	mounted() {
		if (this.file)
			this.getThumbnail(this.file);
	}

	getThumbnail(file: FileModel) {
		this.loading = true;
		const tenantId = authStore.useGetter(AuthGetter.GetTenant);
		const token = getAccessToken();
		axios({
			url: (window as any).ApiService.baseUrl + "/api/files/" + file.id! + "/download-thumbnail",
			method: 'GET',
			responseType: 'blob', // important,
			headers: {
				"TenantId": tenantId,
				"Authorization": `Bearer ${token}`
			}
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			this.blobUrl = url;
			this.loading = false;
		}).catch((e: any) => {
			return "";
		});
	}

	imageClick (e: any) {
		this.$nextTick(() => {
			if (!this.deleteDialog)
				this.$emit("imageClick");
		});
	}

}
