import {RouteConfig} from 'vue-router';

import Audits from './pages/Audits.vue';
import Audit from './pages/Audit.vue';
import AuditGeneral from './pages/General.vue';
import AuditQuestions from './pages/Questions.vue';
import AuditPlan from './pages/Plan.vue';
import AuditCalendar from './pages/Calendar.vue';
import { PermissionTypeEnum } from '../Api';

export default [
    {
        path: '/:tenantId/audits',
        name: 'Audits',
        component: Audits,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit, PermissionTypeEnum.PerformAudit ],
            checkLicence: true,
            title: "menu.audit"
        }
    },
    {
        path: '/:tenantId/audits/:auditId',
        name: 'Audit',
        component: Audit,
        props: (route) => ({ tenantId: route.params.tenantId, auditId: route.params.auditId }),
        children: [
            {
                path: '/:tenantId/audits/:auditId/general',
                name: 'AuditGeneral',
                component: AuditGeneral,
                props: (route) => ({ tenantId: route.params.tenantId, auditId: route.params.auditId }),
                meta: {
                    permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit ],
                    checkLicence: true,
                    title: "menu.audit"
                }
            },
            {
                path: '/:tenantId/audits/:auditId/questions',
                name: 'AuditQuestions',
                component: AuditQuestions,
                props: (route) => ({ tenantId: route.params.tenantId, auditId: route.params.auditId }),
                meta: {
                    permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit ],
                    checkLicence: true,
                    title: "menu.audit"
                }
            },
            {
                path: '/:tenantId/audits/:auditId/plan',
                name: 'AuditPlan',
                component: AuditPlan,
                props: (route) => ({ tenantId: route.params.tenantId, auditId: route.params.auditId }),
                meta: {
                    permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit ],
                    checkLicence: true,
                    title: "menu.audit"
                }
            },
            {
                path: '/:tenantId/audits/:auditId/calendar',
                name: 'AuditCalendar',
                component: AuditCalendar,
                props: (route) => ({ tenantId: route.params.tenantId, auditId: route.params.auditId }),
                meta: {
                    permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit ],
                    checkLicence: true,
                    title: "menu.audit"
                }
            }
        ]
    }
] as RouteConfig[];