
























import {Component, Vue, Prop, PropSync, Watch} from 'vue-property-decorator';
@Component
export default class Pagination extends Vue {

    @Prop() totalRows!: number;
    @Prop({default: true}) darkInput!: boolean;
    @PropSync('pageIndex') myPageIndex!: number;
    @PropSync('pageSize') myPageSize!: number;

    @Watch("myPageIndex")
    myPageIndexChanged (v: number) {
        this.$emit("update:pageIndex", v);
    }

    @Watch("myPageSize")
    myPageSizeChanged (v: number) {
        this.$emit("update:pageSize", v);
    }

    get pages(): number {
        return this.totalRows && this.myPageSize && this.myPageSize > 0 ? Math.ceil(this.totalRows / this.myPageSize) : 0;
    }

}
