import {RouteConfig} from 'vue-router';
import { PermissionTypeEnum } from '../Api';
import Verification from './pages/Verification.vue';

export default [
    {
        path: '/:tenantId/verification',
        name: 'Verification',
        component: Verification,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.MeasureVerification ],
            checkLicence: true,
            title: "menu.verificationLong"
        }
    }
] as RouteConfig[];