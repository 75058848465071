



























import Page from '@/Page.vue';
import { Component, Prop } from 'vue-property-decorator';

import DashboardMainPanel from '@/libs/dashboard/components/DashboardMainPanel.vue';
import DashboardDonePlanItems from '@/libs/dashboard/components/DashboardDonePlanItems.vue';
import DashboardWaitingPlanItems from '@/libs/dashboard/components/DashboardWaitingPlanItems.vue';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { TenantUserModel } from '@/libs/Api';

@Component({
    components: {
        DashboardMainPanel, DashboardDonePlanItems, DashboardWaitingPlanItems, Swiper, SwiperSlide
    },
    directives: {
      swiper: directive
    }
})
export default class Dashboard extends Page {

    tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    get swiperOptions(): any {
        return {
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        }
    }

    @Prop() tenantId!: string;
}
