































import Page from '@/Page.vue';
import { Component, Prop, PropSync, Ref, VModel, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { ApplicationUserModel, MeasureModel, PlanItemModel, PlanItemStateEnum, TenantUserModel } from '@/libs/Api';
import { measuresStore } from '@/libs/measures/+state/store';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Guid } from '@/libs/common/functions/guid';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import moment from 'moment';

@Component({
    components: {
    }
})
export default class WorkTaskDeclineDialog extends Page {

    @VModel() dialog!: boolean;

    /* Component state variables */
    valid = true;
    saving = false;
    hasChanges = true;
    reviewDeclineReason: string | null = null;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    mounted () {
        const planItem = planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel;
        this.reviewDeclineReason = planItem.reviewDeclineReason ?? null;
    }

    @Ref() form!: any;
    
    /* Action functions */
    
    closeDialog () {
        this.$emit("input", false);
        this.dialog = false;
    }
    
    decline () {
        if (this.form.validate()) {
            this.saving = true;
            const planItem = {...planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel};
            planItem.planItemState = PlanItemStateEnum.Declined;
            planItem.reviewedById = this.tenantUser.id;
            planItem.reviewDeclineReason = this.reviewDeclineReason;
            planItem.reviewedOn = moment().format("YYYY-MM-DDTHH:mm:ss");
            planItemsStore.dispatch(CrudAction.Update, { item: planItem });
            this.subscribe(planItemsStore, CrudReponse.Update).then((e) => {
                this.saving = false;
                this.closeDialog();
            }).catch((e) => {
                this.saving = false;
                console.log(e);
            });
        }
    }

}
