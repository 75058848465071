import {BusinessModule} from '@/libs/core/models/business-module';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import store from '@/libs/core/+state/store';
import {isFunction} from 'lodash';

export function createStoreGetter(storeName: BusinessModule) {
    return (getter: CrudGetter, ...params: any[]) => {
        const val = store.getters[`${storeName}/${getter}`];
        return isFunction(val) ? val(...params) : val;
    }
}

