









import { MeasureTypeEnum, PlanItemStateEnum, RepeatFrequencyEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class PlanItemStateChip extends Vue {

    @VModel() innerValue!: PlanItemStateEnum;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) white!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) clearable!: boolean;
    @Prop({ default: false }) onlyMeasures!: boolean;
    @Prop({ default: 0 }) maxWidth!: number;

    get chipColor () {
        switch (this.innerValue) {
            case PlanItemStateEnum.WaitingToPerform:
                return "orange";
            case PlanItemStateEnum.NeedsToCreateMeasures:
                return "cyan";
            case PlanItemStateEnum.NeedsToPerformMeasures:
                return "cyan";
            case PlanItemStateEnum.NeedsToVerifyMeasures:
                return "cyan";
            case PlanItemStateEnum.AllMeasuresVerified:
                return "cyan";
            case PlanItemStateEnum.PerformedWithoutFindings:
                return "cyan";
            case PlanItemStateEnum.WaitingForApproval:
                return "cyan";
            case PlanItemStateEnum.Approved:
                return "green";
            case PlanItemStateEnum.Declined:
                return "red";
            case PlanItemStateEnum.Closed:
                return "green";
            case PlanItemStateEnum.ClosedWithoutFindings:
                return "green";
        }
        return "black";
    }

}
