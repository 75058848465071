
















import { CodebookModel, PermissionTypeEnum, TenantModel, TenantUserModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { tenantsStore } from '@/libs/tenants/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import Page from '@/Page.vue';
import moment from 'moment';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class BadLicence extends Page {
    
    get isValidLicenceToDate () {
        const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
        if (tenant) {
            const licenceToDate = tenant.licenceToDate ? moment(tenant.licenceToDate) : moment().add(-1, "days");
            return licenceToDate.isSameOrAfter(moment())
        }
        return null;
    }

    get licenceToDate () {
        const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
        if (tenant) {
            return tenant.licenceToDate ? moment(tenant.licenceToDate) : moment().add(-1, "days");
        }
        return null;
    }

    get isTenantUserActive () {
        const tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
        if (tenantUser) {
            return tenantUser.isActive;
        }
        return false;
    }

}
