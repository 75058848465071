

























import { WorkplaceModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { isArray } from 'lodash';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import Autocomplete from '../components/Autocomplete.vue';
import { workplacesStore } from '@/libs/workplaces/+state/store';

@Component({})
export default class WorkplaceSelect extends Vue {

    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop() placeholder!: string;
    @Prop() attach!: string;

    @Ref() autocomplete!: Autocomplete;

    get items(): WorkplaceModel[] {
        return [...(workplacesStore.useGetter(CrudGetter.Data) as WorkplaceModel[])].sort((a: WorkplaceModel, b: WorkplaceModel) => {
            if(a.name! < b.name!) { return -1; }
            if(a.name! > b.name!) { return 1; }
            return 0;
        });
    }

}
