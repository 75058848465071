import {
    HttpResponse,
    CodebookModel,
    QueryModel,
    RequestParams,
    CodebookModelIEnumerableApiResultSuccess,
    CodebookModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class CodebooksDataService implements CrudService<CodebookModel, CodebookModel> {
    getAll(query: CrudDataQuery<CodebookModel>): Promise<CrudEnumerableResponse<CodebookModel>> {
        return ApiService.api.getCodebookBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<CodebookModel>): Promise<CrudEnumerableResponse<CodebookModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<CodebookModel>> {
        return ApiService.api.getCodebookById(id, params);
    }

    create(item: CodebookModel, params?: RequestParams): Promise<CrudResponse<CodebookModel>> {
        throw "NotImpelemented";
    }

    update(item: CodebookModel, params?: RequestParams): Promise<CodebookModel> {
        throw "NotImpelemented";
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<CodebookModel> {
        throw "NotImpelemented";
    }

    delete(query: CrudDataQuery<CodebookModel>): Promise<CrudEnumerableResponse<CodebookModel>> {
        throw "NotImpelemented";
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw "NotImplemented";
    }

    download(query: CrudDataQuery<CodebookModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: CodebookModel, params?: RequestParams): Promise<CrudResponse<CodebookModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        return ApiService.api.getCodebookGroupped(query, params);
    }

}
