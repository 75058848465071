import { ApplicationUserModel, PermissionTypeEnum, TenantUserModel } from "@/libs/Api";
import { AuthenticationType } from "./auth-type";

export enum AuthAction {
    SignIn = 'SIGN_IN',
    SignInResponse = 'SIGN_IN_RESPONSE',
    SignUp = 'SING_UP',
    SignUpResponse = 'SING_UP_RESPONSE',
    SignOut = 'SIGN_OUT',
    ActivateAccount = 'ACTIVATE_ACCOUNT',
    ActivateAccountResponse = 'ACTIVATE_ACCOUNT_RESPONSE',
    ResetPassword = 'RESET_PASSWORD',
    ResetPasswordResponse = 'RESET_PASSWORD_RESPONSE',
    ResetSetNewPassword = 'RESET_SET_NEW_PASSWORD',
    SetPassword = 'SET_PASSWORD',
    SetPasswordResponse = 'SET_PASSWORD_RESPONSE',
    SetNewPassword = 'SET_NEW_PASSWORD',
    SetTenant = 'SET_TENANT',
    ResetSetNewPasswordResponse = 'RESET_SET_NEW_PASSWORD_RESPONSE',
    UserInfo = 'USER_INFO',
    SetTenantUser = 'SET_TENANT_USER',
    SetSupportedAuthenticationTypes = 'SET_SUPPORTED_AUTHENTICATION_TYPES',
    SetAuthenticationType = 'SET_AUTHENTICATION_TYPE',
    SetAzureTenantId = 'SET_AZURE_TENANT_ID',
    SetAzureAppId = 'SET_AZURE_APP_ID',
    SetIsSingleTenant = 'SET_IS_SINGLE_TENANT'
}


export enum AuthGetter {
  GetIsLoggedIn='GET_IS_LOGGED_IN',
  GetUserInfo='GET_USER_INFO',
  GetTenant='GET_TENANT',
  GetTenantUser="GET_TENANT_USER",
  GetPermissions="GET_PERMISSIONS",
  GetSupportedAuthenticationTypes = 'GET_SUPPORTED_AUTHENTICATION_TYPES',
  GetAuthenticationType = 'GET_AUTHENTICATION_TYPE',
  GetAzureTenantId = 'GET_AZURE_TENANT_ID',
  GetAzureAppId = 'GET_AZURE_APP_ID',
  GetIsSingleTenant = 'GET_IS_SINGLE_TENANT'
}

export class AuthState {
    isLoggedIn = false;
    userInfo: any = null;
    tenant: string | null = null;
    tenantUser: TenantUserModel | null = null;
    permissions: PermissionTypeEnum[] = [];
    supportedAuthenticationTypes: AuthenticationType[] = [];
    authenticationType: AuthenticationType = AuthenticationType.Standard;
    azureTenantId: string | null = null;
    azureAppId: string | null = null;
    isSingleTenant = false
}
