










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Loader extends Vue { 
    @Prop() centered!: boolean;
    @Prop() main!: boolean;
}
