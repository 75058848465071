



















































import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CategoryModel, FindingModel, ImplementationStateEnum, MeasureModel, PlanItemModel, TenantUserModel } from '@/libs/Api';
import WorkTaskVerifyMeasureItem from '../components/WorkTaskVerifyMeasureItem.vue';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { measuresStore } from '@/libs/measures/+state/store';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { categoriesStore } from '@/libs/categories/+state/store';
import { findingsStore } from '@/libs/findings/+state/store';
import WorkTaskMeasureItem from "@/libs/work-tasks/components/WorkTaskMeasureItem.vue";

@Component({
    components: {
      WorkTaskMeasureItem,
        WorkTaskVerifyMeasureItem
    }
})
export default class WorkTaskVerifyTab extends Page {

    /* Page props */
    @Prop() id!: string;
    @Prop() tenantId!: string;

    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    
    /* Model variables */
    planItem: PlanItemModel | null = null;
    category: CategoryModel | null = null;
    findings: FindingModel[] = [];

    /* Component state variables */
    isLoading = false;

    get mineMeasures(): MeasureModel[] {
        const measures = [...measuresStore.useGetter(CrudGetter.Data)] as MeasureModel[]
        return measures
            .where(x => x.measureVerificationUsers!.any(n => n.tenantUserId == this.tenantUser.id))
            .orderBy(x => x.finding?.response?.question?.questionGroup?.position ?? 1)
            .thenBy(x => x.finding?.response?.question?.position ?? 1)
            .thenBy(x => x.finding?.response?.sequenceIndex ?? 1)
            .thenBy(x => x.finding?.sequenceIndex ?? 1)
            .thenBy(x => x.sequenceIndex ?? 1)
            .toArray();
    }

    get otherMeasures(): MeasureModel[] {
        const measures = [...measuresStore.useGetter(CrudGetter.Data)] as MeasureModel[]
        return measures
            .where(x => !x.measureVerificationUsers!.any(n => n.tenantUserId == this.tenantUser.id))
            .orderBy(x => x.finding?.response?.question?.questionGroup?.position ?? 1)
            .thenBy(x => x.finding?.response?.question?.position ?? 1)
            .thenBy(x => x.finding?.response?.sequenceIndex ?? 1)
            .thenBy(x => x.finding?.sequenceIndex ?? 1)
            .thenBy(x => x.sequenceIndex ?? 1)
            .toArray();
    }

    /* Mounted */
    mounted() {
        this.isLoading = true;
        Promise.all([
            this.loadMeasures(),
            this.loadFindings(),
            this.loadPlanItem()
        ]).then(() => {
            this.loadCategory(this.planItem!.audit!.categoryId!).then(() => {
                this.isLoading = false;
                this.$nextTick(() => {
                  if (this.$router.currentRoute.query.scrollTo) {
                    const id = (this.$router!.currentRoute!.query!.scrollTo! ?? "") as any;
                    document.getElementById("layout-content")!.scrollTo({
                      top: document.getElementById(id)!.offsetTop - document.getElementById("work-task-header")!.offsetHeight + 60,
                      behavior: "smooth"
                    })
                  }
                })
            }).catch((e) => {
               this.isLoading = false; 
               console.log(e);
            });
        }).catch((e) => {
            console.log(e);
        })
    }

    /* Action functions */

    loadMeasures(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            measuresStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<MeasureModel>(undefined, [
                { field: "finding.response.planItemId", op: "eq", comparand: this.id },
                { field: "ImplementationState", op: "in", comparand: [ ImplementationStateEnum.WaitingForVerification, ImplementationStateEnum.Verified ] }
            ]));
            this.subscribe(measuresStore, CrudReponse.GetAll).then((e: MeasureModel[]) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadFindings(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            findingsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<FindingModel>(undefined, [
                { field: "response.planItemId", op: "eq", comparand: this.id }
            ]));
            this.subscribe(findingsStore, CrudReponse.GetAll).then((e: FindingModel[]) => {
                this.findings = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadPlanItem(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            planItemsStore.dispatch(CrudAction.Get, { id: this.id });
            this.subscribe(planItemsStore, CrudReponse.Get).then((e: PlanItemModel) => {
                this.planItem = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadCategory(categoryId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            categoriesStore.dispatch(CrudAction.Get, { id: categoryId });
            this.subscribe(categoriesStore, CrudReponse.Get).then((e: CategoryModel) => {
                this.category = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    getFinding(id: string) {
        return this.findings.firstOrDefault(x => x.id == id);
    }

    planItemChanged () {
        this.$emit("planItemChanged");
    }

}
