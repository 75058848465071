



















































import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import {findingsStore} from '@/libs/findings/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CategoryModel, FindingModel, MeasureModel, PlanItemModel, TenantUserModel } from '@/libs/Api';
import WorkTaskFindingItem from '../components/WorkTaskFindingItem.vue';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { categoriesStore } from '@/libs/categories/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { cloneDeep } from 'lodash';

@Component({
    components: {
        WorkTaskFindingItem
    }
})
export default class WorkTaskFindingTab extends Page {

    /* Page props */
    @Prop() id!: string;
    @Prop() tenantId!: string;

    /* Model variables */
    planItem: PlanItemModel | null = null;
    category: CategoryModel | null = null;

    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    
    /* Component state variables */
    isLoading = false;

    /* Getters */
    get mineFindings(): MeasureModel[] {
        let findings = cloneDeep([...findingsStore.useGetter(CrudGetter.Data)]) as FindingModel[];
        findings = findings.where(x => {
            if (x.useDefinedManagers == true) {
                return x.findingManagers?.any(n => n.tenantUserId == this.tenantUser.id) ?? false;
            }
            else {
                return this.category?.correctionManagers?.any(n => n.tenantUserId == this.tenantUser.id) ?? false;
            }
        }).toArray();
        findings.forEach((x) => {
            x.measures = x.measures?.orderBy(n => n.sequenceIndex).toArray();
        });
        return findings
            .orderBy(x => x.response!.question?.questionGroup?.position ?? 1)
            .thenBy(x => x.response!.question?.position ?? 1)
            .thenBy(x => x.response!.sequenceIndex ?? 1)
            .thenBy(x => x.sequenceIndex ?? 1)
            .toArray();
    }

    get otherFindings(): MeasureModel[] {
        let findings = cloneDeep([...findingsStore.useGetter(CrudGetter.Data)]) as FindingModel[];
        findings = findings.where(x => {
            if (x.useDefinedManagers == true) {
                return !(x.findingManagers?.any(n => n.tenantUserId == this.tenantUser.id) ?? false);
            }
            else {
                return !(this.category?.correctionManagers?.any(n => n.tenantUserId == this.tenantUser.id) ?? false);
            }
        }).toArray();
        findings.forEach((x) => {
            x.measures = x.measures?.orderBy(n => n.sequenceIndex).toArray();
        });
        return findings
            .orderBy(x => x.response!.question?.questionGroup?.position ?? 1)
            .thenBy(x => x.response!.question?.position ?? 1)
            .thenBy(x => x.response!.sequenceIndex ?? 1)
            .thenBy(x => x.sequenceIndex ?? 1)
            .toArray();
    }

    /* Mounted */
    mounted() {
        this.isLoading = true;
        Promise.all([
            this.loadFindings(),
            this.loadPlanItem()
        ]).then(() => {
            this.loadCategory(this.planItem!.audit!.categoryId!).then(() => {
                this.isLoading = false;
            }).catch((e) => {
               this.isLoading = false; 
               console.log(e);
            });
        }).catch((e) => {
            this.isLoading = false;
            console.log(e);
        })
    }

    /* Action functions */

    loadFindings(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            findingsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<FindingModel>(undefined, [
                { field: "response.planItemId", op: "eq", comparand: this.id }
            ]));
            this.subscribe(findingsStore, CrudReponse.GetAll).then((e: MeasureModel[]) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadPlanItem(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            planItemsStore.dispatch(CrudAction.Get, { id: this.id });
            this.subscribe(planItemsStore, CrudReponse.Get).then((e: PlanItemModel) => {
                this.planItem = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    loadCategory(categoryId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            categoriesStore.dispatch(CrudAction.Get, { id: categoryId });
            this.subscribe(categoriesStore, CrudReponse.Get).then((e: CategoryModel) => {
                this.category = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    planItemChanged () {
        this.$emit("planItemChanged");
    }

}
