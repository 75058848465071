































































































import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { responsesStore } from '@/libs/responses/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload, createGrouppedQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CategoryModel, ConditionalQueryOperator, GroupByDefinition, GroupByProjectionDefinition, PredicateDefinition, ProjectionAggregationEnum, QueryOperator, ResponseModel, ResponseTypeEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { categoriesStore } from '@/libs/categories/+state/store';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';

@Component({
    components: {
    }
})
export default class ReportByQuestion extends Page {

    categoryId: string | null = null;
    fromDate: string | null = null;
    toDate: string | null = null;
    tenantUser: Array<string> | null = null;
    department: Array<string> | null = null;
    position: Array<string> | null = null;
    audit: Array<string> | null = null;
    userGroup: Array<string> | null = null;
    mobileFiltersShow = false;

    tab: string | null = "absolute;"

    @Watch("tab")
    @Watch("categoryId")
    @Watch("fromDate")
    @Watch("toDate")
    @Watch("tenantUser")
    @Watch("department")
    @Watch("position")
    @Watch("audit")
    @Watch("userGroup")
    filterChanged () {
        this.loadData();
        this.saveQuery();
    }

    get categories (): CategoryModel[] {
        return [...categoriesStore.useGetter(CrudGetter.Data)];
    }

    get isOkNok(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.OkNok);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.OkNok || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isOkNokWarning(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.OkNokWarning);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.OkNokWarning || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToFive(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToFive);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreZeroToFive || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToFour(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToFour);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreZeroToFour || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToOne(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToOne);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreZeroToOne || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToTwo(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToTwo);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreZeroToTwo || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreOneToThree(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreOneToThree);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreOneToThree || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreOneToFive(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreOneToFive);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreOneToFive || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreZeroToThree(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreZeroToThree);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreZeroToThree || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isScoreOneToFour(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.ScoreOneToFour);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.ScoreOneToFour || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get isVDA63(): boolean {
        let result = this.categories.filter((x: CategoryModel) => x.responseType == ResponseTypeEnum.VDA63);
        if (result.length > 0 && (this.categoryFilterResponseType == ResponseTypeEnum.VDA63 || !this.categoryFilterResponseType)) 
            return true;
        else
            return false;
    }

    get categoryFilterResponseType(): string | null | undefined {
        if (this.categoryId) {
            let result = this.categories.filter((x: CategoryModel) => x.id == this.categoryId);
            if (result.length > 0)
                return result[0].responseType;
            else
                return null;
        } else {
            return null;
        }
    }

    get chartOptions() {
        const tab = this.tab;
        let grouppedData = responsesStore.useGetter(CrudGetter.GrouppedData) as any[];
        grouppedData = grouppedData.orderBy(x => x.key.code).toArray();
        return {
            chart: {
                id: 'vuechart'
            },
            xaxis: {
                categories: grouppedData.select(x => x.key.code).toArray()
            },
            yaxis: {
                tickAmount: 1,
                decimalsInFloat: 0,
                labels: {
                    formatter: function (value: number) {
                        return tab == "absolute" ? value : (value.toFixed(2) + "%");
                    }
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: "20%"
                }
            },
            colors: [ "#43D01A", "#F9B237", "#c71e1e", "#006fc7", "#b300c7", "#acc700", "#333333", "#cfb504", "#0b04cf" ],
            dataLabels: {
                enabled: false
            }
        };
    }

    get chartSeries() {
        let grouppedData = responsesStore.useGetter(CrudGetter.GrouppedData) as any[];
        console.log(grouppedData);
        grouppedData = grouppedData.orderBy(x => x.key.code).toArray();
        if (this.tab == "absolute") {
            let series = [];
            if (this.isOkNok || this.isOkNokWarning)
                series.push({
                    name: this.$i18n.t("reports.countOk"),
                    data: grouppedData.select(x => x.isOkCount).toArray()
                });
            if (this.isOkNokWarning)
                series.push({
                    name: this.$i18n.t("reports.countOkW"),
                    data: grouppedData.select(x => x.isOkWithWarningCount).toArray()
                });
            if (this.isOkNok || this.isOkNokWarning)
                series.push({
                    name: this.$i18n.t("reports.countNok"),
                    data: grouppedData.select(x => x.isNokCount).toArray()
                });
            if (this.isScoreZeroToOne || this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreZeroToThree || this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreZero"),
                    data: grouppedData.select(x => x.scoreZeroCount).toArray()
                });
            if (this.isScoreZeroToOne || this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour)
                series.push({
                    name: this.$i18n.t("responseValue.scoreOne"),
                    data: grouppedData.select(x => x.scoreOneCount).toArray()
                });
            if (this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour)
                series.push({
                    name: this.$i18n.t("responseValue.scoreTwo"),
                    data: grouppedData.select(x => x.scoreTwoCount).toArray()
                });
            if (this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour)
                series.push({
                    name: this.$i18n.t("responseValue.scoreThree"),
                    data: grouppedData.select(x => x.scoreThreeCount).toArray()
                });
            if (this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToFive || this.isScoreOneToFour || this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreFour"),
                    data: grouppedData.select(x => x.scoreFourCount).toArray()
                });
            if (this.isScoreZeroToFive || this.isScoreOneToFive)
                series.push({
                    name: this.$i18n.t("responseValue.scoreFive"),
                    data: grouppedData.select(x => x.scoreFiveCount).toArray()
                });
            if (this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreSix"),
                    data: grouppedData.select(x => x.scoreSixCount).toArray()
                });
            if (this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreEight"),
                    data: grouppedData.select(x => x.scoreEightCount).toArray()
                });
            if (this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreTen"),
                    data: grouppedData.select(x => x.scoreTenCount).toArray()
                });
            return series;
        }
        else {
            let series = [];
            if (this.isOkNok || this.isOkNokWarning)
                series.push({
                    name: this.$i18n.t("reports.countOk"),
                    data: grouppedData.select(x => x.count > 0 ? x.isOkCount / x.count * 100 : 0).toArray()
                });
            if (this.isOkNokWarning)
                series.push({
                    name: this.$i18n.t("reports.countOkW"),
                    data: grouppedData.select(x => x.count > 0 ? x.isOkWithWarningCount / x.count * 100 : 0).toArray()
                });
            if (this.isOkNok || this.isOkNokWarning)
                series.push({
                    name: this.$i18n.t("reports.countNok"),
                    data: grouppedData.select(x => x.count > 0 ? x.isNokCount / x.count * 100 : 0).toArray()
                });
            if (this.isScoreZeroToOne || this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreZeroToThree || this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreZero"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreZeroCount / x.count * 100 : 0).toArray()
                });
            if (this.isScoreZeroToOne || this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour)
                series.push({
                    name: this.$i18n.t("responseValue.scoreOne"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreOneCount / x.count * 100 : 0).toArray()
                });
            if (this.isScoreZeroToTwo || this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour)
                series.push({
                    name: this.$i18n.t("responseValue.scoreTwo"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreTwoCount / x.count * 100 : 0).toArray()
                });
            if (this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToThree || this.isScoreOneToFive || this.isScoreZeroToThree || this.isScoreOneToFour)
                series.push({
                    name: this.$i18n.t("responseValue.scoreThree"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreThreeCount / x.count * 100 : 0).toArray()
                });
            if (this.isScoreZeroToFour || this.isScoreZeroToFive || this.isScoreOneToFive || this.isScoreOneToFour || this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreFour"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreFourCount / x.count * 100 : 0).toArray()
                });
            if (this.isScoreZeroToFive || this.isScoreOneToFive)
                series.push({
                    name: this.$i18n.t("responseValue.scoreFive"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreFiveCount / x.count * 100 : 0).toArray()
                });
            if (this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreSix"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreSixCount / x.count * 100 : 0).toArray()
                });
            if (this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreEight"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreEightCount / x.count * 100 : 0).toArray()
                });
            if (this.isVDA63)
                series.push({
                    name: this.$i18n.t("responseValue.scoreTen"),
                    data: grouppedData.select(x => x.count > 0 ? x.scoreTenCount / x.count * 100 : 0).toArray()
                });
            return series;
        }
    }

    created () {

        this.tab = this.getQuery("tab") ?? null;
        this.categoryId = this.getQuery("categoryId") ?? null;
        this.fromDate = this.getQuery("fromDate") ?? null;
        this.toDate = this.getQuery("toDate") ?? null;
        // this.tenantUserId = this.getQuery("tenantUserId") ?? null;
        // this.departmentId = this.getQuery("departmentId") ?? null;
        // this.positionId = this.getQuery("positionId") ?? null;
        // this.auditId = this.getQuery("auditId") ?? null;
        // this.userGroupId = this.getQuery("userGroupId") ?? null;

    }

    mounted () {
        this.loadData();
        this.loadCategories();
    }

    getPredicates () {
        const filters: PredicateDefinition[] = [
            { field: "PlanItem.ResponseLock", op: QueryOperator.Eq, comparand: true }
        ];
        let auditFilters: CrudQueryPredicate[] = [];
        let userFilters: CrudQueryPredicate[] = [];
        let departmentFilters: CrudQueryPredicate[] = [];
        let positionFilters: CrudQueryPredicate[] = [];
        let userGroupFilters: CrudQueryPredicate[] = [];
        if (this.categoryId)
            filters.push({ field: "PlanItem.Audit.CategoryId", op: QueryOperator.Eq, comparand: this.categoryId });
        if (this.fromDate)
            filters.push({ field: "PlanItem.Time", op: QueryOperator.Gte, comparand: this.fromDate });
        if (this.toDate)
            filters.push({ field: "PlanItem.Time", op: QueryOperator.Lte, comparand: this.toDate });
        if (this.tenantUser && this.tenantUser.length > 0) {
            this.tenantUser.forEach((e: string) => {
                if (e) {
                    userFilters.push({
                        field: "PlanItem.TenantUserId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.department && this.department.length > 0) {
            this.department.forEach((e: string) => {
                if (e) {
                    departmentFilters.push({
                        field: "PlanItem.TenantUser.DepartmentId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.position && this.position.length > 0) {
            this.position.forEach((e: string) => {
                if (e) {
                    positionFilters.push({
                        field: "PlanItem.TenantUser.PositionId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.audit && this.audit.length > 0) {
            this.audit.forEach((e: string) => {
                if (e) {
                    auditFilters.push({
                        field: "PlanItem.AuditId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.userGroup && this.userGroup.length > 0) {
            this.userGroup.forEach((e: string) => {
                if (e) {
                    userGroupFilters.push({
                        field: "PlanItem.AuditTiming.GroupId",
                        op: QueryOperator.Eq,
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (auditFilters.length > 0) {
            filters.push({
                field: "PlanItem.AuditId",
                op: QueryOperator.Wrap,
                comparand: auditFilters
            });
        }
        if (userFilters.length > 0) {
            filters.push({
                field: "PlanItem.TenantUserId",
                op: QueryOperator.Wrap,
                comparand: userFilters
            });
        }
        if (departmentFilters.length > 0) {
            filters.push({
                field: "PlanItem.TenantUser.DepartmentId",
                op: QueryOperator.Wrap,
                comparand: departmentFilters
            });
        }
        if (positionFilters.length > 0) {
            filters.push({
                field: "PlanItem.TenantUser.PositionId",
                op: QueryOperator.Wrap,
                comparand: positionFilters
            });
        }
        if (userGroupFilters.length > 0) {
            filters.push({
                field: "PlanItem.AuditTiming.GroupId",
                op: QueryOperator.Wrap,
                comparand: userGroupFilters
            });
        }
        return filters;
    }

    loadData() {
        responsesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<ResponseModel>(this.getPredicates(), [
            {
                field: "question.code"
            } as GroupByDefinition
        ], [
            {
                field: "Id",
                aggregation: ProjectionAggregationEnum.Count,
                outputField: "Count"
            } as GroupByProjectionDefinition,
            {
                field: "okValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "isOkCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 1
            } as GroupByProjectionDefinition,
            {
                field: "okValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "isOkWithWarningCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 2
            } as GroupByProjectionDefinition,
            {
                field: "okValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "isNokCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 3
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreZeroCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 0
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreOneCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 1
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreTwoCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 2
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreThreeCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 3
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreFourCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 4
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreFiveCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 5
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreSixCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 6
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreEightCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 8
            } as GroupByProjectionDefinition,
            {
                field: "scoreValue",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "scoreTenCount",
                conditional: true,
                op: ConditionalQueryOperator.Eq,
                comparand: 10
            } as GroupByProjectionDefinition
        ]));
    }

    saveQuery () {

        if (this.tab)
            this.setQuery("tab", this.tab);
        else
            this.setQuery("tab", null);

        if (this.categoryId)
            this.setQuery("categoryId", this.categoryId);
        else
            this.setQuery("categoryId", null);

        if (this.fromDate)
            this.setQuery("fromDate", this.fromDate);
        else
            this.setQuery("fromDate", null);

        if (this.toDate)
            this.setQuery("toDate", this.toDate);
        else
            this.setQuery("toDate", null);

        // if (this.tenantUserId)
        //     this.setQuery("tenantUserId", this.tenantUserId);
        // else
        //     this.setQuery("tenantUserId", null);

        // if (this.departmentId)
        //     this.setQuery("departmentId", this.departmentId);
        // else
        //     this.setQuery("departmentId", null);

        // if (this.positionId)
        //     this.setQuery("positionId", this.positionId);
        // else
        //     this.setQuery("positionId", null);

        // if (this.auditId)
        //     this.setQuery("auditId", this.auditId);
        // else
        //     this.setQuery("auditId", null);

        // if (this.userGroupId)
        //     this.setQuery("userGroupId", this.userGroupId);
        // else
        //     this.setQuery("userGroupId", null);

    }

    loadCategories () {
        categoriesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CategoryModel>({ field: "name", index: 1, order: "asc" }));
    }

}
