import { AuthState, AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { Module } from 'vuex';
import store from '@/libs/core/+state/store';
import { isFunction } from 'lodash';

import ApiService from '@/libs/core/api-service';
import { SignUpModel, ConfirmEmailModel, IdentityResult, ResetPasswordModel, ConfirmResetPasswordModel, SetPasswordModel, ApplicationUserModel, TenantUserModel, PermissionModel, PermissionTypeEnum, AadConnectResponseModel } from '@/libs/Api';

import {setAuthTokens, getAccessToken } from 'axios-jwt'
import axios from 'axios'

import { SignInTokensModel, SignInModel } from '@/libs/auth/models/auth-models';
import { EventTypeEnum } from '@/libs/core/models/event-type-enum';
import { AuthenticationType } from '../models/auth-type';
import { PublicClientApplication } from '@azure/msal-browser';

export interface IAuthStore {
    dispatch (action: AuthAction, payload: SignUpModel | ConfirmEmailModel | SignInModel | ResetPasswordModel | ConfirmResetPasswordModel | SetPasswordModel | TenantUserModel | string | null): void;
    dispatch (action: AuthAction): void;
    useGetter (getter: AuthGetter): any;
    getActionName (action: string): string;
}

function registerAuthStore(): IAuthStore {
    const authStoreObject: Module<AuthState, unknown> = {
        namespaced: true,
        state: {
            isLoggedIn: false,
            userInfo: null,
            tenant: null,
            tenantUser: null,
            permissions: [],
            supportedAuthenticationTypes: [
                AuthenticationType.Standard
            ],
            authenticationType: AuthenticationType.Standard,
            azureTenantId: null,
            azureAppId: null,
            isSingleTenant: false
        },
        actions: {
            [AuthAction.SetAuthenticationType]: ({commit, state}, payload: AuthenticationType) =>{
                window.localStorage.AuthenticationType = payload;
                commit(AuthAction.SetAuthenticationType, payload);
            },
            [AuthAction.SetSupportedAuthenticationTypes]: ({commit, state}, payload: AuthenticationType[]) =>{
                commit(AuthAction.SetSupportedAuthenticationTypes, payload);
            },
            [AuthAction.SignUp]: ({commit, state}, payload: SignUpModel) =>{
                ApiService.api.signUp(payload).then((e: any) => {
                    const data = e.data as IdentityResult;
                    console.log(data);
                    commit(AuthAction.SignUpResponse, data);
                });
            },
            [AuthAction.ActivateAccount]: ({commit, state}, payload: ConfirmEmailModel) =>{
                ApiService.api.activateAccount(payload).then((e: any) => {
                    const data = e.returnValue as IdentityResult;
                    console.log(data);
                    commit(AuthAction.ActivateAccountResponse, payload);
                });
            },
            [AuthAction.SignIn]: ({commit, state}, payload: SignInModel) => {
                const params = new URLSearchParams();
                params.append('client_id', 'Web');
                params.append('grant_type', 'password');
                params.append('username', payload.email!);
                params.append('password', payload.password!);
                params.append('mfa_code', payload.mfaCode ?? '');
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                const baseUrl = (window as any).SaasApiEndpoint;
                const axiosInstance = axios.create({ baseURL: baseUrl });
                const login = async (params: any, config: any = null) => {
                    await axiosInstance.post('/connect/token', params, config).then((e: any) => {
                        console.log(e);
                        const payload = new SignInTokensModel();
                        payload.accessToken = e.data.access_token;
                        payload.refreshToken = e.data.access_token;
                        commit(AuthAction.SignInResponse, payload);
                        authStore.dispatch(AuthAction.UserInfo);
                    }).catch((e: any) => {
                        commit(AuthAction.SignInResponse, e.response.data);
                    });
                    
                }
                login(params, config);
            },
            [AuthAction.SignOut]: ({commit, state}) => {
                const baseUrl = (window as any).SaasApiEndpoint;
                const axiosInstance = axios.create({ baseURL: baseUrl });
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                const signout = async (config: any = null) => {
                    commit(AuthAction.SignOut);
                }
                signout(config);
            },
            [AuthAction.UserInfo]: ({commit, state}) => {
                if (state.authenticationType == AuthenticationType.AzureActiveDirectory) {
                    const token = getAccessToken();
                    ApiService.api.aadConnect({
                        token: token
                    }).then(response => {
                        const connectResponse = response.data as AadConnectResponseModel;
                        if (connectResponse.isLoggedIn) {
                            commit(AuthAction.UserInfo, response.data);
                        }
                        else {
                            commit(AuthAction.UserInfo, null);
                        }
                    }).catch(ex => console.log(ex));
                }
                else {
                    const token = getAccessToken();
                    const baseUrl = (window as any).SaasApiEndpoint;
                    const axiosInstance = axios.create({ baseURL: baseUrl });
                    const config = {
                        headers: {
                            //Authorization: `Bearer ${token}`
                            Authorization: `Bearer ${token}`
                        },
                        maxRedirects: 0
                    }
                    const getUserInfo = async (config: any) => {
                        try {
                            const response = await axiosInstance.get('/connect/userinfo', config);
                            commit(AuthAction.UserInfo, response.data);
                        }
                        catch (e) {
                            console.log(e);
                            commit(AuthAction.UserInfo, null);
                        }
                    }
                    getUserInfo(config);
                }
            },
            [AuthAction.ResetPassword]: ({commit, state}, payload: ResetPasswordModel) =>{
                ApiService.api.resetPassword(payload).then((e: any) => {
                    const data = e.data as IdentityResult;
                    commit(AuthAction.ResetPasswordResponse, data);
                });
            },
            [AuthAction.ResetSetNewPassword]: ({commit, state}, payload: ConfirmResetPasswordModel) =>{
                ApiService.api.confirmResetPassword(payload).then((e: any) => {
                    const data = e.data as IdentityResult;
                    commit(AuthAction.ResetSetNewPasswordResponse, data);
                });
            },
            [AuthAction.SetTenant]: ({commit, state}, payload: string) =>{
                commit(AuthAction.SetTenant, payload);
                window.localStorage.CurrentTenant = payload;
                (window as any).Journaly.publish(EventTypeEnum.TenantChanged, payload);
            },
            [AuthAction.SetPassword]: ({commit, state}, payload: SetPasswordModel) =>{
                ApiService.api.setPassword(payload).then((e: any) => {
                    const data = e.data as IdentityResult;
                    commit(AuthAction.SetPasswordResponse, data);
                });
            },
            [AuthAction.SetTenantUser]: ({commit, state}, payload: TenantUserModel) =>{
                commit(AuthAction.SetTenantUser, payload);
            },
            [AuthAction.SetAzureTenantId]: ({commit, state}, payload: string | null) =>{
                commit(AuthAction.SetAzureTenantId, payload);
            },
            [AuthAction.SetAzureAppId]: ({commit, state}, payload: string | null) =>{
                commit(AuthAction.SetAzureAppId, payload);
            },
            [AuthAction.SetIsSingleTenant]: ({commit, state}, payload: boolean) =>{
                commit(AuthAction.SetIsSingleTenant, payload);
            },
        },
        mutations: {
            [AuthAction.SetAuthenticationType]: (state: AuthState, payload: AuthenticationType) => {
                state.authenticationType = payload;
            },
            [AuthAction.SetSupportedAuthenticationTypes]: (state: AuthState, payload: AuthenticationType[]) => {
                state.supportedAuthenticationTypes = payload;
            },
            [AuthAction.SignUp]: (state: AuthState, payload: SignUpModel) => {
                state.isLoggedIn = true;
            },
            [AuthAction.ActivateAccount]: (state: AuthState, payload: SignInModel) => {
                state.isLoggedIn = true;
            },
            [AuthAction.SignInResponse]: (state: AuthState, payload: any) => {
                state.isLoggedIn = true;
                setAuthTokens({
                    accessToken: payload.accessToken!,
                    refreshToken: payload.refreshToken!
                });
            },
            [AuthAction.SignOut]: (state: AuthState) => {
                state.isLoggedIn = false;
                setAuthTokens({
                    accessToken: "",
                    refreshToken: ""
                });
            },
            [AuthAction.UserInfo]: (state: AuthState, payload: any) => {
                console.log(payload);
                if (payload != null && state.isLoggedIn == false) {
                    state.isLoggedIn = true;
                }
                else if (payload == null) {
                    state.isLoggedIn = false;
                }
                state.userInfo = payload;
            },
            [AuthAction.ResetPasswordResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.SetTenant]: (state: AuthState, payload: string) => {
                state.tenant = payload;
            },
            [AuthAction.ResetSetNewPasswordResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.SetPasswordResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.SignUpResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.ActivateAccountResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.SetTenantUser]: (state: AuthState, payload: TenantUserModel) => {
                state.tenantUser = payload;
                state.permissions = [];
                payload.userRoles?.forEach(ur => {
                    ur.role?.permissions?.forEach((x: PermissionModel) => {
                        if (!state.permissions.any(p => p == x.permissionType)) {
                            state.permissions.push(x.permissionType!);
                        }
                    });
                });
            },
            [AuthAction.SetAzureTenantId]: (state: AuthState, payload: string | null) => {
                state.azureTenantId = payload;
            },
            [AuthAction.SetAzureAppId]: (state: AuthState, payload: string | null) => {
                state.azureAppId = payload;
            },
            [AuthAction.SetIsSingleTenant]: (state: AuthState, payload: boolean) => {
                state.isSingleTenant = payload;
            },
        },
        getters: {
            [AuthGetter.GetSupportedAuthenticationTypes]: (state: AuthState): AuthenticationType[] => {
                return state.supportedAuthenticationTypes;
            },
            [AuthGetter.GetAuthenticationType]: (state: AuthState): AuthenticationType => {
                return state.authenticationType;
            },
            [AuthGetter.GetIsLoggedIn]: (state: AuthState): boolean => {
                return state.isLoggedIn;
            },
            [AuthGetter.GetUserInfo]: (state: AuthState): any => {
                return state.userInfo;
            },
            [AuthGetter.GetTenant]: (state: AuthState): string | null => {
                return state.tenant;
            },
            [AuthGetter.GetTenantUser]: (state: AuthState): TenantUserModel | null => {
                return state.tenantUser;
            },
            [AuthGetter.GetPermissions]: (state: AuthState): PermissionTypeEnum[] | null => {
                return state.permissions;
            },
            [AuthGetter.GetAzureTenantId]: (state: AuthState): string | null => {
                return state.azureTenantId;
            },
            [AuthGetter.GetAzureAppId]: (state: AuthState): string | null => {
                return state.azureAppId;
            },
            [AuthGetter.GetIsSingleTenant]: (state: AuthState): boolean => {
                return state.isSingleTenant;
            }
        }
    };
    store.registerModule("AUTH", authStoreObject);
    return {
        dispatch: (action: AuthAction, payload: SignUpModel | ConfirmEmailModel | SignInModel | ResetPasswordModel | ConfirmResetPasswordModel | SetPasswordModel | TenantUserModel | string | null = null) => {
            store.dispatch(`AUTH/${action}`, payload);
        },
        useGetter: (getter: AuthGetter) => {
            const val = store.getters[`AUTH/${getter}`];
            return isFunction(val) ? val() : val;
        },
        getActionName: (action: string) => `AUTH/${action}`,
    }
}

export const authStore = registerAuthStore();
