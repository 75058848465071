import {
    HttpResponse,
    GroupUserModel,
    QueryModel,
    RequestParams,
    GroupUserModelIEnumerableApiResultSuccess,
    GroupUserModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class GroupUsersDataService implements CrudService<GroupUserModel, GroupUserModel> {
    getAll(query: CrudDataQuery<GroupUserModel>): Promise<CrudEnumerableResponse<GroupUserModel>> {
        return ApiService.api.getGroupUserBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<GroupUserModel>): Promise<CrudEnumerableResponse<GroupUserModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<GroupUserModel>> {
        return ApiService.api.getGroupUserById(id, params);
    }

    create(item: GroupUserModel, params?: RequestParams): Promise<CrudResponse<GroupUserModel>> {
        return ApiService.api.insertGroupUser(item, params);
    }

    update(item: GroupUserModel, params?: RequestParams): Promise<GroupUserModel> {
        return ApiService.api.updateGroupUser(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<GroupUserModel> {
        return ApiService.api.patchGroupUser(id, patches, params).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<GroupUserModel>): Promise<CrudEnumerableResponse<GroupUserModel>> {
        return ApiService.api.deleteGroupUsers(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        return ApiService.api.aggregationGroupUser(query, params).then((req: any) => req.data.returnValue);
    }
    
    download(query: CrudDataQuery<GroupUserModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: GroupUserModel, params?: RequestParams): Promise<CrudResponse<GroupUserModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        return ApiService.api.getGroupUserGroupped(query, params);
    }

}
