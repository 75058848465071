import { JunctionOperator, PredicateDefinition, QueryOperator } from "@/libs/Api";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import moment from "moment";

export class PredicateBuilder {
    
    public static GetExpression(definitions: PredicateDefinition[]): (x: any) => boolean {
        
        console.log(definitions);

        if (!definitions || definitions.length == 0)
            return () => { return true; };

        let _definitions = [...definitions];
        let expressions = null;

        while (_definitions.length > 0) {

            const definition = _definitions.first();
            
            const expression = PredicateBuilder.GetPropertyExpression(definition.field!.split("."), definition.op!, definition.comparand);
            if (expression != null) {
                if (expressions != null) {
                    switch (definition.junction) {
                        case JunctionOperator.And:
                            expressions = PredicateBuilder.And(expressions, expression);
                            break;
                        case JunctionOperator.Or:
                            expressions = PredicateBuilder.Or(expressions, expression);
                            break;
                        default:
                            expressions = PredicateBuilder.And(expressions, expression);
                            break;
                    }
                }
                else {
                    expressions = expression;
                }
            }

            _definitions = _definitions.splice(1);

        }

        return expressions != null ? expressions : () => { return true };
    
    }

    private static GetPropertyExpression(path: string[], operator: QueryOperator, comparand: any): (x: any) => boolean {
        const propertyName = path.first();
        if (path.count() == 0) {
            throw new Error("Bad request!");
        }
        else if (path.count() > 1) {
            const expression = PredicateBuilder.GetPropertyExpression(path.splice(1), operator, comparand);
            const propertyExpression = (x: any) => { return expression(x[propertyName]); };
            console.log(expression.toString(), propertyExpression.toString());
            return propertyExpression;
        }
        else {
            const propertyExpression = (x: any) => { return x[propertyName]; };
            const expression = PredicateBuilder.GetExpressionFilter(propertyExpression, operator, comparand);
            console.log(expression.toString());
            return expression;
        }
    }

    private static GetExpressionFilter(expression: (x: any) => any, operator: QueryOperator, comparand: any): (x: any) => boolean {
        switch (operator) {
            case QueryOperator.Eq:
                return PredicateBuilder.Equal(expression, comparand);
            case QueryOperator.Ne:
                return PredicateBuilder.NotEqual(expression, comparand);
            case QueryOperator.Gt:
                return PredicateBuilder.GreaterThan(expression, comparand);
            case QueryOperator.Gte:
                return PredicateBuilder.GreaterThanOrEqual(expression, comparand);
            case QueryOperator.Lt:
                return PredicateBuilder.LessThan(expression, comparand);
            case QueryOperator.Lte:
                return PredicateBuilder.LessThanOrEqual(expression, comparand);
            case QueryOperator.C:
                return PredicateBuilder.Contains(expression, comparand);
            case QueryOperator.Sw:
                return PredicateBuilder.StartsWith(expression, comparand);
            case QueryOperator.Ew:
                return PredicateBuilder.EndsWith(expression, comparand);
            case QueryOperator.In:
                return PredicateBuilder.In(expression, comparand);
            default:
                throw new Error("Operator not supported!");
        }
    }

    private static Or(a: (x: any) => boolean, b: (x: any) => boolean): (x: any) => boolean {
        return (x: any) => a(x) || b(x);
    }

    private static And(a: (x: any) => boolean, b: (x: any) => boolean): (x: any) => boolean {
        return (x: any) => a(x) && b(x);
    }

    private static Equal(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => this.GetValue(expression(x)) == this.GetValue(comparand);
    }

    private static NotEqual(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => this.GetValue(expression(x)) != this.GetValue(comparand);
    }

    private static GreaterThan(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => this.GetValue(expression(x)) > this.GetValue(comparand);
    }

    private static GreaterThanOrEqual(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => this.GetValue(expression(x)) >= this.GetValue(comparand);
    }

    private static LessThan(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => this.GetValue(expression(x)) < this.GetValue(comparand);
    }

    private static LessThanOrEqual(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => this.GetValue(expression(x)) <= this.GetValue(comparand);
    }

    private static Contains(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => (expression(x).toString() as string).includes(comparand);
    }

    private static StartsWith(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => (expression(x).toString() as string).startsWith(comparand);
    }

    private static EndsWith(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => (expression(x).toString() as string).endsWith(comparand);
    }

    private static In(expression: (x: any) => any, comparand: any): (x: any) => boolean {
        return (x: any) => expression(x).find((e: any) => e == comparand);
    }

    private static GetValue(x: any): any {
        if (PredicateBuilder.isDate(x)) {
            return moment(x).toDate();
        }
        return x;
    }

    private static isDate(x: string | Date | null): boolean {
        console.log("isDate", moment(x).isValid());
        if (x == null)
            return false;
        return moment(x).isValid()
    }

}