




















import { CorrectionManagerModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import Page from '@/Page.vue';
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';

@Component({})
export default class CorrectionManagerList extends Page {

    @Prop() tenantId!: string;
    @Prop() categoryId!: string;
    
    @VModel() correctionManagers!: CorrectionManagerModel[];

    get exitingCorrectionManagers (): Array<string> {
        return this.correctionManagers.select(x => x.tenantUserId!).toArray();
    }

    private addClick () {
        const items = [...this.correctionManagers];
        items.push({
            id: Guid.EmptyGuid(),
            tenantUserId: Guid.EmptyGuid(),
            categoryId: this.categoryId
        } as CorrectionManagerModel);
        this.$emit("input", items);
    }

    mounted () {
        if (this.correctionManagers.length == 0) {
            this.addClick();
        }
    }

    private deleteItem (index: number) {
        this.correctionManagers.splice(index, 1);
    }

}
