


































import Page from '@/Page.vue';
import { Component, Prop, PropSync, Ref, VModel, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { ApplicationUserModel, MeasureModel, PlanItemModel, PlanItemStateEnum } from '@/libs/Api';
import { measuresStore } from '@/libs/measures/+state/store';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Guid } from '@/libs/common/functions/guid';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import moment from 'moment';

@Component({
    components: {
    }
})
export default class WorkTaskRequestApprovalDialog extends Page {

    @VModel() dialog!: boolean;

    /* Component state variables */
    valid = true;
    saving = false;
    hasChanges = true;
    requestReviewById: string | null = null;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;

    mounted () {
        const planItem = planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel;
        this.requestReviewById = planItem.requestReviewById ?? null;
    }

    @Ref() form!: any;
    
    /* Action functions */
    
    closeDialog () {
        this.$emit("input", false);
        this.dialog = false;
    }
    
    requestApproval () {
        if (this.form.validate()) {
            this.saving = true;
            const planItem = {...planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel};
            planItem.planItemState = PlanItemStateEnum.WaitingForApproval;
            planItem.reviewDeclineReason = null;
            planItem.requestReviewById = this.requestReviewById;
            planItem.requestReviewOn = moment().format("YYYY-MM-DDTHH:mm:ss");
            planItemsStore.dispatch(CrudAction.Update, { item: planItem, headers: {
                Action: 'SendForReview'
            } });
            this.subscribe(planItemsStore, CrudReponse.Update).then((e) => {
                this.saving = false;
                this.closeDialog();
            }).catch((e) => {
                this.saving = false;
                console.log(e);
            });
        }
    }

}
