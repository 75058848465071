



















































import {
  PlanItemModel
} from '@/libs/Api';
import Page from '@/Page.vue';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import apiService from '@/libs/core/api-service';

@Component({})
export default class AiSummaryGenerator extends Page {

  dialog = false;
  valid = true;
  saving = false;
  generating = false;
  messageInput: string | null = null;
  summaryText: string | null = null;

  @Watch("dialog")
  dialogChanged(v: boolean): void {
    if (!v)
      this.close();
  }

  @Watch("selected")
  selectedChanged(v: any[]): void {
    console.log(v);
  }

  @Ref() form!: any;
  @Prop() tenantId!: string;
  @Prop() planItem!: PlanItemModel;

  open(): void {
    this.dialog = true;
    this.messageInput = window.localStorage.getItem('aiPlanItemSummary');
  }

  close(): void {
    this.dialog = false;
  }

  generate(): void {
    /*this.generating = true;
    window.localStorage.setItem('aiPlanItemSummary', this.messageInput ?? '');
    apiService.api.summarizePlanItem(this.planItem!.id!, {
      messages: [
        { message: this.messageInput }
      ]
    }).then((e) => {
      this.summaryText = e.data.returnValue?.summaryText ?? null;
      this.generating = false;
    }).catch((e) => {
      console.error(e);
      this.generating = false;
    });*/
  }

}
