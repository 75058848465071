import {
    HttpResponse,
    ResponseModel,
    QueryModel,
    RequestParams,
    ResponseModelIEnumerableApiResultSuccess,
    ResponseModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class ResponsesDataService implements CrudService<ResponseModel, ResponseModel> {
    getAll(query: CrudDataQuery<ResponseModel>): Promise<CrudEnumerableResponse<ResponseModel>> {
        return ApiService.api.getResponseBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<ResponseModel>): Promise<CrudEnumerableResponse<ResponseModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<ResponseModel>> {
        return ApiService.api.getResponseById(id, params);
    }

    create(item: ResponseModel, params?: RequestParams): Promise<CrudResponse<ResponseModel>> {
        return ApiService.api.insertResponse(item, params);
    }

    update(item: ResponseModel, params?: RequestParams): Promise<ResponseModel> {
        return ApiService.api.updateResponse(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<ResponseModel> {
        return ApiService.api.patchResponse(id, patches, params).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<ResponseModel>): Promise<CrudEnumerableResponse<ResponseModel>> {
        return ApiService.api.deleteResponses(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        return ApiService.api.aggregationResponse(query, params).then((req: any) => req.data.returnValue);
    }
    
    download(query: CrudDataQuery<ResponseModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        return ApiService.api.exportResponseBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    clone(item: ResponseModel, params?: RequestParams): Promise<CrudResponse<ResponseModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        return ApiService.api.getResponseGroupped(query, params);
    }

}
