


















import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class ConfirmDialog extends Vue {

    @VModel() show!: boolean;

    @Prop() header!: string;
    @Prop() confirmButton!: string;
    @Prop() declineButton!: string;
    @Prop({ default: '600' }) width!: string;
    @Prop({ default: 'green' }) confirmButtonClass!: string;
    @Prop({ default: 'red' }) declineButtonClass!: string;
    @Prop({ default: true }) closeOnEscape!: boolean;
    @Prop({ default: false }) savingConfirm!: boolean;
    @Prop({ default: false }) savingDecline!: boolean;
    @Prop({ default: true }) allowConfirm!: boolean;
    
    get getConfirmButtonClass () : string {
        return this.confirmButtonClass.concat(" ").concat("mx-2");
    }

    confirmed () {
        this.$emit("confirmed");
    }

    declined () {
        this.$emit("declined");
    }

}
