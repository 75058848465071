


























import { PositionModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { isArray } from 'lodash';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import Autocomplete from '../components/Autocomplete.vue';
import { positionsStore } from '@/libs/positions/+state/store';
import { createAggregationQueryPayload } from '@/libs/core/+state/models/aggregation-query-payload';

@Component({})
export default class PositionSelect extends Vue {

    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) noMarginTop!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: true }) chips!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop() placeholder!: string;
    @Prop() attach!: string;

    @Ref() autocomplete!: Autocomplete;

    get items(): PositionModel[] {
        return [...(positionsStore.useGetter(CrudGetter.Data) as PositionModel[])].sort((a: PositionModel, b: PositionModel) => {
            if(a.name! < b.name!) { return -1; }
            if(a.name! > b.name!) { return 1; }
            return 0;
        });
    }

    mounted () {
        positionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PositionModel>())
    }

}
