





























































































































































































































import {
	ApplicationUserModel,
	PlanItemBasicModel,
	PlanItemFindingManagerModel,
	PlanItemModel,
	PlanItemStateEnum,
	TenantUserModel
} from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { issuesStore } from '@/libs/issues/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';

@Component({
    components: {
    }
})
export default class Issues extends Page {

    filterCategory: Array<string> | null = null;
    filterAudit: Array<string> | null = null;
    filterUser: Array<string> | null = null;
    filterDepartment: Array<string> | null = null;
    filterPosition: Array<string> | null = null;
    filterWorkplace: Array<string> | null = null;
    filterPlanItemState: Array<string> | null = null;
	filterManagerUser: Array<string> | null = null;
	filterOnlyMine = true;
	showSecondLine = false;
    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    filterCorrectiveMeasure = 0;
    filterIsImplementationClosed = null;
    mobileFiltersShow = false;
    pageIndex = 1;
    pageSize = 25;
    pagingChanged = false;

    get headers() {
        return [
            { text: this.$i18n.t("issues.time"), value: "time", align: "left", sortable: false },
            { text: this.$i18n.t("issues.audit.name"), value: "planItem.audit.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.evidenceNumber"), value: "evidenceNumber", align: "left", sortable: false },
            { text: this.$i18n.t("issues.user.displayName"), value: "planItem.tenantUser.user.displayName", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.workplace.name"), value: "workplace.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.shiftType.name"), value: "shiftType.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.planItemState"), value: "planItemState", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.findingManagers"), value: "findingManagers", align: "left", sortable: false, width: '14%' },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }

    @Prop() tenantId!: string;

    @Watch("filterCategory")
    @Watch("filterAudit")
    @Watch("filterUser")
    @Watch("filterDepartment")
    @Watch("filterPosition")
    @Watch("filterWorkplace")
    @Watch("filterPlanItemState")
    @Watch("filterOnlyMine")
    @Watch("filterManagerUser")
    filtersChanged () {
        this.pageIndex = 1;
        this.load();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged () {
        this.saveQuery();
        if (this.pagingChanged) {
            this.pagingChanged = false;
            return;
        }
        this.load();
    }

    get loading(): boolean {
        return planItemsStore.useGetter(CrudGetter.PendingBasic);
    }
    
    get issues (): PlanItemModel[] {
        return [...planItemsStore.useGetter(CrudGetter.DataBasic)];
    }

    get totalRows (): number {
        return ({...planItemsStore.useGetter(CrudGetter.DataStatsBasic)} as CrudDataStats)?.rowCount;
    }

    get downloading(): boolean {
        return issuesStore.useGetter(CrudGetter.Downloading);
    }

    mounted () {
        if (this.getQueryInt("pageSize") != null) {
            this.pageSize = this.getQueryInt("pageSize") ?? 25;
        }
        else {
            if (window.localStorage.ptPageSize && Number(window.localStorage.ptPageSize)) {
                this.pageSize = Number(window.localStorage.ptPageSize);
            }
            this.saveQuery();
        }
        this.load();
    }

    load () {
        planItemsStore.dispatch(CrudAction.GetAllBasic, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [
            { field: "responseLock", op: "eq", comparand: true },
            { field: "disagreementCount", op: "gt", comparand: 0 },
            { field: "planItemState", op: "in", comparand: [ PlanItemStateEnum.NeedsToCreateMeasures, PlanItemStateEnum.NeedsToPerformMeasures, PlanItemStateEnum.NeedsToVerifyMeasures, PlanItemStateEnum.AllMeasuresVerified ] },
            { field: "audit.category.correctionManagers.tenantUserId", op: "eq", comparand: this.tenantUser!.id! }
        ];
        let auditFilters: CrudQueryPredicate[] = [];
        let categoryFilters: CrudQueryPredicate[] = [];
        let userFilters: CrudQueryPredicate[] = [];
        let departmentFilters: CrudQueryPredicate[] = [];
        let positionFilters: CrudQueryPredicate[] = [];
        let workplaceFilters: CrudQueryPredicate[] = [];
        let planItemStateFilters: CrudQueryPredicate[] = [];
        if (!this.tenantUser.accessAllCategories) {
            filters.push({ field: "audit.category.categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
		if (this.filterManagerUser && this.filterManagerUser.length > 0) {
			filters.push({ field: "planItemFindingManagers.tenantUserId", op: "in", comparand: this.filterManagerUser });
		}
		else if (this.filterOnlyMine) {
			filters.push({ field: "planItemFindingManagers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
		}
        if (this.filterCategory && this.filterCategory.length > 0) {
            this.filterCategory.forEach((e: string) => {
                if (e) {
                    categoryFilters.push({
                        field: "audit.categoryId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterAudit && this.filterAudit.length > 0) {
            this.filterAudit.forEach((e: string) => {
                if (e) {
                    auditFilters.push({
                        field: "auditId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterUser && this.filterUser.length > 0) {
            this.filterUser.forEach((e: string) => {
                if (e) {
                    userFilters.push({
                        field: "tenantUserId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterDepartment && this.filterDepartment.length > 0) {
            this.filterDepartment.forEach((e: string) => {
                if (e) {
                    departmentFilters.push({
                        field: "tenantUser.departmentId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterPosition && this.filterPosition.length > 0) {
            this.filterPosition.forEach((e: string) => {
                if (e) {
                    positionFilters.push({
                        field: "tenantUser.positionId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterWorkplace && this.filterWorkplace.length > 0) {
            this.filterWorkplace.forEach((e: string) => {
                if (e) {
                    workplaceFilters.push({
                        field: "workplaceId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterPlanItemState && this.filterPlanItemState.length > 0) {
            this.filterPlanItemState.forEach((e: string) => {
                if (e) {
                    planItemStateFilters.push({
                        field: "planItemState",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }

        if (auditFilters.length > 0) {
            filters.push({
                field: "auditId",
                op: "wrap",
                comparand: auditFilters
            });
        }
        if (categoryFilters.length > 0) {
            filters.push({
                field: "audit.categoryId",
                op: "wrap",
                comparand: categoryFilters
            });
        }
        if (userFilters.length > 0) {
            filters.push({
                field: "tenantUserId",
                op: "wrap",
                comparand: userFilters
            });
        }
        if (departmentFilters.length > 0) {
            filters.push({
                field: "tenantUser.departmentId",
                op: "wrap",
                comparand: departmentFilters
            });
        }
        if (positionFilters.length > 0) {
            filters.push({
                field: "tenantUser.positionId",
                op: "wrap",
                comparand: positionFilters
            });
        }
        if (workplaceFilters.length > 0) {
            filters.push({
                field: "workplaceId",
                op: "wrap",
                comparand: workplaceFilters
            });
        }
        if (planItemStateFilters.length > 0) {
            filters.push({
                field: "planItemState",
                op: "wrap",
                comparand: planItemStateFilters
            });
        }
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<PlanItemModel>({ field: "time", index: 1, order: "desc" }, this.getFilters(), {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        });
    }

    exportClick () {
        const filters = this.getFilters();
        issuesStore.dispatch(CrudAction.Download, createCrudQueryPayload<PlanItemModel>({ field: "time", index: 1, order: "desc" }, filters));
    }

    saveQuery () {
        this.setQuery("pageSize", this.pageSize?.toString());
        window.localStorage.ptPageSize = this.pageSize?.toString();
    }

}
