import moment from 'moment';
import { GlobalVariables } from '@/libs/core/global';

export function formatDate(input: string) {
    if (!input) {
        return '';
    }

    return moment(input).format("DD.MM.YYYY HH:mm")
}

export function formatDateShort(input: string) {
    if (!input) {
        return '';
    }

    return moment(input).format("DD.MM HH:mm")
}
