



import { CodebookModel, PermissionTypeEnum, TenantModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { tenantsStore } from '@/libs/tenants/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import Page from '@/Page.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class Home extends Page {
    
    getDataList(): Array<any> {
        const tenantId = authStore.useGetter(AuthGetter.GetTenant);
        if (tenantId && tenantId.length > 0) {
            return [
                { text: this.$i18n.t("menu.dashboard"), to: { name: 'Dashboard', params: { tenantId: tenantId } }, icon: "dashboard.svg", permissions: [ PermissionTypeEnum.PerformAudit ] },
                { text: this.$i18n.t("menu.audit"), to: { name: 'Audits', params: { tenantId: tenantId } }, icon: "audits.svg", permissions: [ PermissionTypeEnum.Audits, PermissionTypeEnum.EditAllAudits, PermissionTypeEnum.EditOwnedAudit, PermissionTypeEnum.PerformAudit ] },
                { text: this.$i18n.t("menu.tasks"), to: { name: 'WorkTasks', params: { tenantId: tenantId } }, icon: "calendar.svg", permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Plan ] },
                { text: this.$i18n.t("menu.responses"), to: { name: 'Responses', params: { tenantId: tenantId } }, icon: "responses.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Response, PermissionTypeEnum.MistakeCorrection ] },
                { text: this.$i18n.t("menu.unfinishedResponses"), to: { name: 'Unanswered', params: { tenantId: tenantId } }, icon: "unanswered-responses.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Response ] },
                { text: this.$i18n.t("menu.issues"), to: { name: 'Issues', params: { tenantId: tenantId } }, icon: "issues.svg", permissions: [ PermissionTypeEnum.Findings ] },
                { text: this.$i18n.t("menu.solvedIssues"), to: { name: 'Measures', params: { tenantId: tenantId } }, icon: "measure.svg", permissions: [ PermissionTypeEnum.Measures, PermissionTypeEnum.MeasuresAllUsers ] },
                { text: this.$i18n.t("menu.verification"), to: { name: 'Verification', params: { tenantId: tenantId } }, icon: "resources.svg", permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.MeasureVerification ] },
                { text: this.$i18n.t("menu.review"), to: { name: 'Review', params: { tenantId: tenantId } }, icon: "tasks.svg", permissions: [ PermissionTypeEnum.PlanItemReview ] },
                { text: this.$i18n.t("menu.reports"), to: { name: 'ReportByQuestion', params: { tenantId: tenantId } }, icon: "reports.svg", permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ] }
            ];
        }
        else {
            return [];
        }
    }

    mounted () {
        applicationUserStore.dispatch(CrudAction.Get, {});
        this.subscribe(applicationUserStore, CrudReponse.Get).then((e: any) => {
            tenantsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantModel>({
                field: 'name',
                order: 'asc'
            }));
            this.subscribe(tenantsStore, CrudReponse.GetAll).then((e: TenantModel[]) => {
                if (e.length > 0) {
                    this.loadCodebooks().then(() => {
                        let tenantId = this.$route.params.tenantId;
                        if (!tenantId && window.localStorage.CurrentTenant && e.any(x => x.id == window.localStorage.CurrentTenant)) {
                            tenantId = window.localStorage.CurrentTenant;
                        }
                        else if (tenantId == undefined && e.length > 0) {
                            tenantId = e[0].id!;
                        }
                        authStore.dispatch(AuthAction.SetTenant, tenantId);
                        const route = this.getFirstRoute(e[0].id!);
                        route.params.tenantId = tenantId;
                        this.$router.push(route ?? { name: "UserSettings" });
                    });
                }
                else {
                    authStore.dispatch(AuthAction.SetTenant, "");
                    this.$router.push({ name: "UserSettings" });
                }
            });
        });
    }

    loadCodebooks () : Promise<any> {
        return new Promise((resolve, reject) => {
        codebooksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CodebookModel>());
            this.subscribe(codebooksStore, CrudReponse.GetAll).then((e: CodebookModel[]) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    getFirstRoute (tenantId: string | null): any {
        const routes = this.getDataList();
        for (const route of routes) {
            if (this.hasPermissions(route.permissions)) {
                return { name: route.to.name, params: { tenantId } };
            }
        }
        return null;
    }

}
