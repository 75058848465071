






























import moment from 'moment';
import { Component, Vue, Watch, Prop, VModel } from 'vue-property-decorator';

@Component
export default class ColorPicker extends Vue {

    menu = false;

    modal = false;
    menu2 = false;

    color = "";
    
    @VModel() innerValue!: string;
    @Prop() label!: [ string ];
    @Prop() placeholder!: [ string ];

    @Watch("color")
    colorChanged (v: string, p: string) {
        if (v == p)
            return;
        this.$emit("input", v == null ? "" : v.replaceAll("#", "").substring(0, 6));
    }

    @Watch("innerValue")
    innerValueChanged (v: string, p: string) {
        if (v == p)
            return;
        if (this.innerValue != null && this.innerValue.length > 1)
            this.color = this.innerValue.startsWith("#") ? this.innerValue : "#".concat(this.innerValue);
        else
            this.color = "#4527a0";
    }

    mounted () {
        if (this.innerValue != null && this.innerValue.length > 1)
            this.color = this.innerValue.startsWith("#") ? this.innerValue : "#".concat(this.innerValue);
        else
            this.color = "#4527a0";
    }

}
