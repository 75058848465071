















import { Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({ })
export default class TextArea extends Vue {

  innerValue: string | number | null = null;

  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() errorMessage!: string;
  @Prop() value!: string | number | null;
  @Prop() rows!: number | null;
  @Prop() autoGrow!: boolean;
  @Prop({default: false}) hideDetails!: boolean;
  @Prop({default: false}) readonly!: boolean;
  @Prop({default: false}) required!: boolean;
  @Prop() rules!: [ any[] ];

  get componentRules (): any[] {
    if (this.rules?.length > 0) {
      return this.rules;
    }
    else if (this.required) {
      return [
        (v: any) => !!v || this.$i18n.t('common.thisFileIsRequired')
      ];
    }
    return [];
  }

  mounted () {
    this.innerValue = this.$props.value;
  }

  @Watch("innerValue")
  textChanged(v: string | number | null, p: string | number | null) {
    if (v == p)
      return;
    this.$emit("input", v);
  }

  @Watch("value")
  valueChanged(v: string | number | null, p: string | number | null) {
    if (v == p)
      return;
    this.innerValue = v;
  }

}
