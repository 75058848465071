




import {Component, Vue, Prop, Watch, Emit} from 'vue-property-decorator';

@Component({})
export default class Slider extends Vue {

  @Prop() min!: number;
  @Prop() max!: number;
  @Prop() step!: number;
  @Prop() value!: string;
  @Prop() label!: string;
  @Prop({ default: true }) enabled!: boolean;

  private innerValue: any = null;

  @Watch("value")
  valueChanged(v: string, p: string) {
    if (v == p)
      return;
    this.innerValue = v;
  }

}
