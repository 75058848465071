import {
    HttpResponse,
    PlanItemModel,
    QueryModel,
    RequestParams,
    PlanItemModelIEnumerableApiResultSuccess,
    PlanItemModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class PlanItemsDataService implements CrudService<PlanItemModel, PlanItemModel> {
    
    getAggregation(query: AggregationModel): Promise<AggregationResponse> {
        throw new Error("Method not implemented.");
    }

    getAll(query: CrudDataQuery<PlanItemModel>): Promise<CrudEnumerableResponse<PlanItemModel>> {
        throw new Error('NotImplemented');
    }

    getBasicAll(query: CrudDataQuery<PlanItemModel>): Promise<CrudEnumerableResponse<PlanItemModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<PlanItemModel>> {
        throw new Error('NotImplemented');
    }

    create(item: PlanItemModel, params?: RequestParams): Promise<CrudResponse<PlanItemModel>> {
        throw new Error('NotImplemented');
    }

    update(item: PlanItemModel, params?: RequestParams): Promise<PlanItemModel> {
        throw new Error('NotImplemented');
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<PlanItemModel> {
        throw new Error('NotImplemented');
    }

    delete(query: CrudDataQuery<PlanItemModel>, params?: RequestParams): Promise<CrudEnumerableResponse<PlanItemModel>> {
        throw new Error('NotImplemented');
    }

    download(query: CrudDataQuery<PlanItemModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        return ApiService.api.exportPlanItemBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    clone(item: PlanItemModel, params?: RequestParams): Promise<CrudResponse<PlanItemModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error('NotImplemented');
    }

}
