import {RouteConfig} from 'vue-router';

import Settings from './pages/Settings.vue';
import Accounts from './pages/Accounts.vue';
import Organization from './pages/Organization.vue';
import Workplaces from './pages/Workplaces.vue';
import Categories from './pages/Categories.vue';
import Roles from './pages/Roles.vue';
import Groups from './pages/Groups.vue';
import NotRatedOptionReasons from './pages/NotRatedOptionReasons.vue';
import Departments from './pages/Departments.vue';
import Positions from './pages/Positions.vue';
import ShiftTypes from './pages/ShiftTypes.vue';
import Import from './pages/Import.vue';
import { PermissionTypeEnum } from '../Api';

export default [
    {
        path: '/:tenantId/settings',
        name: 'Settings',
        component: Settings,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.Admin ],
            title: "menu.settings"
        },
        children: [
            {
                path: 'accounts',
                name: 'Accounts',
                component: Accounts,
                meta: {
                    permissions: [ PermissionTypeEnum.Admin ],
                    title: "menu.settings"
                }
            },
            {
                path: 'organization',
                name: 'Organization',
                component: Organization,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Admin ],
                    title: "menu.settings"
                }
            },
            {
                path: 'workplaces',
                name: 'Workplaces',
                component: Workplaces,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.AdminGroups ],
                    title: "menu.settings"
                }
            },
            {
                path: '/:tenantId/categories',
                name: 'Categories',
                component: Categories,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.AdminGroups ],
                    title: "menu.settings"
                }
            },
            {
                path: '/:tenantId/roles',
                name: 'Roles',
                component: Roles,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Admin ],
                    title: "menu.settings"
                }
            },
            {
                path: '/:tenantId/groups',
                name: 'Groups',
                component: Groups,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.AdminGroups ],
                    title: "menu.settings"
                }
            },
            {
                path: '/:tenantId/not-rated-option-reasons',
                name: 'NotRatedOptionReasons',
                component: NotRatedOptionReasons,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.AdminGroups ],
                    title: "menu.settings"
                }
            },
            {
                path: '/:tenantId/departments',
                name: 'Departments',
                component: Departments,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.AdminGroups ],
                    title: "menu.settings"
                }
            },
            {
                path: '/:tenantId/positions',
                name: 'Positions',
                component: Positions,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.AdminGroups ],
                    title: "menu.settings"
                }
            },
            {
                path: '/:tenantId/shift-types',
                name: 'ShiftTypes',
                component: ShiftTypes,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.AdminGroups ],
                    title: "menu.settings"
                }
            },
            {
                path: 'import',
                name: 'Import',
                component: Import,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Admin, PermissionTypeEnum.Admin ]
                }
            }
        ]
    }
] as RouteConfig[];
