import { Vue } from 'vue-property-decorator';

Vue.filter('firstLetterUppercase', (value : string) => {
    if (value) {
        return value.substring(0, 1).toUpperCase() + value.substring(1);
    }
})

Vue.filter('truncate', (value : string, lenght = 50) => {
    if (value) {
        if (!lenght || lenght <= 0) {
            lenght = 50;
        }
        if (value.length > lenght) {
            return value.substring(0, lenght).concat("...");
        }
        return value;
    }
})

