import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import csTranslation from './translations/cs';
import enTranslation from './translations/en';

const messages = {
  cs: csTranslation,
  en: enTranslation
};

export const i18n = new VueI18n({
  locale: 'en',
  messages
});