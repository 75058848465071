

























import { PermissionTypeEnum, TenantUserModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { isArray } from 'lodash';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import Autocomplete from '../components/Autocomplete.vue';
import { tenantUsersStore } from '@/libs/tenants/+state/store';

@Component({})
export default class TenantUserSelect extends Vue {

    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: false }) createParty!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) noMarginTop!: boolean;
    @Prop({ default: true }) chips!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop() placeholder!: string;
    @Prop() exceptUsers!: string[];
    @Prop() permissions!: PermissionTypeEnum[];

    @Ref() autocomplete!: Autocomplete;

    get items(): TenantUserModel[] {
        let items = [...(tenantUsersStore.useGetter(CrudGetter.Data) as TenantUserModel[])];
        if (this.exceptUsers) {
            items = items.where(x => !this.exceptUsers.contains(x.id!) || x.id! == this.innerValue).toArray();
        }
        if (this.permissions && this.permissions?.length > 0) {
            items = items.where(x => x.userRoles?.any(n => n.role!.permissions!.any(j => this.permissions.contains(j.permissionType!))) || x.id! == this.innerValue).toArray();
        }
        return items.where(x => x.isActive == true || x.id! == this.innerValue).toArray().sort((a: TenantUserModel, b: TenantUserModel) => {
            if(a.user!.displayName! < b.user!.displayName!) { return -1; }
            if(a.user!.displayName! > b.user!.displayName!) { return 1; }
            return 0;
        });
    }

}
