




































































































































































































































































import {CategoryModel, CategoryUserModel, TenantInvitationModel, TenantUserModel} from '@/libs/Api';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import Page from '@/Page.vue';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import {tenantInvitationsStore, tenantUsersStore} from '@/libs/tenants/+state/store';

import AccountDialog from '@/libs/settings/components/AccountDialog.vue';
import InviteAccountDialog from '../components/InviteAccountDialog.vue';
import DeleteDialog from '@/libs/common/components/DeleteDialog.vue';
import ExportDialog from '@/libs/common/components-smart/ExportDialog.vue';
import {categoriesStore} from '@/libs/categories/+state/store';
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '@/libs/auth/models/auth-state';
import {AuthenticationType} from "@/libs/auth/models/auth-type";

@Component({
    components: {
        AccountDialog,
        InviteAccountDialog
    }
})
export default class Accounts extends Page {

    invitation: TenantInvitationModel | null = null;
    deleteInvitationDialog = false;
    deletingInvitation = false;
    tenantUser: TenantUserModel | null = null;
    deleting = false;
    filterIsActive: boolean | null = null;
    itemsPerPage = 10;
    tab = "accounts";

    @Prop() tenantId!: string;

    @Ref() accountDialog!: AccountDialog;
    @Ref() inviteAccountDialog!: InviteAccountDialog;
    @Ref() exportDialog!: ExportDialog;
    @Ref() deleteDialog!: DeleteDialog;

    @Watch("filterIsActive")
    filtersChanged () {
        this.loadItems();
    }

    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    get tableHeaders() {
        return [
            { text: this.$i18n.t("settings.accounts.columnName"), value: "lastName", align: "left", sortable: false },
            { text: this.$i18n.t("settings.accounts.columnPhoneNumber"), value: "phoneNumber", align: "left", sortable: false },
            { text: this.$i18n.t("settings.accounts.columnEmail"), value: "email", align: "left", sortable: false },
            // { text: this.$i18n.t("settings.accounts.allowedCategories"), value: "roles", align: "left", width: "40%", sortable: false },
            { text: this.$i18n.t("settings.accounts.columnRoles"), value: "roles", align: "left", width: "40%", sortable: false },
            { text: this.$i18n.t("settings.accounts.department"), value: "department.name", align: "left", sortable: false },
            { text: this.$i18n.t("settings.accounts.position"), value: "position.name", align: "left", sortable: false },
            { text: this.$i18n.t("settings.accounts.allowedCategories"), value: "categories", align: "left", sortable: false },
            { text: "", value: "isActive", align: "left", width: "100px", sortable: false },
            { text: "", value: "id", align: "right", width: "120px", sortable: false }
        ];
    }

    get tableInvitationsHeaders() {
        return [
            { text: this.$i18n.t("settings.accounts.columnEmail"), value: "emailAddress", align: "left", sortable: false },
            { text: this.$i18n.t("settings.accounts.columnRoles"), value: "roles", align: "left", width: "40%", sortable: false },
            { text: "", value: "id", align: "right", width: "120px", sortable: false }
        ];
    }

    get loading(): boolean {
        return tenantUsersStore.useGetter(CrudGetter.Pending);
    }

    get items(): TenantUserModel[] {
        const items = [...tenantUsersStore.useGetter(CrudGetter.Data)] as TenantUserModel[];
        return items.orderBy(x => x.user?.displayName).toArray();
    }
    
    get activeUserCount(): number {
        const items = [...tenantUsersStore.useGetter(CrudGetter.Data)] as TenantUserModel[];
        return items.count(x => x.isActive == true);
    }
    
    get inactiveUserCount(): number {
        const items = [...tenantUsersStore.useGetter(CrudGetter.Data)] as TenantUserModel[];
        return items.count(x => x.isActive != true);
    }

    get loadingInvitations(): boolean {
        return tenantInvitationsStore.useGetter(CrudGetter.Pending);
    }

    get invitations (): TenantInvitationModel[] {
        const invitations = [...tenantInvitationsStore.useGetter(CrudGetter.Data)] as TenantInvitationModel[];
        return invitations.orderBy(x => x.emailAddress).toArray();
    }

    get categories (): CategoryModel[] {
        return [...categoriesStore.useGetter(CrudGetter.Data)];
    }

	get enableInvitations(): boolean {
		const authenticationType = authStore.useGetter(AuthGetter.GetAuthenticationType) as AuthenticationType;
		return authenticationType != AuthenticationType.AzureActiveDirectory;
	}

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.load();
    }

    load () {
        this.loadItems();
        this.loadInvitations();
        this.loadCategories();
    }

    loadItems () {
        const filters = [];
        if (this.filterIsActive != null) {
            filters.push({ field: "isActive", op: "eq", comparand: this.filterIsActive })
        }
        tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>(undefined, filters));
    }

    loadInvitations () {
        tenantInvitationsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantInvitationModel>(undefined, [
            { field: "isActive", op: "eq", comparand: true }
        ]));
    }

    loadCategories () {
        categoriesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CategoryModel>({ field: "name", index: 1, order: "asc" }));
    }

    createItemClick () {
        this.inviteAccountDialog.open();
    }

    openItemClick (item: TenantUserModel) {
        this.accountDialog.open(item.id);
    }

    exportClick () {
        this.exportDialog.open();
    }

    deleteInvitationClick (invitation: TenantInvitationModel) {
        this.invitation = invitation;
        this.deleteInvitationDialog = true;
    }

    deleteInvitation () {
        tenantInvitationsStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TenantInvitationModel>(undefined, [
            { field: "id", op: "eq", comparand: this.invitation!.id! }
        ]))
        this.deletingInvitation = true;
        this.subscribe(tenantInvitationsStore, CrudReponse.Delete).then(() => {
            this.deleteInvitationDialog = false;
            this.deletingInvitation = false;
            this.loadInvitations();
        }).catch(() => {
            this.deletingInvitation = false;
        })

    }
    
    deleteItemClick (item: TenantUserModel) {
        this.deleteDialog.open();
        this.tenantUser = item;
    }

    deleteItem () {
        tenantUsersStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TenantInvitationModel>(undefined, [
            { field: "id", op: "eq", comparand: this.tenantUser!.id! }
        ]))
        this.deleting = true;
        this.subscribe(tenantUsersStore, CrudReponse.Delete).then(() => {
            this.deleteDialog.close();
            this.deleting = false;
            this.loadInvitations();
        }).catch(() => {
            this.deleting = false;
        })

    }

    getAllowedCategories (userId: string) {
        const categories: any[] = [];
        this.categories.forEach((c: CategoryModel) => {
            const result: any[] = [];
            result.push(
                c.categoryUsers?.filter((x: CategoryUserModel) => {
                    x.tenantUserId == userId
                }).toArray()
            );
            // console.log(result);
        });
        return categories;
    }

}
