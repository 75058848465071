















































import { AuditModel, TenantModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { adminTenantsStore } from '@/libs/admin-tenants/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import AdminTenant from './AdminTenant.vue';

@Component({
    components: {
        AdminTenant
    }
})
export default class AdminTenants extends Page {

    filterCategoryId: string | null = null;
    filterActive = true;
    itemsPerPage = 10;
    tenantDialog = false;
    activeTenant: TenantModel | null = null;
    
    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    get headers() {
        return [
            { text: "Datum registrace", value: "name", align: "left", sortable: false },
            { text: "Název", value: "name", align: "left", sortable: false },
            { text: "Licence do", value: "name", align: "left", sortable: false },
            { text: "Max. počet uživatelů", value: "name", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }

    get loading(): boolean {
        return adminTenantsStore.useGetter(CrudGetter.Pending);
    }
    
    get audits (): TenantModel[] {
        return [...adminTenantsStore.useGetter(CrudGetter.Data)];
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.load();
    }

    load () {
        adminTenantsStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [];
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<AuditModel>({ field: "createdAt", index: 1, order: "desc" }, this.getFilters());
    }

    openDetail (item: TenantModel) {
        this.tenantDialog = true;
        this.activeTenant = item;
    }

}
