






























import {Component, Vue, Prop, Watch, Emit, VModel} from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag'
import { GlobalVariables } from '@/libs/core/global';

@Component({
    components: {
        CountryFlag
    }
})
export default class FlagSelect extends Vue {

    items = [
        { id: "cs", flag: "cz", value: "Čeština" },
        { id: "en", flag: "gb", value: "English" }
    ];

    @VModel() innerValue: any;
    
    @Prop() label!: string;
    @Prop() itemText!: string;
    @Prop() itemValue!: string;
    @Prop({ default: true }) enabled!: boolean;
    @Prop() readonly!: boolean;
    @Prop() clearable!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop() placeholder!: string;
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) showText!: boolean;

    @Watch("innerValue")
    innerValueChanged (v: string, p: string) {
        if (v != null && v != p) {
            this.$i18n.locale = this.innerValue;
            window.localStorage.language = v;
            this.$vuetify.lang.current = v;
            GlobalVariables.lang = v;
        }
    }

}
