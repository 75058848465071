























import { CategoryModel, TenantUserModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { categoriesStore } from '@/libs/categories/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class CategorySelect extends Vue {

    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) noMarginTop!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: false }) all!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop({ default: true }) chips!: boolean;
    @Prop({ default: true }) clearable!: boolean;
    @Prop() placeholder!: string;
    @Prop() attach!: string;
    @Prop() except!: Array<string>;

    @Watch("innerValue")
    innerValueChanged (v: string) {
        this.$emit("change", v);
        this.$emit("onSelect", this.items.firstOrDefault(x => x.id == v));
    }

    get items(): CategoryModel[] {
        return categoriesStore.useGetter(CrudGetter.Data, "CategorySelect").filter((x: CategoryModel) => !this.except?.any(p => p == x.id) ?? true).toArray();
    }

    get selectRules () {
        if (this.rules && this.rules.length > 0) {
            return this.rules;
        }
        else if (this.required) {
            return [
                (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
            ];
        }
        return [];
    }

    mounted () {
        const query: CrudQueryPredicate[] = [];
        if (!this.all && !this.tenantUser.accessAllCategories) {
            query.push({ field: "categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
        categoriesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CategoryModel>({ field: "name", index: 1, order: "asc" }, query, undefined, false, "CategorySelect"));
    }

}
