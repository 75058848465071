




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: { }
})
export default class Cloud extends Vue {
    @Prop() tenantId!: string;
}
