



































































































































import {
    ApplicationUserModel,
    AuditModel,
    CategoryModel,
    FileDownloadModel,
    MeasureModel,
    PermissionTypeEnum,
    PlanItemCloseTypeEnum,
    PlanItemModel,
    ResponseModel,
    ResponseTypeEnum,
    TenantUserModel
} from "@/libs/Api";
import Page from "@/Page.vue";
import {Component, Prop, Ref, Watch} from "vue-property-decorator";
import {cloneDeep, isEqual} from "lodash";
import {auditsStore} from "@/libs/audits/+state/store";
import {CrudAction, CrudReponse} from "@/libs/core/+state/models/crud-action";
import {Guid} from "@/libs/common/functions/guid";
import moment from "moment";
import {planItemsStore} from "@/libs/plan-items/+state/store";
import {applicationUserStore} from "@/libs/user-settings/+state/store";
import {CrudGetter} from "@/libs/core/+state/models/crud-getter";
import {authStore} from "@/libs/auth/+store/store";
import {AuthGetter} from "@/libs/auth/models/auth-state";
import ApiService from "@/libs/core/api-service"

import WorkTaskAuditTab from '@/libs/work-tasks/pages/WorkTaskAuditTab.vue';
import WorkTaskFindingTab from '@/libs/work-tasks/pages/WorkTaskFindingTab.vue';
import WorkTaskMeasureTab from '@/libs/work-tasks/pages/WorkTaskMeasureTab.vue';

import WorkTaskRequestApprovalDialog from '@/libs/work-tasks/components/WorkTaskRequestApprovalDialog.vue';
import WorkTaskApproveDialog from '@/libs/work-tasks/components/WorkTaskApproveDialog.vue';
import WorkTaskDeclineDialog from '@/libs/work-tasks/components/WorkTaskDeclineDialog.vue';
import WorkTaskCloseDialog from '@/libs/work-tasks/components/WorkTaskCloseDialog.vue';
import {measuresStore} from "@/libs/measures/+state/store";
import {VDA63} from '@/audit-settings/VDA63';
import AiSummaryGenerator from "@/libs/work-tasks/components/AiSummaryGenerator.vue";

const b64toBlob = (b64Data: string, contentType='', sliceSize=512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

@Component({
    components: {
      AiSummaryGenerator,
        WorkTaskAuditTab,
        WorkTaskFindingTab,
        WorkTaskMeasureTab,
        WorkTaskCloseDialog,
        WorkTaskRequestApprovalDialog,
        WorkTaskApproveDialog,
        WorkTaskDeclineDialog
    }
})
export default class WorkTask extends Page {

    /* Page props */
    @Prop() id!: string;
    @Prop() tenantId!: string;
    
    /* Model variables */
    planItem: PlanItemModel | null = null;
    planItemOriginal: PlanItemModel | null = null;
    audit: AuditModel | null = null;
    category: CategoryModel | undefined = undefined;
    day!: string;
    auditId!: string;

    /* Component state variables */
    tab = 0;
    loading = true;
    saving = false;
    responseLockDialog = false;
    hasChanges = false;
    auditPerformed = false;
    planItemId: string | null = null;
    closePlanItemDialog = false;
    requestApprovalPlanItemDialog = false;
    approvePlanItemDialog = false;
    declinePlanItemDialog = false;
    printing = false;
    deleting = false;
    deleteDialog = false;

    /* User state variables */
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    /* Refs */
    @Ref() auditTab!: WorkTaskAuditTab;
    @Ref() aiSummaryDialog!: AiSummaryGenerator;

    /* Watches */
    @Watch("planItem", { deep: true })
    planItemChanged () {
        this.checkChanges();
    }

    @Watch("id")
    idChanged (v: string) {
        this.tab = 0;
        this.planItemId = v;
        this.planItem = null;
        this.planItemOriginal = null;
        this.audit = null;
        this.category = undefined;
        this.day = "";
        this.load();
    }

    /* Getters */

    get isLoading() {
        return this.loading || this.auditTab?.loading == true;
    }

    get isValid(): boolean {
        return this.auditTab?.isValid();
    }
    
    get canEditWorkplace () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditQuestions () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || (permissions.contains(PermissionTypeEnum.MistakeCorrection));
    }

    get canEditFiles () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditCorrectiveMeasurements () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.responseLock == true && permissions.contains(PermissionTypeEnum.Findings)) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canCorrectMistakes () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canReviewAudit () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.responseLock == true && permissions.contains(PermissionTypeEnum.PlanItemReview)) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }
    
    get canRemovePlanItem () {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get routeName () {
        return this.$router.currentRoute.name;
    }

    get storedPlanItem (): PlanItemModel {
        return planItemsStore.useGetter(CrudGetter.Detail, this.id) as PlanItemModel;
    }

    get canCloseAudit(): boolean {
        if (this.storedPlanItem?.audit?.category?.planItemCloseType == PlanItemCloseTypeEnum.ByAuditorOrReviewer) {
            return this.planItem?.tenantUserId == this.tenantUser.id || this.planItem?.reviewedById == this.tenantUser.id;
        }
        else if (this.storedPlanItem?.audit?.category?.planItemCloseType == PlanItemCloseTypeEnum.ByAuditor) {
            return this.planItem?.tenantUserId == this.tenantUser.id;
        }
        else if (this.storedPlanItem?.audit?.category?.planItemCloseType == PlanItemCloseTypeEnum.ByReviewer) { 
            return this.planItem?.reviewedById == this.tenantUser.id;
        }
        return false;
    }

    get useAuditApproval(): boolean {
        return (this.storedPlanItem?.audit?.category?.useAuditApproval == true && (this.storedPlanItem?.responses?.selectMany(x => x.findings ?? [])?.count() ?? 0) > 0) || (this.storedPlanItem?.audit?.category?.useAuditApprovalWithNoFindings == true && (this.storedPlanItem?.responses?.selectMany(x => x.findings ?? [])?.count() ?? 0) == 0);
    }

    get canSendToApproval(): boolean {
        const measures = [...measuresStore.useGetter(CrudGetter.Data)] as MeasureModel[];
        if (this.storedPlanItem?.tenantUserId == this.tenantUser.id) {
            return true;
        }
        else if (measures.any(x => x.verificationUserId == this.tenantUser.id)) {
            return true;
        }
        return false;
    }

    /* Mounted */
    mounted() {
        if (this.id && this.id != Guid.EmptyGuid()) {
            this.planItemId = this.id;
            this.load();
        }
        else {
            this.planItemId = Guid.EmptyGuid();
            this.auditId = this.$router.currentRoute.params.auditId;
            this.day = moment().format("YYYY-MM-DDT00:00:00");
            this.load();
        }
        
    }

    /* Action functions */

    private load () {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.loadPlanItem().then((e: PlanItemModel) => {
                if (e.auditId)
                    this.auditId = e.auditId;
                this.loadAudit().then((e: AuditModel) => {
                    this.loading = false;
                }).catch((e: any) => {
                    reject(e);
                });
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private loadPlanItem() : any {
        return new Promise((resolve, reject) => {
            if (this.planItemId == Guid.EmptyGuid()) {
                auditsStore.dispatch(CrudAction.Get, { id: this.auditId });
                this.subscribe(auditsStore, CrudReponse.Get).then((e: AuditModel) => {
                    this.planItem = {
                        id: Guid.EmptyGuid(),
                        userId: this.applicationUser.id,
                        auditId: this.auditId,
                        audit: e,
                        time: moment().format("YYYY-MM-DDT00:00:00"),
                        responses: [],
                        responseLock: false,
                        fullLock: false
                    } as PlanItemModel;
                    this.planItemOriginal = cloneDeep(this.planItem);
                    this.category = e.category;
                    this.hasChanges = false;
                    resolve(this.planItem);
                }).catch((e) => {
                    reject(e);
                })
            }
            else {
                planItemsStore.dispatch(CrudAction.Get, { id: this.id, key: this.id });
                this.subscribe(planItemsStore, CrudReponse.Get, this.id).then((e: PlanItemModel) => {
                    this.planItem = e;
                    this.planItemOriginal = cloneDeep(this.planItem);
                    this.category = e.audit!.category;
                    this.hasChanges = false;
                    resolve(e);
                }).catch((e: any) => {
                    reject(e);
                });
            }
        });
    }

    private loadAudit (): Promise<any> {
        return new Promise((resolve, reject) => {
            auditsStore.dispatch(CrudAction.Get, { id: this.auditId!, key: this.auditId! });
            this.subscribe(auditsStore, CrudReponse.Get, this.auditId!).then((e: AuditModel) => {
                this.audit = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private save (showResponseLock: true) {
		this.responseLockDialog = false;
        if (this.auditTab?.validate()) {
            const notFinishedResponses: Array<ResponseModel> = this.auditTab.getNotFinishedResponses();
            let validQuestions = true;
            if (this.audit?.category?.responseType == ResponseTypeEnum.VDA63) {
                validQuestions = this.validateVda63Questions();
            }
            if (this.canEditQuestions && !this.planItem!.responseLock && this.planItem!.isRequestedResponseLock != true && showResponseLock && validQuestions && notFinishedResponses.length == 0) {
                this.responseLockDialog = true;
            }
            else {
                if (notFinishedResponses.length > 0) {
                    this.planItem!.isRequestedResponseLock = false;
                }
                this.saving = true;
                const responses: Array<ResponseModel> = this.auditTab.getResponses();
                const planItem = {...this.planItem};
                planItem.responses = responses;
                if (this.planItemId == Guid.EmptyGuid()) {
                    planItemsStore.dispatch(CrudAction.Create, { item: planItem });
                    this.subscribe(planItemsStore, CrudReponse.Create).then((e: PlanItemModel) => {
                        this.auditPerformed = true;
                        this.planItem = e;
                        this.planItemOriginal = cloneDeep(this.planItem);
                        this.saving = false;
                        this.auditTab.refresh();
                        this.$router.replace({ name: "WorkTaskAuditTab", params: { tenantId: this.$router.currentRoute.params.tenantId, id: e.id! }})
                    }).catch((e) => {
                        this.saving = false;
                        throw e;
                    });
                }
                else {
                    planItemsStore.dispatch(CrudAction.Update, { item: planItem });
                    this.subscribe(planItemsStore, CrudReponse.Update).then((e: PlanItemModel) => {
                        this.auditPerformed = true;
                        this.planItem = e;
                        this.planItemOriginal = cloneDeep(this.planItem);
                        this.saving = false;
                        this.auditTab.refresh();
                    }).catch((e) => {
                        this.saving = false;
                        throw e;
                    });
                }
            }
        }
    }
    
    validateVda63Questions(): boolean {
        const responses = this.auditTab.getResponses();
        for (const processElement of VDA63.ProcessElementsSteps) {
            if (responses.any(x => x.question?.questionGroup?.processElement == processElement.Element)) {
                if (processElement.Validation.ResponseCount != null) {
                    if (responses.count(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1) < processElement.Validation.ResponseCount!) {
                        return false;
                    }
                }
                if (processElement.Validation.ProductResponseCount != null) {
                    if (responses.count(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1 && x.processStep?.isHidden == true && x.processStep.stepNumber == "Product") < processElement.Validation.ProductResponseCount!) {
                        return false;
                    }
                }
                if (processElement.Validation.ProcessResponseCount != null) {
                    if (responses.count(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1 && x.processStep?.isHidden == true && x.processStep.stepNumber == "Process") < processElement.Validation.ProcessResponseCount!) {
                        return false;
                    }
                }
                if (processElement.Validation.ProcessStepResponseCount != null) {
                    if (responses.where(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1 && this.audit!.processSteps!.any(n => n.id == x.processStepId && n.isHidden != true)).groupBy(x => x.questionId!).count() < processElement.Validation.ProcessStepResponseCount!) {
                        return false;
                    }
                }
            }
        }
        return true;
        
    }
    
    canEditAnyImplementationOfCorrectiveMeasurements () {
        if (this.planItem == null || this.planItem.responses == null)
            return false;
        return this.planItem.responses.any(x => this.canEditImplementationOfCorrectiveMeasurements(x));
    }

    canEditImplementationOfCorrectiveMeasurements (response: ResponseModel) {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.responseLock == true && this.planItem.fullLock == false && (permissions.contains(PermissionTypeEnum.Measures) || permissions.contains(PermissionTypeEnum.MeasuresAllUsers)) && response.implementerUserId == this.tenantUser.id) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }
    
    checkChanges () {
		this.hasChanges = !isEqual(this.planItemOriginal, this.planItem) || this.auditTab?.hasChanges();
    }

    refreshPlanItem () {
        planItemsStore.dispatch(CrudAction.Get, { id: this.id, key: this.id });
    }
    
    printPlanItem() {
        this.printing = true;
        ApiService.api.printPlanItemById(this.id).then((e: any) => {
            this.printing = false;
            const res = e.data.returnValue as FileDownloadModel
            const blob = b64toBlob(res.base64!, res.contentType!);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', res.filename!);
            document.body.appendChild(link);
            link.click();
            link.parentNode!.removeChild(link);
        }).catch((e: any) => {
            this.printing = false;
            throw e;
        });
    }
    
    onDeletePlanItemClick() {
        this.deleteDialog = true;
    }
    
    onDeletePlanItem() {
        this.deleting = true;
        planItemsStore.dispatch(CrudAction.Delete, { id: this.id, key: this.id });
        this.subscribe(planItemsStore, CrudReponse.Delete, this.id).then(() => {
            this.deleting = false;
            this.$router.push({ name: 'Responses' });
        }).catch((e) => {
            console.log(e);
            this.deleting = false;
        })
    }

    generateSummary(): void {
      this.aiSummaryDialog.open();
    }

}

