
































































































import {
  ApplicationUserModel,
  RoleModel,
  PlanItemModel,
  QuestionGroupModel,
  ResponseModel,
  PermissionModel,
  UserRoleModel,
  PermissionTypeEnum
} from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { Guid } from '@/libs/common/functions/guid';
import { rolesStore } from '@/libs/roles/+state/store';
import { userRolesStore } from '@/libs/user-roles/+state/store';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';

@Component({})
export default class RoleDialog extends Page {

    id!: string;
    dialog = false;
    valid = true;
    saving = false;
    loading = false;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    role : RoleModel | null = null;
    permissions: any = {
        Admin: false,
        Audits: false,
        Plan: false,
        EditAllAudits: false,
        EditOwnedAudit: false,
        PerformAudit: false,
        Response: false,
        Findings: false,
        Measures: false,
        MeasureVerification: false,
        MistakeCorrection: false,
        PlanItemReview: false,
        MeasuresAllUsers: false
    };
    deleteDialog = false;
    deleting = false;
    canDelete = false;
    
    @Ref() form!: any;
    
    get isNewItem(): boolean {
        return this.role?.id == Guid.EmptyGuid();
    }
    
    open (id: string) {
        this.dialog = true;
        this.id = id;
        this.load();
    }

    close () {
        this.dialog = false;
    }

    private load (): Promise<any> {
        if (this.id != Guid.EmptyGuid()) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.loadRole().then((e) => {
                    const role = e as RoleModel;
                    this.loading = false;
                    this.permissions = {
                        Admin: false,
                        Audits: false,
                        Plan: false,
                        EditAllAudits: false,
                        EditOwnedAudit: false,
                        PerformAudit: false,
                        Response: false,
                        Findings: false,
                        Measures: false,
                        MeasureVerification: false,
                        MistakeCorrection: false,
                        PlanItemReview: false,
                        MeasuresAllUsers: false
                    };
                    for (const permission of role.permissions!) {
                        this.permissions[permission.permissionType!] = true;
                    }
                    resolve(e);
                }).catch((e: any) => {
                    reject(e);
                });
            });
        }
        else {
            this.role = {
                id: Guid.EmptyGuid(),
                code: "",
                name: ""
            } as RoleModel;
            this.permissions = {
                Admin: false,
                Audits: false,
                Plan: false,
                EditAllAudits: false,
                EditOwnedAudit: false,
                PerformAudit: false,
                Response: false,
                Findings: false,
                Measures: false,
                MeasureVerification: false,
                MistakeCorrection: false,
                PlanItemReview: false,
                MeasuresAllUsers: false
            };
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    }

    private loadRole() {
        return new Promise((resolve, reject) => {
            rolesStore.dispatch(CrudAction.Get, { id: this.id });
            this.subscribe(rolesStore, CrudReponse.Get).then((e) => {
                this.role = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private save () {
        if (this.form.validate()) {
            const role = {...this.role};
            this.saving = true;
            if (this.id == Guid.EmptyGuid()) {
                role!.permissions = [];
                for (const key in this.permissions) {
                    if (this.permissions[key.toString()] == true) {
                        role.permissions!.push({
                            id: Guid.EmptyGuid(),
                            permissionType: key
                        } as PermissionModel);
                    }
                }
                rolesStore.dispatch(CrudAction.Create, { item: role });
                this.subscribe(rolesStore, CrudReponse.Create).then(() => {
                    this.load().then(() => {
                        this.saving = false;
                        this.close();
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            }
            else {
                role!.permissions = [];
                for (const key in this.permissions) {
                    if (this.permissions[key.toString()] == true) {
                        role.permissions!.push({
                            id: Guid.EmptyGuid(),
                            permissionType: key
                        } as PermissionModel);
                    }
                }
                for (const key in this.permissions) {
                    if (this.permissions[key.toString()] == true) {
                        if (!role!.permissions!.any(x => x.permissionType == key)) {
                            role!.permissions!.push({
                                id: Guid.EmptyGuid(),
                                permissionType: key
                            } as PermissionModel);
                        }
                    }
                }
                rolesStore.dispatch(CrudAction.Update, { item: role });
                this.subscribe(rolesStore, CrudReponse.Update).then(() => {
                    this.load().then(() => {
                        this.saving = false;
                        this.close();
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            }
        }
    }

    deleteClick () {
        userRolesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<UserRoleModel>(undefined, [
            { field: "roleId", op: "eq", comparand: this.role!.id! }
        ], undefined, false, "RoleDelete"));
        this.subscribe(userRolesStore, CrudReponse.GetAll, "RoleDelete").then((e: UserRoleModel[]) => {
            this.canDelete = e.length == 0;
            this.deleteDialog = true;
        });
    }

    deleteRole () {
        this.deleting = true;
        rolesStore.dispatch(CrudAction.Delete, { id: this.id });
        this.subscribe(rolesStore, CrudReponse.Delete).then((e: any) => {
            this.deleting = false;
            this.deleteDialog = false;
            this.close();
        }).catch((e: any) => {
            this.deleting = false;
        });
    }

}
