




import { Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({ })
export default class TextArea extends Vue {

  innerValue: string | number | null = null;

  @Prop() label!: string;
  @Prop() errorMessage!: string;
  @Prop() value!: string | number | null;

  mounted () {
    this.innerValue = this.$props.value;
  }

  @Watch("innerValue")
  textChanged(v: string | number | null, p: string | number | null) {
    if (v == p)
      return;
    this.$emit("input", v);
  }

  @Watch("value")
  valueChanged(v: string | number | null, p: string | number | null) {
    if (v == p)
      return;
    this.innerValue = v;
  }

}
