import {CrudDataQuery, CrudEntity} from '@/libs/core/+state/models/crud-service';
import {AggregationEnum, AggregationModel, QueryModel} from '@/libs/Api';
import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import {CrudOrderBy} from '@/libs/core/+state/models/crud-order-by';
import {CrudQueryPaging} from '@/libs/core/+state/models/crud-query-paging';

export function createAggregationQueryPayload(predicates: CrudQueryPredicate[] = [], aggregationPropertyName = "", key = ""): { query: AggregationModel, key: string } {
    return {
        query: {
            predicates: predicates,
            aggregation: AggregationEnum.Sum,
            aggregationProperty: aggregationPropertyName
        } as AggregationModel,
        key: key
    }
}