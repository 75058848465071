import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';

import en from 'vuetify/src/locale/en'
import cs from 'vuetify/src/locale/cs'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { cs, en },
        current: 'cs',
    },
});
