import {
    HttpResponse,
    NotRatedOptionReasonModel,
    QueryModel,
    RequestParams,
    NotRatedOptionReasonModelIEnumerableApiResultSuccess,
    NotRatedOptionReasonModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class NotRatedOptionReasonsDataService implements CrudService<NotRatedOptionReasonModel, NotRatedOptionReasonModel> {
    getGroupped(query: GrouppedQueryModel, params?: RequestParams | undefined): Promise<CrudResponse<any>> {
        throw new Error("Method not implemented.");
    }
    getAll(query: CrudDataQuery<NotRatedOptionReasonModel>): Promise<CrudEnumerableResponse<NotRatedOptionReasonModel>> {
        return ApiService.api.getNotRatedOptionReasonBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<NotRatedOptionReasonModel>): Promise<CrudEnumerableResponse<NotRatedOptionReasonModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<NotRatedOptionReasonModel>> {
        return ApiService.api.getNotRatedOptionReasonById(id, params);
    }

    create(item: NotRatedOptionReasonModel, params?: RequestParams): Promise<CrudResponse<NotRatedOptionReasonModel>> {
        return ApiService.api.insertNotRatedOptionReason(item, params);
    }

    update(item: NotRatedOptionReasonModel, params?: RequestParams): Promise<NotRatedOptionReasonModel> {
        return ApiService.api.updateNotRatedOptionReason(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<NotRatedOptionReasonModel> {
        return ApiService.api.patchNotRatedOptionReason(id, patches, params).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<NotRatedOptionReasonModel>): Promise<CrudEnumerableResponse<NotRatedOptionReasonModel>> {
        return ApiService.api.deleteNotRatedOptionReasons(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        return ApiService.api.aggregationNotRatedOptionReason(query, params).then((req: any) => req.data.returnValue);
    }
    
    download(query: CrudDataQuery<NotRatedOptionReasonModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: NotRatedOptionReasonModel, params?: RequestParams): Promise<CrudResponse<NotRatedOptionReasonModel>> {
        throw new Error('NotImplemented');
    }

}
