
























import { AuditModel, TenantUserModel } from '@/libs/Api';
import { auditsStore } from '@/libs/audits/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class AuditSelect extends Vue {

    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    @Prop({ default: "" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() type!: string;
    @Prop() placeholder!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) all!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: true }) chips!: boolean;
    @Prop({ default: null }) categoryId!: string | Array<string> | null;

    @Watch("innerValue")
    innerValueChanged (v: string) {
        this.$emit("change", v);
    }

    get items(): AuditModel[] {
        let audits = auditsStore.useGetter(CrudGetter.Data, "AuditSelect") as AuditModel[];
        if (this.categoryId != null && typeof this.categoryId == "string") {
            audits = audits.where(x => x.categoryId == this.categoryId).toArray();
        } else if (this.categoryId && this.categoryId!.length > 0) {
            let result: Array<AuditModel> = [];
            audits.forEach((x: AuditModel) => {
                if (this.categoryId!.includes(String(x.categoryId))) {
                    result.push(x);
                }
            });
            audits = result;
        }
        return audits;
    }

    get loading(): boolean {
        return auditsStore.useGetter(CrudGetter.Data, "AuditSelect");
    }

    mounted () {
        const query: CrudQueryPredicate[] = [];
        if (!this.all && !this.tenantUser.accessAllCategories) {
            query.push({ field: "category.categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
        auditsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<AuditModel>({ field: "name", index: 1, order: "asc" }, query, undefined, false, "AuditSelect"));
    }

}
