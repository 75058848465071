









import { ImplementationStateEnum, MeasureTypeEnum, PlanItemStateEnum, RepeatFrequencyEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class ImplementationStateChip extends Vue {

    @VModel() innerValue!: ImplementationStateEnum;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) white!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) clearable!: boolean;
    @Prop({ default: false }) onlyMeasures!: boolean;
    @Prop({ default: 0 }) maxWidth!: number;

    get chipColor () {
        switch (this.innerValue) {
            case ImplementationStateEnum.New:
                return "orange";
            case ImplementationStateEnum.InProgress:
                return "cyan";
            case ImplementationStateEnum.WaitingForVerification:
                return "cyan";
            case ImplementationStateEnum.Verified:
                return "green";
        }
        return "black";
    }

}
