
import { reject } from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";
import { PermissionTypeEnum } from "./libs/Api";
import { authStore } from "./libs/auth/+store/store";
import { AuthGetter } from "./libs/auth/models/auth-state";
import { CreateCrudStoreResult } from "./libs/core/+state/models/create-crud-store-result";
import { CrudAction, CrudReponse, CrudResponseAction } from "./libs/core/+state/models/crud-action";
import moment from "moment/moment";
import {GlobalVariables} from "@/libs/core/global";

type Dictionary<T> = { [key: string]: T }

@Component({
  components: {
  },
})
export default class Page extends Vue {

    private query: Dictionary<string | (string | null)[]> = {};
    private params: Dictionary<string> = {};

    @Watch("$route")
    routeChanged () {
        this.query = this.$router.currentRoute.query;
        this.params = this.$router.currentRoute.params;
    }

    mounted () {
        this.query = this.$router.currentRoute.query;
        this.params = this.$router.currentRoute.params;
    }

    subscribe (store: CreateCrudStoreResult<any>, action: CrudReponse, key: string | null = null) : Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                let payloadKey = mutation.payload.key;
                if (!payloadKey) {
                    payloadKey = "";
                }
                if (!key) {
                    key = "";
                }
                if (payloadKey != key) {
                    return;
                }
                switch (action) {
                    case CrudReponse.GetAll:
                        if (mutation.type === store.getActionName(CrudResponseAction.GetAllSuccess)) {
                            resolve(mutation.payload.data);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.GetAllNoop)) {
                            resolve(mutation.payload.data);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.GetAllFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.GetAllBasic:
                        if (mutation.type === store.getActionName(CrudResponseAction.GetAllBasicSuccess)) {
                            resolve(mutation.payload.data);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.GetAllBasicNoop)) {
                            resolve(mutation.payload.data);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.GetAllBasicFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.Get:
                        if (mutation.type === store.getActionName(CrudResponseAction.GetSuccess)) {
                            resolve(mutation.payload.item);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.GetFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.Create:
                        if (mutation.type === store.getActionName(CrudResponseAction.CreateSuccess)) {
                            resolve(mutation.payload.item);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.CreateFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.Clone:
                        if (mutation.type === store.getActionName(CrudResponseAction.CloneSuccess)) {
                            resolve(mutation.payload.item);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.CloneFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.Update:
                        if (mutation.type === store.getActionName(CrudResponseAction.UpdateSuccess)) {
                            resolve(mutation.payload.item);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.UpdateFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.PartialUpdate:
                        if (mutation.type === store.getActionName(CrudResponseAction.PartialUpdateSuccess)) {
                            resolve(mutation.payload.item);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.PartialUpdateFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.BatchPartial:
                        if (mutation.type === store.getActionName(CrudResponseAction.BatchPartialUpdateSuccess)) {
                            resolve(mutation.payload.item);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.BatchPartialUpdateFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.Delete:
                        if (mutation.type === store.getActionName(CrudResponseAction.DeleteSuccess)) {
                            resolve(mutation.payload.data);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.DeleteFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                    case CrudReponse.Download:
                        if (mutation.type === store.getActionName(CrudResponseAction.DownloadSuccess)) {
                            resolve(mutation.payload.data);
                            subscribtion();
                        }
                        else if (mutation.type === store.getActionName(CrudResponseAction.DownloadFailed)) {
                            reject(mutation.payload);
                            subscribtion();
                        }
                        break;
                }
            });
        });
    }

    public createPromise (store: CreateCrudStoreResult<any>, payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            store.dispatch(CrudAction.Create, payload);
            this.subscribe(store, CrudReponse.Create).then((e: any) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    public createAllPromise (store: CreateCrudStoreResult<any>, payload: Array<any>): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.createAllPromisePop(store, payload, resolve, reject);
        });
    }

    private createAllPromisePop(store: CreateCrudStoreResult<any>, payload: Array<any>, resolve: (value: any) => void, reject: (reason?: any) => void) {
        if (payload.length > 0) {
            const payloadItem = payload[0];
            store.dispatch(CrudAction.Create, payloadItem);
            this.subscribe(store, CrudReponse.Create).then((e: any) => {
                payload.shift();
                this.createAllPromisePop(store, payload, resolve, reject);
            }).catch((e: any) => {
                reject(e);
            });
        }
        else {
            resolve(null);
        }
    }

    public updatePromise (store: CreateCrudStoreResult<any>, payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            store.dispatch(CrudAction.Update, payload);
            this.subscribe(store, CrudReponse.Update).then((e: any) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    public updateAllPromise (store: CreateCrudStoreResult<any>, payload: Array<any>): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.updateAllPromisePop(store, payload, resolve, reject);
        });
    }

    private updateAllPromisePop(store: CreateCrudStoreResult<any>, payload: Array<any>, resolve: (value: any) => void, reject: (reason?: any) => void) {
        if (payload.length > 0) {
            const payloadItem = payload[0];
            store.dispatch(CrudAction.Update, payloadItem);
            this.subscribe(store, CrudReponse.Update).then((e: any) => {
                payload.shift();
                this.updateAllPromisePop(store, payload, resolve, reject);
            }).catch((e: any) => {
                reject(e);
            });
        }
        else {
            resolve(null);
        }
    }

    public deletePromise (store: CreateCrudStoreResult<any>, payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            store.dispatch(CrudAction.Delete, payload);
            this.subscribe(store, CrudReponse.Delete).then((e: any) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    setQuery (q: string, v: string | null) {
        if (v == null || v.length == 0 || v == "null") {
            if (this.query[q]) {
                delete this.query[q];
            }
        }
        else {
            this.query[q] = v.toString();
        }
        this.replaceRoute();
    }

    getQuery (q: string): string | null {
        const currentRoute = this.$router.currentRoute;
        if (currentRoute.query[q] && currentRoute.query[q]?.toString().length > 0) {
            return currentRoute.query[q].toString();
        }
        return null;
    }

    getQueryInt (q: string): number | null {
        var v = this.getQuery(q);
        if (v && !isNaN(Number(v!)))
            return Number(v);
        return null;
    }

    private replaceRoute () {
        var value: Dictionary<string | (string | null)[]> = {};
        var keys = Object.keys(this.query);
        keys.forEach((k) => {
            if (this.query[k] && this.query[k]?.length > 0) {
                value[k] = this.query[k].toString();
            }
        })
        value["t"] = new Date().getTime().toString();
        this.$router.replace({
            name: this.$router.currentRoute.name!,
            params: this.$router.currentRoute.params,
            query: value
        }).catch(err => { const x = 0; });
    }

    hasPermissions (permissionTypes: PermissionTypeEnum[]) {
        if (!permissionTypes)
            return true;
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return permissions.any(x => permissionTypes.any(p => p == x));
    }

    textAreaToHtml (value : string | null, length = 0) {
        if (value) {
            value = value.replaceAll("\n", "<br />");
        }
        if (value) {
            if (length > 0) {
                if (value.length > length) {
                    value = value.substring(0, length).concat("...");
                }
            }
        }
        return value;
    }

    public useWhiteFont(color: string | null | undefined) {
        return (parseInt((color ?? '000').replace('#', ''), 16) > 0xffffff / 2) ? false : true;
    }
    
    formatSimpleDate(value: string) {
        if (value) {
            return moment(String(value)).locale(GlobalVariables.lang).format('DD.MM.YYYY')
        }
        return '';
    }

}
