















import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component({ })
export default class AccountTypeSelect extends Vue {

    @Prop() label!: string;
    @VModel() innerValue!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: true }) includeClient!: boolean;
    @Prop({ default: true }) clearable!: boolean;
    @Prop() placeholder!: string;

    get items(): any[] {
        const items = [
          { id: "Admin", name: this.$i18n.t("common.admin") }
        ];
        return items;
    }

}
