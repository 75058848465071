




















import {Component, Vue, Prop} from 'vue-property-decorator';
@Component
export default class Button extends Vue {

    @Prop({ default: false }) saving!: boolean;
    @Prop() icon!: string;
    @Prop() cssClass!: string;
    @Prop() text!: string;
    @Prop({ default: false }) disabled: boolean | undefined;
    @Prop() tooltip!: boolean;
    @Prop() tooltipText!: string;
    @Prop() block!: boolean;
    @Prop({default: 'button'}) type!: 'submit' | 'reset' | 'button'

    get iconCss(): string {
        if (this.$props.icon)
            return 'ms-Icon ' + this.$props.icon;
        else 
            return '';
    }

    get target(): string {
        const a = String.fromCharCode(97+Math.floor(Math.random() * 26));
        const b = String.fromCharCode(97+Math.floor(Math.random() * 26));

        return a+b;
    }

    buttonClick (e : any) {
        this.$emit("buttonClick", e);
    }

}
