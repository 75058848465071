import {RouteConfig} from 'vue-router';

import SignIn from './pages/SignIn.vue';
import SignUp from './pages/SignUp.vue';
import SignUpOrganization from './pages/CreateOrganization.vue';
import PasswordRecovery from './pages/PasswordRecovery.vue';

export default [
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn,
        meta: {
            tenant: false
        }
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp,
        props: (route) => ({ email: route.query.email, token: route.query.token }),
        meta: {
            tenant: false
        }
    },
    {
        path: '/organization-sign-up',
        name: 'SignUpOrganization',
        component: SignUpOrganization,
        meta: {
            tenant: false
        }
    },
    {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
        meta: {
            tenant: false
        }
    }
] as RouteConfig[];
