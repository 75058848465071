





















































































































































































import {AuditModel, FileDownloadModel, TenantUserModel} from '@/libs/Api';
import ExportDialog from '@/libs/common/components-smart/ExportDialog.vue';
import { useObjectKey } from '@/libs/common/functions/use-object-key';
import { createAggregationQueryPayload } from '@/libs/core/+state/models/aggregation-query-payload';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { auditsStore } from '@/libs/audits/+state/store';
import Page from '@/Page.vue';
import moment from 'moment';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import AuditCreateDialog from '@/libs/audits/components/AuditCreateDialog.vue';
import WorkTaskCreateDialog from '@/libs/work-tasks/components/WorkTaskCreateDialog.vue';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import ApiService from "@/libs/core/api-service";

const b64toBlob = (b64Data: string, contentType='', sliceSize=512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

@Component({
    components: {
        AuditCreateDialog,
        WorkTaskCreateDialog
    }
})
export default class Audits extends Page {

    filterCategoryId: string | null = null;
    filterActive = true;
    mobileFiltersShow = false;
    pageIndex = 1;
    pageSize = 25;
    pagingChanged = false;
    workTaskAuditId: string | null = null;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    get totalRows(): number {
        const stats = auditsStore.useGetter(CrudGetter.DataStats) as CrudDataStats;
        return stats?.rowCount ?? 0;
    }
    
    get headers() {
        return [
            { text: "", value: "id", align: "left", sortable: false, width: 30 },
            { text: this.$i18n.t("audits.name"), value: "name", align: "left", sortable: false },
            { text: this.$i18n.t("audits.categoryName"), value: "category.name", align: "left", sortable: false },
            { text: this.$i18n.t("audits.owner"), value: "owner.user.displayName", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }

    @Prop() tenantId!: string;
    @Prop() partyId!: string;

    @Ref() dialog!: AuditCreateDialog;
    @Ref() workTaskCreateDialog!: WorkTaskCreateDialog;

    @Watch("filterCategoryId")
    @Watch("filterActive")
    filtersChanged () {
        this.pageIndex = 1;
        this.load();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged () {
        this.saveQuery();
        if (this.pagingChanged) {
            this.pagingChanged = false;
            return;
        }
        this.load();
    }

    get loading(): boolean {
        return auditsStore.useGetter(CrudGetter.Pending);
    }
    
    get audits (): AuditModel[] {
        return [...auditsStore.useGetter(CrudGetter.Data)];
    }

    mounted () {
        if (this.getQueryInt("pageSize") != null) {
            this.pageSize = this.getQueryInt("pageSize") ?? 25;
        }
        else {
            if (window.localStorage.ptPageSize && Number(window.localStorage.ptPageSize)) {
                this.pageSize = Number(window.localStorage.ptPageSize);
            }
            this.saveQuery();
        }
        this.load();
    }

    load () {
        auditsStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [];
        if (!this.tenantUser.accessAllCategories) {
            filters.push({ field: "category.categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
        if (this.filterCategoryId) {
            filters.push({ field: "categoryId", op: "eq", comparand: this.filterCategoryId });
        }
        filters.push({ field: "isActive", op: "eq", comparand: this.filterActive });
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<AuditModel>({ field: "name", index: 1, order: "asc" }, this.getFilters(), {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        });
    }

    createItemClick () {
        this.dialog.open();
    }

    exportClick () {
        const filters = this.getFilters();
        auditsStore.dispatch(CrudAction.Download, createCrudQueryPayload<AuditModel>({ field: "name", index: 1, order: "asc" }, filters));
    }

    saveQuery () {
        this.setQuery("pageSize", this.pageSize?.toString());
        window.localStorage.ptPageSize = this.pageSize?.toString();
    }

    planWorkTask (audit: AuditModel) {
        this.workTaskAuditId = audit.id!;
        this.workTaskCreateDialog.open();
    }
    
    printAudit(audit: AuditModel) {
        //this.printing = true;
        ApiService.api.printAuditById(audit!.id!).then((e: any) => {
            //this.printing = false;
            const res = e.data.returnValue as FileDownloadModel
            const blob = b64toBlob(res.base64!, res.contentType!);
            const tab = window.open();
            tab!.location.href = window.URL.createObjectURL(blob);
            /*const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', res.filename!);
            document.body.appendChild(link);
            link.click();
            link.parentNode!.removeChild(link);*/
        }).catch((e: any) => {
            //this.printing = false;
            throw e;
        });
    }

}
