


























































































import Page from '@/Page.vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { importsStore } from '@/libs/imports/+state/store'; 
import ImportUploadDialog from '../components/ImportUploadDialog.vue';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { ImportModel, ImportTypeEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

@Component({
    components: {
        ImportUploadDialog
    }
})
export default class Import extends Page {

    errorDialog = false;
    errorMessage: string | null = null;
	warningDialog = false;
	warningMessage: string | null = null;

    @Prop() tenantId!: string;
    @Ref() importUploadDialog!: ImportUploadDialog;

    uploadImportType: ImportTypeEnum = ImportTypeEnum.Audit;

    get loading(): boolean {
        return false;
    }

    get templates(): any[] {
        return [
            {
                name: "Import šablon auditů",
                type: "Šablony auditů",
                typeId: "Audit",
                templateFile: "https://idominoams.blob.core.windows.net/ams-public/Templates/Audits.xlsx"
            }
        ];
    }

    get previousImports(): any[] {
        return importsStore.useGetter(CrudGetter.Data);
    }

    get templatesHeaders() {
        return [
            { text: this.$i18n.t("settings.import.table.templateHeaders.name"), value: "name", align: "left", sortable: false },
            { text: this.$i18n.t("settings.import.table.templateHeaders.importType"), value: "type", align: "left", sortable: false },
            { text: "", value: "id", align: "right", sortable: false }
            
        ];
    }

    get previousImportsHeaders() {
        return [
            { text: this.$i18n.t("settings.import.table.previousImportsHeaders.name"), value: "name", align: "left", sortable: false },
            { text: this.$i18n.t("settings.import.table.previousImportsHeaders.start"), value: "start", align: "left", sortable: false },
            { text: this.$i18n.t("settings.import.table.previousImportsHeaders.importType"), value: "importType", align: "left", sortable: false },
            { text: this.$i18n.t("settings.import.table.previousImportsHeaders.state"), value: "state", align: "left", sortable: false },
            { text: "", value: "id", align: "right", sortable: false }
            
        ];
    }

    mounted () {
        this.load();
    }

    load () {
        this.loadItems();
    }

    loadItems () {
        importsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ImportModel>({ field: "createdAt", index: 1, order: "desc" }));
    }

    createItemClick () {
        console.log("createItemClick")
    }

    newImport (type: string) {
        this.uploadImportType = type as ImportTypeEnum;
        this.importUploadDialog.open();
    }

    showError(item: ImportModel) {
        this.errorDialog = true;
        this.errorMessage = item.errorMessage ?? "";
    }

	showWarning(item: ImportModel) {
        this.warningDialog = true;
        this.warningMessage = item.warningMessage ?? "";
    }

}
