import {RouteConfig} from 'vue-router';
import { PermissionTypeEnum } from '../Api';
import Unanswered from './pages/Unanswered.vue';

export default [
    {
        path: '/:tenantId/unanswered',
        name: 'Unanswered',
        component: Unanswered,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.PerformAudit,PermissionTypeEnum.Response ],
            checkLicence: true,
            title: "menu.unfinishedResponses"
        }
    }
] as RouteConfig[];
