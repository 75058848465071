
































import {Component, Vue, Prop, Watch, Emit, VModel, PropSync, Ref} from 'vue-property-decorator';

@Component({})
export default class Autocomplete extends Vue {

    @VModel() innerValue: any;
    
    @Prop() items!: Array<any>;
    @Prop() label!: string;
    @Prop({ default: false }) loading!: boolean;
    @Prop() itemText!: string;
    @Prop() itemValue!: string;
    @Prop() placeholder!: string;
    @Prop({ default: true }) enabled!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: true }) clearable!: boolean;
    @Prop() readonly!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop({ default: true }) chips!: boolean;
    @Prop({ default: false }) noMarginTop!: boolean;
    @Prop() attach!: string;

    @Ref() autocomplete!: any;

    blur () {
        this.autocomplete.blur();
    }

    get rules() : any[] {
      let rules: any[] = [];
      if (this.required) {
        if (this.multiple) {
            rules.push((v: any) => (v && v.any((x: any) => x != null)) || this.$i18n.t('common.thisFileIsRequired'));
        }
        else {
            rules.push((v: any) => !!v || this.$i18n.t('common.thisFileIsRequired'));
        }
      }
      return rules;
    }

}
