import {applyPatch, Operation, applyOperation} from 'fast-json-patch';
import {cloneDeep} from 'lodash';
import {CrudEntity} from '@/libs/core/+state/models/crud-service';

export function patchStoreData<T extends CrudEntity>(data: Dictionary<T[]>, updateItemId: string, updateOperations: Operation[]): Dictionary<T[]> {
    const updatedData: Dictionary<T[]> = {};
    updateOperations.forEach(x => {
        if (!x.path.startsWith("/"))
            x.path = "/".concat(x.path);
    });
    Object.keys(data).forEach(key => {
        const dataItemIndex = data[key].findIndex(item => item.id === updateItemId);
        if (dataItemIndex > -1) {
            updatedData[key] = data[key].map((dataItem, index) => {
                if (index === dataItemIndex) {
                    const dataItem = data[key][dataItemIndex];
                    let updatedDataItem = cloneDeep(dataItem);
                    updateOperations.forEach(operation => {
                        try {
                            updatedDataItem = applyOperation<T>(updatedDataItem, operation).newDocument;
                        }
                        catch (e) { true; }
                    });
                    return updatedDataItem;
                }
                return dataItem;
            });
        }
    });

    return updatedData;
}

export function updateStoreData<T extends CrudEntity, TDetail extends CrudEntity>(data: Dictionary<T[]>, updatedItem: TDetail) {
    const updatedData: Dictionary<T[]> = {};

    return updatedData;
}
