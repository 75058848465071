















































































import { Component, Prop, Watch, Vue, Ref } from 'vue-property-decorator';

import { CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
import { enableRipple } from '@syncfusion/ej2-base';
import { SignUpModel, ConfirmEmailModel } from '@/libs/Api';
import { authStore } from "@/libs/auth/+store/store";
import { AuthAction } from '../models/auth-state';

import { TenantModel } from '@/libs/Api';
import {tenantsStore} from '@/libs/tenants/+state/store';

enableRipple(true);
Vue.use(CheckBoxPlugin);

@Component({
  components: {
    
  }
})
export default class SignIn extends Vue {
    user: SignUpModel = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        language: "cs-CZ",
        phoneNumber: ""
    };
    organization: TenantModel = {
        name: null,
        domainName: null,
        identificationNumber: null,
        vat: null,
        addressStreet: null,
        addressZip: null,
        addressCity: null
    };
    emailReadonly = false;
    pending = false;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    valid = false;
    languages = [
        {
            name: "Čestina",
            id: "cs-CZ"
        }, 
        {
            name: "Angličtina",
            id: "en-US"
        }
    ];
    agreement = false;
    creating = false;
    succeeded: boolean | null = null;
    error: string | null = null;
    tokenVerification = false;
    tokenVerified: boolean | null = null;
    passwordAgain = "";
    passwordValid = true;
    passwordMatched = true;
    createOrganization = true;

    @Prop({ default: null }) email!: string | null;
    @Prop({ default: null }) token!: string | null;

    @Ref() form!: any;

    @Watch("user", { immediate: true, deep: true })
    userChanged(v: SignUpModel, p: SignUpModel) {
        if (v && p) {
            if (p.password != "" && p.password != null && v.password.length <= 5) {
                this.passwordValid = false;
            } else {
                this.passwordValid = true;
            }
        }
    }

    @Watch("passwordAgain")
    passwordAgainChanged(v: string, p: string) {
        if (p != "" && p != null && v.length > 0) {
            if (v == this.user.password) {
                this.passwordMatched = true;
            } else {
                this.passwordMatched = false;
            }
        } else {
            this.passwordMatched = true;
        }
    }

    mounted() {
        if (this.$router.currentRoute.query?.email) {
            this.user.email = this.$router.currentRoute.query!.email!.toString();
            this.user.invitationToken = this.$router.currentRoute.query!.invitationToken?.toString() ?? null;
            this.emailReadonly = true;
        }
        if (this.token != null) {
            this.tokenVerification = true;
            const activationRequest: ConfirmEmailModel = {
                email: this.email!,
                token: this.token!
            };
            authStore.dispatch(AuthAction.ActivateAccount, activationRequest);
            const self = this;
            const createdSubscriber = this.$store.subscribe((mutation, _state) => {
                if(mutation.type === authStore.getActionName(AuthAction.ActivateAccountResponse)) {
                    createdSubscriber();
                    self.tokenVerified = mutation.payload.succeeded;
                }
            });
        }
    }

    submit () {
        if (this.form.validate()) {
            authStore.dispatch(AuthAction.SignUp, this.user);
            this.creating = true;
            this.succeeded = null;
            this.error = null;
            const self = this;
            const createdSubscriber = this.$store.subscribe((mutation, _state) => {
                if(mutation.type === authStore.getActionName(AuthAction.SignUpResponse)) {
                    createdSubscriber();
                    self.succeeded = mutation.payload.succeeded;
                    if (!mutation.payload.succeeded) {
                        if (mutation.payload.errors.find((x: { code: string; }) => x.code == "DuplicateUserName")) {
                            self.error = self.$t('authentification.errors.duplicateUserName') as string;
                        }
                        else {
                            self.error = "Při registraci nastala chyba.";
                        }
                    }
                    self.creating = false;
                }
            });
        }
    }

    goToSignIn () {
        this.$router.push({ name: "SignIn" });
    }

}
