import Vue from 'vue'
import Vuex, {createLogger} from 'vuex'
import {createNotificationPlugin} from '@/libs/core/notifications/vuex-notification-plugin';

Vue.use(Vuex)

const notificationPlugin = createNotificationPlugin();

export default new Vuex.Store({
  state: {
    organizationSettings: {},
    organizationSettingsParsed: {},
  },
  strict: process.env.NODE_ENV === 'development',
  plugins: process.env.NODE_ENV === 'development' ? [createLogger(), notificationPlugin] : [notificationPlugin]
})
