










































































import Page from '@/Page.vue';
import {Component, Prop} from 'vue-property-decorator';
import {AiServiceQueueActionEnum, PlanItemModel, QueryOperator, TenantUserModel} from '@/libs/Api';
import WorkTaskMeasureItem from '../components/WorkTaskMeasureItem.vue';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {planItemsStore} from '@/libs/plan-items/+state/store';
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '@/libs/auth/models/auth-state';
import {aiServiceQueueItemsStore} from "@/libs/ai-service-queue-items/+state/store";
import {CrudAction} from "@/libs/core/+state/models/crud-action";
import {createCrudQueryPayload} from "@/libs/core/+state/models/crud-query-payload";

@Component({
    components: {
        WorkTaskMeasureItem
    }
})
export default class WorkTaskReportTab extends Page {

    /* Page props */
    @Prop() id!: string;
    @Prop() tenantId!: string;
    
    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    /* Component state variables */
    isLoading = false;

    /* Getters */

    get planItem(): PlanItemModel {
      return planItemsStore.useGetter(CrudGetter.Detail, this.id);
    }

    get isRunningAi(): boolean {
      return ([...aiServiceQueueItemsStore.useGetter(CrudGetter.Data, this.id)] ?? []).length > 0;
    }

    mounted() {
        const planItem = planItemsStore.useGetter(CrudGetter.Detail, this.id) as PlanItemModel;
        if (planItem && !planItem?.aiIsReportGenerated && planItem?.audit?.category?.aiUseReportGeneration == true) {
          aiServiceQueueItemsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
              { field: 'planItemId', op: QueryOperator.Eq, comparand: this.id },
              { field: 'action', op: QueryOperator.Eq, comparand: AiServiceQueueActionEnum.AiGeneratePlanItemReport },
              { field: 'isDone', op: QueryOperator.Ne, comparand: true }
          ], { pageIndex: 1, pageSize: 1 }, false, this.id));
        }
    }

}
