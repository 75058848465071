import {
    HttpResponse,
    QueryModel,
    RequestParams,
    ApiResultFailed,
    Operation,
    FullRequestParams,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import { TenantInvitationModel } from "../Api";

export class TenantInvitationsDataService implements CrudService<TenantInvitationModel, TenantInvitationModel> {
    getAll(query: CrudDataQuery<TenantInvitationModel>): Promise<CrudEnumerableResponse<TenantInvitationModel>> {
        return ApiService.api.getTenantInvitationBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<TenantInvitationModel>): Promise<CrudEnumerableResponse<TenantInvitationModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<TenantInvitationModel>> {
        return ApiService.api.getTenantInvitationById(id, params);
    }

    create(item: TenantInvitationModel, params?: RequestParams): Promise<CrudResponse<TenantInvitationModel>> {
        throw new Error('NotImplemented');
    }

    update(item: TenantInvitationModel, params?: RequestParams): Promise<TenantInvitationModel> {
        throw new Error('NotImplemented');
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<TenantInvitationModel> {
        throw new Error('NotImplemented');
    }

    delete(query: CrudDataQuery<TenantInvitationModel>): Promise<CrudEnumerableResponse<TenantInvitationModel>> {
        return ApiService.api.deleteTenantInvitations(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error('NotImplemented');
    }
    
    download(query: CrudDataQuery<TenantInvitationModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: TenantInvitationModel, params?: RequestParams): Promise<CrudResponse<TenantInvitationModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error('NotImplemented');
    }

}
