




























































import { RoleModel, TenantInvitationRoleModel, TenantUserModel } from '@/libs/Api';
import Page from '@/Page.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { tenantUsersStore } from '@/libs/tenants/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { rolesStore } from '@/libs/roles/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

@Component({})
export default class InviteAccountDialog extends Page {

    dialog = false;
    valid = true;
    saving = false;
    showInvitationRoleRules = false;
    email = "";
    invitationRoles: TenantInvitationRoleModel[] = [];
    roleId: string | null = null;
    
    get rules() {
        return [
            (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$i18n.t("validation.emailIsRequired")
        ]
    }
   
    get activeRoles(): string[] {
        return this.invitationRoles?.map(x => x.roleId!) ?? [];
    }

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Ref() form!: any;
    @Prop() tenantId!: string;

    open () {
        this.invitationRoles = [];
        this.email = "";
        this.dialog = true;
        this.showInvitationRoleRules = false;
    }

    close () {
        this.dialog = false;
    }

    save () {
        this.showInvitationRoleRules = false;
        if (this.form.validate() && this.invitationRoles.length > 0) {
            const InviteTenantUserModel: TenantUserModel = {
                id: "00000000-0000-0000-0000-000000000000",
                user: {
                    id: "00000000-0000-0000-0000-000000000000",
                    email: this.email
                },
                userRoles: this.invitationRoles
            };
            tenantUsersStore.dispatch(CrudAction.Create, { item: InviteTenantUserModel });
            this.saving = true;
            this.subscribe(tenantUsersStore, CrudReponse.Create).then(() => {
                this.close();
                this.saving = false;
                this.$emit("change");
            }).catch(() => {
                this.close();
                this.saving = false;
                this.$emit("change");
            });
        }
        else {
            this.showInvitationRoleRules = true;
        }
    }

    deleteClick () {
        console.log("deleteClick")
    }

    addRoleClick () {
        const roles = rolesStore.useGetter(CrudGetter.Data) as RoleModel[];
        const role = roles.firstOrDefault(x => x.id == this.roleId);
        if (role) {
            this.invitationRoles!.push({
                id: Guid.EmptyGuid(),
                roleId: role.id,
                role: role
            } as TenantInvitationRoleModel);
        }
        this.roleId = null;
    }

}
