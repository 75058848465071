import {RouteConfig} from 'vue-router';
import AdminTenants from './pages/AdminTenants.vue';
import AdminTenant from './pages/AdminTenant.vue';
import AdminUsers from './pages/AdminUsers.vue';

export default [
    {
        path: '/admin/tenants',
        name: 'AdminTenants',
        component: AdminTenants,
        meta: {
            systemUser: true
        }
    },
    {
        path: '/admin/tenants/:tenantId',
        name: 'AdminTenant',
        component: AdminTenant,
        props: (route) => ({adminTenantId: route.params.tenantId}),
        meta: {
            systemUser: true
        }
    },
    {
        path: '/admin/users',
        name: 'AdminUsers',
        component: AdminUsers,
        meta: {
            systemUser: true
        }
    },
] as RouteConfig[];