








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({

})
export default class ButtonToggle extends Vue {

    innerValue = null;

    @Prop() buttons!: Array<any>;
    @Prop() value!: [ string, number, boolean ];
    @Prop() name!: string;
    @Prop({default: true}) autoFill!: boolean;

    @Watch("innerValue")
    innerValueChanged(v: any, p: any) {
      if (v == p)
        return;
      this.$emit("input", v);
    }

    @Watch("value")
    valueChanged(v: any, p: any) {
      if (v == p)
        return;
      this.innerValue = v;
    }

    mounted () {
        this.changeInnerValue(this.value);
    }

    changeInnerValue(v: any) {
      if (this.innerValue != v)
        this.innerValue = v;
    }
}
