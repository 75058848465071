













































import { TenantUserModel } from '@/libs/Api';
import Page from '@/Page.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

@Component({})
export default class ExportDialog extends Page {

    dialog = false;
    valid = true;
    saving = false;

    @Prop() exporting!: boolean;

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Ref() form!: any;

    open () {
        this.dialog = true;
    }

    close () {
        this.dialog = false;
    }

    exportClick () {
        this.$emit("export-click");
    }

}
