import {RouteConfig} from 'vue-router';
import { PermissionTypeEnum } from '../Api';
import Issues from './pages/Issues.vue';

export default [
    {
        path: '/:tenantId/findings',
        name: 'Issues',
        component: Issues,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.Findings ],
            checkLicence: true,
            title: "menu.issuesLong"
        }
    }
] as RouteConfig[];