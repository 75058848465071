import Vue from 'vue';
import App from './App.vue';
//import './registerServiceWorker'
import router from './libs/core/router';
import store from './libs/core/+state/store';
import vuetify from './plugins/vuetify';
import './libs/Api';
import { Journaly, PublisherSubscriber } from 'journaly';
import './culture';
import './linq';
import moment from 'moment';
import { i18n } from "./i18n";
import { SecurityModel } from './libs/core/security-model';

moment.locale("cs");

declare global {
  const ApiService: Api<SecurityModel>;
  const SignalClientId: string;
  const SignalClientToken: string;
  const SignalHub: any;
  let Journaly: PublisherSubscriber<string>;
  let SaasApiEndpoint: string;
  let ApiAccessToken: string;
}

(window as any).Journaly = Journaly.newJournaly<string>({
  multiple: true,
  hasTopic: true,
}) as PublisherSubscriber<string>;

declare module 'vue/types/vue' {
  interface Vue {
    SignalHub: any
  }
}

import 'vuetify/src/styles/main.sass';

Vue.prototype.$settings = {};

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';
 
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false

// Components

import TextInput from '@/libs/common/components/TextInput.vue';
Vue.component('TextInput', TextInput);

import TextArea from '@/libs/common/components/TextArea.vue';
Vue.component('TextArea', TextArea);

import NSelect from '@/libs/common/components/NSelect.vue';
Vue.component('NSelect', NSelect);

import Slider from '@/libs/common/components/Slider.vue';
Vue.component('Slider', Slider);

import Loader from '@/libs/common/components/Loader.vue';
Vue.component('Loader', Loader);

import Button from '@/libs/common/components/Button.vue';
Vue.component('Button', Button);

import ButtonToggle from '@/libs/common/components/ButtonToggle.vue';
Vue.component('ButtonToggle', ButtonToggle);

import NDialog from '@/libs/common/components/NDialog.vue';
Vue.component('NDialog', NDialog);

import ConfirmDialog from '@/libs/common/components/ConfirmDialog.vue';
Vue.component('ConfirmDialog', ConfirmDialog);

import DeleteDialog from '@/libs/common/components/DeleteDialog.vue';
Vue.component('DeleteDialog', DeleteDialog);

import TextEditor from '@/libs/common/components/TextEditor.vue';
Vue.component('TextEditor', TextEditor);

import DateTimePicker from '@/libs/common/components/DateTimePicker.vue';
Vue.component('DateTimePicker', DateTimePicker);

import DatePicker from '@/libs/common/components/DatePicker.vue';
Vue.component('DatePicker', DatePicker);

import Toast from '@/libs/common/components/Toast.vue';
Vue.component('Toast', Toast);

import Autocomplete from '@/libs/common/components/Autocomplete.vue';
Vue.component('Autocomplete', Autocomplete);

import CrudTable from '@/libs/common/components/CrudTable.vue';
Vue.component('CrudTable', CrudTable);

import ColorPicker from '@/libs/common/components/ColorPicker.vue';
Vue.component('ColorPicker', ColorPicker);

import FlagSelect from '@/libs/common/components/FlagSelect.vue';
Vue.component('FlagSelect', FlagSelect);

import Pagination from '@/libs/common/components/Pagination.vue';
Vue.component('Pagination', Pagination);

import VueApexCharts from 'vue-apexcharts'
Vue.component('apexchart', VueApexCharts);

// Smart components

import UserSelect from '@/libs/common/components-smart/UserSelect.vue';
Vue.component('UserSelect', UserSelect);

import RepeatFrequencySelect from '@/libs/common/components-smart/RepeatFrequencySelect.vue';
Vue.component('RepeatFrequencySelect', RepeatFrequencySelect);

import MeasureTypeSelect from '@/libs/common/components-smart/MeasureTypeSelect.vue';
Vue.component('MeasureTypeSelect', MeasureTypeSelect);

import MeasureTypeToggle from '@/libs/common/components-smart/MeasureTypeToggle.vue';
Vue.component('MeasureTypeToggle', MeasureTypeToggle);

import ImplementationStateSelect from '@/libs/common/components-smart/ImplementationStateSelect.vue';
Vue.component('ImplementationStateSelect', ImplementationStateSelect);

import ImplementationStateToggle from '@/libs/common/components-smart/ImplementationStateToggle.vue';
Vue.component('ImplementationStateToggle', ImplementationStateToggle);

import ExportDialog from '@/libs/common/components-smart/ExportDialog.vue';
Vue.component('ExportDialog', ExportDialog);

import AccountTypeSelect from '@/libs/common/components-smart/AccountTypeSelect.vue';
Vue.component('AccountTypeSelect', AccountTypeSelect);

import CategorySelect from '@/libs/common/components-smart/CategorySelect.vue';
Vue.component('CategorySelect', CategorySelect);

import WorkplaceSelect from '@/libs/common/components-smart/WorkplaceSelect.vue';
Vue.component('WorkplaceSelect', WorkplaceSelect);

import RoleSelect from '@/libs/common/components-smart/RoleSelect.vue';
Vue.component('RoleSelect', RoleSelect);

import TenantUserSelect from '@/libs/common/components-smart/TenantUserSelect.vue';
Vue.component('TenantUserSelect', TenantUserSelect);

import GroupSelect from '@/libs/common/components-smart/GroupSelect.vue';
Vue.component('GroupSelect', GroupSelect);

import AuditSelect from '@/libs/common/components-smart/AuditSelect.vue';
Vue.component('AuditSelect', AuditSelect);

import NotRatedOptionReasonSelect from '@/libs/common/components-smart/NotRatedOptionReasonSelect.vue';
Vue.component('NotRatedOptionReasonSelect', NotRatedOptionReasonSelect);

import PlanItemStateSelect from '@/libs/common/components-smart/PlanItemStateSelect.vue';
Vue.component('PlanItemStateSelect', PlanItemStateSelect);

import PlanItemStateChip from '@/libs/common/components-smart/PlanItemStateChip.vue';
Vue.component('PlanItemStateChip', PlanItemStateChip);

import ImplementationStateChip from '@/libs/common/components-smart/ImplementationStateChip.vue';
Vue.component('ImplementationStateChip', ImplementationStateChip);

import PositionSelect from '@/libs/common/components-smart/PositionSelect.vue';
Vue.component('PositionSelect', PositionSelect);

import DepartmentSelect from '@/libs/common/components-smart/DepartmentSelect.vue';
Vue.component('DepartmentSelect', DepartmentSelect);

import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);

// Layout styles
// import '@/assets/vars.scss';
import "@/assets/common.scss";
import "@/assets/layout.scss";
import "@/assets/icons.scss";
import "@/assets/spacing.scss";
import "@/assets/grid-system.scss";

// Component styles
import "@/assets/components/avatar.scss";
import "@/assets/components/sidebar.scss";
import "@/assets/components/searchbox.scss";
import "@/assets/components/filemanager.scss";
import "@/assets/components/work-item-comments.scss";
import "@/assets/components/tenant-group-panel.scss";
import "@/assets/components/scheduler-tasqio-theme.css";
import "@/assets/components/queue-tasqio-theme.scss";
import "@/assets/components/work-item-additional-info.scss";
import "@/assets/components/structure-manager.scss";
import "@/assets/components/work-item-participants.scss";
import "@/assets/components/project-grid.scss";
import "@/assets/components/comments.scss";
import "@/assets/components/panel.scss";
import "@/assets/components/flag-select.scss";
import "@/assets/components/dropzone.scss";

// Page styles
import '@/assets/pages/resources.scss';
import '@/assets/pages/projects.scss';
import '@/assets/pages/project.scss';
import '@/assets/pages/project-notes.scss';
import '@/assets/pages/organization-settings.scss';
import '@/assets/pages/user-settings.scss';
import '@/assets/pages/sign-in.scss';
import '@/assets/pages/dashboard.scss';
import '@/assets/pages/districts.scss';
import '@/assets/pages/work-tasks.scss';
import '@/assets/pages/work-task.scss';
import '@/assets/pages/reports.scss';
import '@/assets/pages/audit.scss';
import '@/assets/pages/settings.scss';

// Overrides
import "@/assets/overrides/grid.scss";
import "@/assets/overrides/kanban.scss";
import "@/assets/overrides/toolbar.scss";
import "@/assets/overrides/form.scss";
import "@/assets/overrides/button.scss";
import "@/assets/overrides/button-toggle.scss";
import "@/assets/overrides/dialog.scss";
import "@/assets/overrides/card.scss";
import "@/assets/overrides/gantt.scss";
import "@/assets/overrides/multiselect.scss";
import "@/assets/overrides/tooltip.scss";
import "@/assets/overrides/text-editor.scss";
import "@/assets/overrides/file-manager.scss";
import "@/assets/overrides/treegrid.scss";
import "@/assets/overrides/pager.scss";
import "@/assets/overrides/toast.scss";
import "@/assets/overrides/colorpicker.scss";
import "@/assets/overrides/scheduler.scss";
import "@/assets/overrides/grid.scss";
import "@/assets/overrides/loader.scss";
import "@/assets/overrides/switch.scss";
import "@/assets/overrides/text-field.scss";
import "@/assets/overrides/datepicker.scss";
import "@/assets/overrides/v-input.scss";
import "@/assets/overrides/v-data-table.scss";
import "@/assets/overrides/v-tab.scss";
import "@/assets/overrides/v-btn-toggle.scss";
import "@/assets/overrides/v-select.scss";
import "@/assets/overrides/v-dialog.scss";
import "@/assets/overrides/v-textarea.scss";
import "@/assets/overrides/v-autocomplete.scss";
import "@/assets/overrides/v-calendar.scss";
import "@/assets/overrides/v-btn.scss";
import "@/assets/overrides/v-sheet.scss";
import "@/assets/overrides/v-chip.scss";
import "@/assets/overrides/v-table.scss";
import "@/assets/overrides/v-snackbar.scss";
import "@/assets/overrides/v-label.scss";
import "@/assets/overrides/apexcharts.scss";
import "@/assets/overrides/swiper.scss";
import "@/assets/overrides/pagination.scss";

import "@/assets/borders.scss";

// Formatters
import "@/libs/common/filters/format-date";
import "@/libs/common/filters/format-address";
import "@/libs/common/filters/format-moment";
import "@/libs/common/filters/format-string";
import "@/libs/common/filters/format-textarea-to-html";

// Api
import './libs/core/api-service'
import { Api } from './libs/Api';

// VH unit fix
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
