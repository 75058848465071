import {RouteConfig} from 'vue-router';
import { PermissionTypeEnum } from '../Api';

import Reports from './pages/Reports.vue';

import ReportByQuestion from './pages/ReportByQuestion.vue';
import ReportAuditByWorkplace from './pages/ReportAuditByWorkplace.vue';
import ReportAuditByUser from './pages/ReportAuditByUser.vue';
import ReportAuditByYear from './pages/ReportAuditByYear.vue';
import ReportAuditByCategory from './pages/ReportAuditByCategory.vue';
import ReportExport from './pages/ReportExport.vue';

export default [
    {
        path: '/:tenantId/reports',
        name: 'Reports',
        component: Reports,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ],
            checkLicence: true,
            title: "menu.reports"
        },
        children: [
            {
                path: '/:tenantId/reports/by-question',
                name: 'ReportByQuestion',
                component: ReportByQuestion,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ],
                    checkLicence: true,
                    title: "menu.reports"
                }
            },
            {
                path: '/:tenantId/reports/audits-by-workplace',
                name: 'ReportAuditByWorkplace',
                component: ReportAuditByWorkplace,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ],
                    checkLicence: true,
                    title: "menu.reports"
                }
            },
            {
                path: '/:tenantId/reports/audits-by-user',
                name: 'ReportAuditByUser',
                component: ReportAuditByUser,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ],
                    checkLicence: true,
                    title: "menu.reports"
                }
            },
            {
                path: '/:tenantId/reports/audits-by-year',
                name: 'ReportAuditByYear',
                component: ReportAuditByYear,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ],
                    checkLicence: true,
                    title: "menu.reports"
                }
            },
            {
                path: '/:tenantId/reports/audits-by-category',
                name: 'ReportAuditByCategory',
                component: ReportAuditByCategory,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ],
                    checkLicence: true,
                    title: "menu.reports"
                }
            },
            {
                path: '/:tenantId/reports/export',
                name: 'ReportExport',
                component: ReportExport,
                props: (route) => ({tenantId: route.params.tenantId}),
                meta: {
                    permissions: [ PermissionTypeEnum.Response, PermissionTypeEnum.Audits ],
                    checkLicence: true,
                    title: "menu.reports"
                }
            }
        ]
    }
] as RouteConfig[];
