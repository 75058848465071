
















import { ApplicationUserModel, TenantModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { tenantsStore } from '@/libs/tenants/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import Page from '@/Page.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class MissingMfa extends Page {
    
    mounted() {
        const tenantId = authStore.useGetter(AuthGetter.GetTenant);
        const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        if (!tenant.isRequiredTwoFactor || (tenant.isRequiredTwoFactor && user.twoFactorEnabled)) {
            this.$router.push({ name: "Dashboard", params: { tenantId: tenantId } });
            return;
        }
    }

}
