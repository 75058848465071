import { Vue } from 'vue-property-decorator';
import moment from 'moment';
import { GlobalVariables } from '@/libs/core/global';
import { i18n } from "@/i18n";

Vue.filter('formatDate', (value : string) => {
    if (value) {
        return moment(String(value)).locale(GlobalVariables.lang).format('DD.MM.YYYY HH:mm')
    }
})

Vue.filter('formatSimpleDate', (value : string) => {
    if (value) {
        return moment(String(value)).locale(GlobalVariables.lang).format('DD.MM.YYYY')
    }
})

Vue.filter('formatDateName', (value : string) => {
    if (value) {
        return moment(String(value)).locale(GlobalVariables.lang).format('dddd')
    }
})
