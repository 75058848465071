import {
    HttpResponse,
    MeasureModel,
    QueryModel,
    RequestParams,
    MeasureModelIEnumerableApiResultSuccess,
    MeasureModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class VerificationsDataService implements CrudService<MeasureModel, MeasureModel> {
    getAll(query: CrudDataQuery<MeasureModel>): Promise<CrudEnumerableResponse<MeasureModel>> {
        throw new Error('NotImplemented');
    }

    getBasicAll(query: CrudDataQuery<MeasureModel>): Promise<CrudEnumerableResponse<MeasureModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<MeasureModel>> {
        throw new Error('NotImplemented');
    }

    create(item: MeasureModel, params?: RequestParams): Promise<CrudResponse<MeasureModel>> {
        throw new Error('NotImplemented');
    }

    update(item: MeasureModel, params?: RequestParams): Promise<MeasureModel> {
        throw new Error('NotImplemented');
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<MeasureModel> {
        throw new Error('NotImplemented');
    }

    delete(query: CrudDataQuery<MeasureModel>): Promise<CrudEnumerableResponse<MeasureModel>> {
        throw new Error('NotImplemented');
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error('NotImplemented');
    }
    
    download(query: CrudDataQuery<MeasureModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        return ApiService.api.exportVerificationBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    clone(item: MeasureModel, params?: RequestParams): Promise<CrudResponse<MeasureModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error('NotImplemented');
    }

}
