import {RouteConfig} from 'vue-router';

import UserSettings from './pages/UserSettings.vue';

export default [
    {
        path: '/:tenantId/user-settings',
        name: 'UserSettings',
        component: UserSettings,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            title: "account.header"
        }
    }
] as RouteConfig[];

