


























































import { TenantUserModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import ApiService from "@/libs/core/api-service"

@Component({})
export default class iCalDialog extends Page {

    dialog = false;
    valid = true;
    snackbar = false;

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    open () {
        this.dialog = true;
    }

    close () {
        this.dialog = false;
    }

    copyToClipboard () {
        navigator.clipboard.writeText(this.getCalendarUrl());
        this.snackbar = true;
    }

    getCalendarUrl () {
        const tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
        let url = ApiService.baseUrl;
        if (!ApiService.baseUrl.endsWith("/")) {
            url += "/";
        }
        return url + "api/ical/" + tenantUser.iCalIdentifier + "?t=" + (new Date().getTime().toString());
    }

}
