
































































































































































import { Component, Vue, Watch, Prop, PropSync, Ref } from 'vue-property-decorator';

@Component
export default class CrudTable extends Vue {

    dialog = false;
    dialogDelete = false;
    editedIndex = -1;
    editedItem: any = null;
    edit = false;
    tableHeaders: Array<any> = [];
    valid = false;
    basicRules = [
        (v: any) => !!v || this.$i18n.t('common.thisFileIsRequired')
    ];
    rules = {
        required: [ (value: any) => !!value || this.$i18n.t('common.thisFileIsRequired') ]
    };
    
    @Prop() name!: string;
    @Prop() headers!: Array<any>;
    @Prop({ type: Function, default: (e: any) => { return e; } }) beforeInsert!: any;
    @Prop() defaultItem: any;
    @Prop({ default: true }) editable!: boolean;
    @Prop({ default: true }) addable!: boolean;
    @Prop({ default: true }) deleteable!: boolean;
    @PropSync('items', { default: () => { return []; } }) tableItems!: Array<any>;
    @Ref() form!: any;

    @Watch("headers")
    headersChanged (v: Array<any>) {
        this.tableHeaders = [
            ...v,
            { text: '', value: 'actions', sortable: false, align: 'right', type: "actions" }
        ];
    }

    @Watch("dialog")
    dialogChanged (v: boolean) {
        if (!v) {
            this.editedItem = null;
        }
    }

    mounted () {
        this.tableHeaders = [
            ...this.headers,
            { text: '', value: 'actions', sortable: false, align: 'right', type: "actions" }
        ];
    }

    addItemClick () {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.edit = false;
        this.dialog = true;
    }

    editItem (item : any) {
        this.editedIndex = this.tableItems.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
        this.edit = true;
    }

    deleteItem (item : any) {
        this.editedIndex = this.tableItems.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
    }

    deleteItemConfirm () {
        this.tableItems.splice(this.editedIndex, 1);
        this.closeDelete();
        this.$emit("delete", this.editedItem);
    }

    close () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
    }

    closeDelete () {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
    }

    save () {
        if (this.form.validate()) {
            if (this.editedIndex > -1) {
                Object.assign(this.tableItems[this.editedIndex], this.editedItem);
                this.$emit("save", this.editedItem);
            }
            else {
                this.editedItem = this.beforeInsert(this.editedItem);
                this.tableItems.push(this.editedItem);
                this.$emit("save", this.editedItem);
            }
            this.close()
        }
    }

}
