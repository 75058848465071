import {RouteConfig} from 'vue-router';

import Home from './pages/Home.vue';
import BadLicence from './pages/BadLicence.vue';
import MissingMfa from './pages/MissingMfa.vue';

export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            tenant: false
        }
    },
    {
        path: '/:tenantId/bad-licence',
        name: 'BadLicence',
        props: (route) => ({tenantId: route.params.tenantId}),
        component: BadLicence,
        meta: {
            tenant: false
        }
    },
    {
        path: '/:tenantId/missing-mfa',
        name: 'MissingMfa',
        props: (route) => ({tenantId: route.params.tenantId}),
        component: MissingMfa,
        meta: {
            tenant: false
        }
    }
] as RouteConfig[];