import {registerCrudStore} from '@/libs/core/+state/register-crud-store';
import {
    ResponseModel
} from '@/libs/Api';
import {ResponsesDataService} from '@/libs/responses/responses-data-service';
import {BusinessModule} from '@/libs/core/models/business-module';
import store from "@/libs/core/+state/store";
import {isFunction} from "lodash";
import {Module} from "vuex";

export const responsesStore = registerCrudStore<ResponseModel, ResponseModel>(BusinessModule.Response, new ResponsesDataService());

export interface IResponsesShowDetailsStore {
    dispatch (code: ResponsesShowDetailTypeEnum, value: boolean): void;
    useGetter (code: ResponsesShowDetailTypeEnum): boolean;
}

export enum ResponsesShowDetailTypeEnum {
    ShowScore = 'showScore',
    ShowDisagreementSummary = 'showDisagreementSummary',
    ShowIdentifiedCauses = 'showIdentifiedCauses',
    ShowIdentifiedRisks = 'showIdentifiedRisks',
    ShowRecommendations = 'showRecommendations',
    ShowConclusion = 'showConclusion'
}

export class ResponsesShowDetails {
    showScore = false;
    showDisagreementSummary = false;
    showIdentifiedCauses = false;
    showIdentifiedRisks = false;
    showRecommendations = false;
    showConclusion = false;
}

function getBoolLocalStorage(code: string, defaultValue = false) {
    if (window.localStorage.getItem(code) == null)
        return defaultValue;
    return window.localStorage.getItem(code) == "true";
}

const registerResponsesShowDetailsStore = (): IResponsesShowDetailsStore => {
    const storeObject: Module<ResponsesShowDetails, unknown> = {
        namespaced: true,
        state: {
            showScore: getBoolLocalStorage('showScore'),
            showDisagreementSummary: getBoolLocalStorage('showDisagreementSummary'),
            showIdentifiedCauses: getBoolLocalStorage('showIdentifiedCauses'),
            showIdentifiedRisks: getBoolLocalStorage('showIdentifiedRisks'),
            showRecommendations: getBoolLocalStorage('showRecommendations'),
            showConclusion: getBoolLocalStorage('showConclusion', true)
        },
        actions: {
            ['Set']: ({commit, state}, payload: { code: string, value: boolean }) => {
                window.localStorage.setItem(payload.code, payload.value.toString());
                commit('Set', payload);
            }
        },
        mutations: {
            ['Set']: (state, payload: { code: string, value: boolean }) => {
                state[payload.code as keyof ResponsesShowDetails] = payload.value;
            }
        },
        getters: {
            [ResponsesShowDetailTypeEnum.ShowScore]: (state): boolean => {
                return state.showScore;
            },
            [ResponsesShowDetailTypeEnum.ShowDisagreementSummary]: (state): boolean => {
                return state.showDisagreementSummary;
            },
            [ResponsesShowDetailTypeEnum.ShowIdentifiedCauses]: (state): boolean => {
                return state.showIdentifiedCauses;
            },
            [ResponsesShowDetailTypeEnum.ShowIdentifiedRisks]: (state): boolean => {
                return state.showIdentifiedRisks;
            },
            [ResponsesShowDetailTypeEnum.ShowRecommendations]: (state): boolean => {
                return state.showRecommendations;
            },
            [ResponsesShowDetailTypeEnum.ShowConclusion]: (state): boolean => {
                return state.showConclusion;
            }
        }
    };
    store.registerModule("ResponsesShowDetails", storeObject);
    return {
        dispatch: (code: ResponsesShowDetailTypeEnum, value: boolean) => {
            store.dispatch(`ResponsesShowDetails/Set`, {code, value});
        },
        useGetter: (code: ResponsesShowDetailTypeEnum) => {
            const val = store.getters[`ResponsesShowDetails/${code}`];
            return isFunction(val) ? val() : val;
        }
    };
}

export const responsesShowDetailsStore = registerResponsesShowDetailsStore();