export enum ProcessStepTypeEnum {
    None = 0,
    UseDefined= 1,
    ProductAndProcess = 2,
    Process = 3
}

export interface Vda63ProcessElementModel {
    Element: string;
    UseSubElements: boolean;
    Questions: Vda63QuestionModel[];
    SubElements: Vda63ProcessSubElementModel[];
    Validation: Vda64QuestionValidation;
}

export interface Vda63ProcessSubElementModel {
    SubElement: string;
    Questions: Vda63QuestionModel[];
}

export interface Vda63QuestionModel {
    QuestionCode: string;
    ProcessStepType: ProcessStepTypeEnum;
}

export interface Vda64QuestionValidation {
    ResponseCount?: number;
    ProcessResponseCount?: number;
    ProductResponseCount?: number;
    ProcessStepResponseCount?: number;
}

export class VDA63 {

    public static ProcessElements: string[] = [ 'P2', 'P3', 'P4', 'P5', 'P6', 'P7' ];

    public static  ProcessElementsSteps: Vda63ProcessElementModel[] = [
        {
            Element: 'P2',
            UseSubElements: false,
            Questions: [
                {
                    QuestionCode: '2.1',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '2.2',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '2.3',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '2.4',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '2.5',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '2.6',
                    ProcessStepType: ProcessStepTypeEnum.None
                }
            ],
            SubElements: [],
            Validation: {
                ResponseCount: 5
            }
        },
        {
            Element: 'P3',
            UseSubElements: false,
            Questions: [
                {
                    QuestionCode: '3.1',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '3.2',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '3.3',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '3.4',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '3.5',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '3.6',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                }
            ],
            SubElements: [],
            Validation: {
                ProductResponseCount: 4,
                ProcessResponseCount: 4
            }
        },
        {
            Element: 'P4',
            UseSubElements: false,
            Questions: [
                {
                    QuestionCode: '4.1',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '4.2',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '4.3',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '4.4',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '4.5',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '4.6',
                    ProcessStepType: ProcessStepTypeEnum.Process
                },
                {
                    QuestionCode: '4.7',
                    ProcessStepType: ProcessStepTypeEnum.Process
                },
                {
                    QuestionCode: '4.8',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                },
                {
                    QuestionCode: '4.9',
                    ProcessStepType: ProcessStepTypeEnum.ProductAndProcess
                }
            ],
            SubElements: [],
            Validation: {
                ProductResponseCount: 4,
                ProcessResponseCount: 6
            }
        },
        {
            Element: 'P5',
            UseSubElements: false,
            Questions: [
                {
                    QuestionCode: '5.1',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '5.2',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '5.3',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '5.4',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '5.5',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '5.6',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '5.7',
                    ProcessStepType: ProcessStepTypeEnum.None
                }
            ],
            SubElements: [],
            Validation: {
                ResponseCount: 5
            }
        },
        {
            Element: 'P6',
            UseSubElements: true,
            Questions: [],
            SubElements: [
                {
                    SubElement: '6.1',
                    Questions: [
                        {
                            QuestionCode: '6.1.1',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.1.2',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.1.3',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.1.4',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.1.5',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        }
                    ]
                },
                {
                    SubElement: '6.2',
                    Questions: [
                        {
                            QuestionCode: '6.2.1',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.2.2',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.2.3',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.2.4',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.2.5',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        }
                    ]
                },
                {
                    SubElement: '6.3',
                    Questions: [
                        {
                            QuestionCode: '6.3.1',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.3.2',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.3.3',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        }
                    ]
                },
                {
                    SubElement: '6.4',
                    Questions: [
                        {
                            QuestionCode: '6.4.1',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.4.2',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.4.3',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.4.4',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.4.5',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        }
                    ]
                },
                {
                    SubElement: '6.5',
                    Questions: [
                        {
                            QuestionCode: '6.5.1',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.5.2',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.5.3',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.5.4',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        }
                    ]
                },
                {
                    SubElement: '6.6',
                    Questions: [
                        {
                            QuestionCode: '6.6.1',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.6.2',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.6.3',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        },
                        {
                            QuestionCode: '6.6.4',
                            ProcessStepType: ProcessStepTypeEnum.UseDefined
                        }
                    ]
                }
            ],
            Validation: {
                ProcessStepResponseCount: 17
            }
        },
        {
            Element: 'P7',
            UseSubElements: false,
            Questions: [
                {
                    QuestionCode: '7.1',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '7.2',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '7.3',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '7.4',
                    ProcessStepType: ProcessStepTypeEnum.None
                },
                {
                    QuestionCode: '7.5',
                    ProcessStepType: ProcessStepTypeEnum.None
                }
            ],
            SubElements: [],
            Validation: {
                ResponseCount: 4
            }
        }
    ];

}
