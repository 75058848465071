
















































































































import { ApplicationUserModel, CategoryModel, CategoryUserModel, RoleModel, TenantModel, TenantUserModel, UserRoleModel } from '@/libs/Api';
import Page from '@/Page.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { tenantsStore, tenantUsersStore } from '@/libs/tenants/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { rolesStore } from '@/libs/roles/+state/store';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { categoriesStore } from '@/libs/categories/+state/store';
import { categoryUsersStore } from '@/libs/category-users/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthAction } from '@/libs/auth/models/auth-state';
import { cloneDeep } from 'lodash';

@Component({})
export default class AccountDialog extends Page {

    dialog = false;
    valid = true;
    id: string | null = null;
    roleId: string | null = null;
    categoryId: string | null = null;
    item: TenantUserModel | null = null;
    activeUserCountReached = false;
    originalIsActive = false;
    allowedCategories: CategoryUserModel[] = [];
    
    get isNewItem() : boolean {
        return this.item?.id == Guid.EmptyGuid();
    }

    get saving(): boolean {
        return tenantUsersStore.useGetter(CrudGetter.Saving, this.id);
    }

    get activeRoles(): string[] {
        return this.item?.userRoles?.map(x => x.roleId!) ?? [];
    }

    get activeCategories(): string[] {
        return this.allowedCategories?.map(x => x.categoryId!) ?? [];
    }

    @Prop() categories!: CategoryModel[];

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Ref() form!: any;

    open (id: string | null = null) {
        this.dialog = true;
        this.id = id;
        this.roleId = null;
        tenantUsersStore.dispatch(CrudAction.Get, { id: id, key: id });
        this.subscribe(tenantUsersStore, CrudReponse.Get, id).then((e: TenantUserModel) => {
            this.item = e;
            this.originalIsActive = e.isActive!;
            this.allowedCategories = cloneDeep(e.categories!);
            if (!e.isActive) {
                const tenant = tenantsStore.useGetter(CrudGetter.Detail) as TenantModel;
                tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>(undefined, [{ field: "isActive", op: "eq", comparand: true }], undefined, false, "ActiveTenantUsers"));
                this.subscribe(tenantUsersStore, CrudReponse.GetAll, "ActiveTenantUsers").then((e: TenantUserModel[]) => {
                    if (e.length >= (tenant.licenceUserCount ?? 0)) {
                        this.activeUserCountReached = true;
                    }
                    else {
                        this.activeUserCountReached = false;
                    }
                });
            }
            else {
                this.activeUserCountReached = false;
            }
        }).catch((e) => {
            this.close();
            throw e;
        })
    }

    close () {
        this.dialog = false;
    }

    save () {
        if (!this.item?.accessAllCategories) {
            this.item!.categories = this.allowedCategories;
        }
        else {
            this.item!.categories = [];
        }
        tenantUsersStore.dispatch(CrudAction.Update, { item: this.item, key: this.id });
        this.subscribe(tenantUsersStore, CrudReponse.Update, this.id).then(() => {
            tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>());
            const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
            tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>(undefined, [
                { field: "userId", op: "eq", comparand: user.id! }
            ], undefined, false, "me"));
            this.subscribe(tenantUsersStore, CrudReponse.GetAll, "me").then((e: TenantUserModel[]) => {
                authStore.dispatch(AuthAction.SetTenantUser, e[0]);
                this.close();
                this.$emit("save");
            }).catch((e: any) => {
                console.error(e);
            });
        });
    }

    deleteClick () {
        console.log("deleteClick")
    }

    addRoleClick () {
        const roles = rolesStore.useGetter(CrudGetter.Data) as RoleModel[];
        const role = roles.firstOrDefault(x => x.id == this.roleId);
        if (role) {
            this.item!.userRoles!.push({
                id: Guid.EmptyGuid(),
                tenantUserId: this.item!.id,
                roleId: role.id,
                role: role
            } as UserRoleModel);
        }
        this.roleId = null;
    }

    addAllowedCategoryClick () {
        const category = this.categories.firstOrDefault(x => x.id == this.categoryId);
        if (category) {
            this.allowedCategories.push({
                id: Guid.EmptyGuid(),
                categoryId: this.categoryId,
                category: category
            } as CategoryUserModel);
        }
        this.categoryId = null;
    }

    deleteCategoryClick (index: number, category: CategoryModel) {
        this.allowedCategories.splice(index, 1); 
    }

}
