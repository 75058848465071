




































import { ImplementationStateEnum, MeasureTypeEnum, RepeatFrequencyEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import Autocomplete from '../components/Autocomplete.vue';

@Component({ })
export default class ImplementationStateSelect extends Vue {

    get items() {
        if (this.onlyVerification) {
            return [
                { id: ImplementationStateEnum.WaitingForVerification, name: this.$i18n.t("issueDialog.waitingForVerification") },
                { id: ImplementationStateEnum.Verified, name: this.$i18n.t("issueDialog.verified") },
            ];
        }
        else {
            return [
                { id: ImplementationStateEnum.New, name: this.$i18n.t("issueDialog.new") },
                { id: ImplementationStateEnum.InProgress, name: this.$i18n.t("issueDialog.inProgress") },
                { id: ImplementationStateEnum.WaitingForVerification, name: this.$i18n.t("issueDialog.waitingForVerification") },
                { id: ImplementationStateEnum.Verified, name: this.$i18n.t("issueDialog.verified") },
            ];
        }
    }


    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop({ default: true }) clearable!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: true }) chips!: boolean;
    @Prop() placeholder!: string;
    @Prop({ default: false }) white!: boolean;
    @Prop({ default: false }) onlyVerification!: boolean;
    @Prop({ default: 0 }) maxWidth!: number;

    @Ref() autocomplete!: Autocomplete;

    // @Watch("innerValue")
    // innerValueChanged (v: string) {
    //     if (v != null && !this.items.find((x: any) => { return x.id == v; })) {
    //         this.$emit("input", null);
    //     }
    // }

}
