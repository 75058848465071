





































import { FileModel, ImportModel, ImportTypeEnum } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { Guid } from '@/libs/common/functions/guid';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { importsStore } from '@/libs/imports/+state/store';
import Page from '@/Page.vue';
import axios from 'axios';
import { getAccessToken } from 'axios-jwt';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

@Component({})
export default class ImportUploadDialog extends Page {

    dialog = false;
    valid = true;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    dialogStatus = "uploadItem";
    importType: string | null = null;
    files = [];
    maxUploadFileSize = 50;
    snackbarFileTooBig = false;
    uploading = false;
    canUpload = false;
	fileName = "";

    @Ref() inputFile!: any;

    @Prop() uploadImportType!: ImportTypeEnum;

    get saving(): boolean {
        return false;
    }

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Ref() form!: any;

    open (id: string | null = null) {
		this.fileName = "";
		this.canUpload = false;
        this.dialogStatus = "uploadItem";
        this.dialog = true;
    }

    close () {
        this.dialog = false;
    }

    save () {
        if (this.form.validate()) {
            console.log("save");
        }
    }

    deleteClick () {
        console.log("deleteClick")
    }

    activateFileUpload () {
        this.inputFile.click();
    }

    filesAdded (e: any) {
		if (this.inputFile.files.length > 0) {
			this.canUpload = true;
			this.fileName = this.inputFile.files[0].name;
		}
		else {
			this.canUpload = false;
		}
    }

    uploadFile () {
        this.uploading = true;
        const fileSize = this.inputFile.files[0].size as number;
        if (fileSize <= this.maxUploadFileSize * 1000000) {
            var formData = new FormData();
            formData.append("file", this.inputFile.files[0]);
            const tenantId = authStore.useGetter(AuthGetter.GetTenant);
            const token = getAccessToken();
            axios.post((window as any).ApiService.baseUrl + "/api/files/upload/import", formData, {
                headers: {
                    "TenantId": tenantId,
                    "Authorization": `Bearer ${token}`
                }
            }).then((r) => {
                const fileModel = r.data.returnValue as FileModel;
                const importModel = {
                    id: Guid.EmptyGuid(),
                    fileId: fileModel.id,
                    importType: this.uploadImportType,
                } as ImportModel;
                importsStore.dispatch(CrudAction.Create, { item: importModel });
                this.subscribe(importsStore, CrudReponse.Create).then(() => {
                    this.uploading = false;
                    this.dialog = false;
                }).catch(() => {
                    this.uploading = false;
                })
            }).catch(() => {
                this.uploading = false;
            });
        }
        else {
            this.snackbarFileTooBig = true;
        }
        this.inputFile.value = "";
    }

}
