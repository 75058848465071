import {RouteConfig} from 'vue-router';
import { PermissionTypeEnum } from '../Api';
import Responses from './pages/Responses.vue';
import Unanswered from './pages/Unanswered.vue';

export default [
    {
        path: '/:tenantId/responses',
        name: 'Responses',
        component: Responses,
        props: (route) => ({tenantId: route.params.tenantId}),
        meta: {
            permissions: [ PermissionTypeEnum.PerformAudit, PermissionTypeEnum.Response, PermissionTypeEnum.MistakeCorrection ],
            checkLicence: true,
            title: "menu.responses"
        }
    }
] as RouteConfig[];
