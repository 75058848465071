import {
    HttpResponse,
    AdminUserModel,
    QueryModel,
    RequestParams,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class AdminUsersDataService implements CrudService<AdminUserModel, AdminUserModel> {
    getAll(query: CrudDataQuery<AdminUserModel>): Promise<CrudEnumerableResponse<AdminUserModel>> {
        return ApiService.api.getAdminUserBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<AdminUserModel>): Promise<CrudEnumerableResponse<AdminUserModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<AdminUserModel>> {
        return ApiService.api.getAdminUserById(id, params);
    }

    create(item: AdminUserModel, params?: RequestParams): Promise<CrudResponse<AdminUserModel>> {
        throw new Error('NotImplemented');
    }

    update(item: AdminUserModel, params?: RequestParams): Promise<AdminUserModel> {
        throw new Error('NotImplemented');
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<AdminUserModel> {
        throw new Error('NotImplemented');
    }

    delete(query: CrudDataQuery<AdminUserModel>): Promise<CrudEnumerableResponse<AdminUserModel>> {
        throw new Error('NotImplemented');
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error('NotImplemented');
    }
    
    download(query: CrudDataQuery<AdminUserModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: AdminUserModel, params?: RequestParams): Promise<CrudResponse<AdminUserModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error('NotImplemented');
    }

}
