

















import { RepeatFrequencyEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class RepeatFrequencySelect extends Vue {

    get items() {
        return [
            { id: RepeatFrequencyEnum.Week, name: this.$t("common.week") },
            { id: RepeatFrequencyEnum.Month, name: this.$t("common.month") }
        ];
    }

    @Prop({ default: "" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) white!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) clearable!: boolean;
    @Prop() districtId!: string;
    @Prop({ default: 0 }) maxWidth!: number;

    @Watch("innerValue")
    innerValueChanged (v: string) {
        if (v != null && !this.items.find((x: any) => { return x.id == v; })) {
            this.$emit("input", null);
        }
    }

}
