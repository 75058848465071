import {
    HttpResponse,
    QueryModel,
    RequestParams,
    ApiResultFailed,
    Operation,
    FullRequestParams,
    ApplicationUserModel,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class ApplicationUserDataService implements CrudService<ApplicationUserModel, ApplicationUserModel> {
    getAll(query: CrudDataQuery<ApplicationUserModel>): Promise<CrudEnumerableResponse<ApplicationUserModel>> {
        throw new Error('NotImplemented');
    }

    getBasicAll(query: CrudDataQuery<ApplicationUserModel>): Promise<CrudEnumerableResponse<ApplicationUserModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<ApplicationUserModel>> {
        return ApiService.api.getApplicationUser(params);
    }

    create(item: ApplicationUserModel, params?: RequestParams): Promise<CrudResponse<ApplicationUserModel>> {
        throw new Error('NotImplemented');
    }

    update(item: ApplicationUserModel, params?: RequestParams): Promise<ApplicationUserModel> {
        return ApiService.api.updateApplicationUser(item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<ApplicationUserModel> {
        throw new Error('NotImplemented');
    }

    delete(query: CrudDataQuery<ApplicationUserModel>): Promise<CrudEnumerableResponse<ApplicationUserModel>> {
        throw new Error('NotImplemented');
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error('NotImplemented');
    }
    
    download(query: CrudDataQuery<ApplicationUserModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: ApplicationUserModel, params?: RequestParams): Promise<CrudResponse<ApplicationUserModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error('NotImplemented');
    }

}
