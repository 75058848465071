



























































import moment from 'moment';
import { Component, Vue, Watch, Prop, VModel } from 'vue-property-decorator';

@Component
export default class DatePicker extends Vue {

    menu = false;
    text = "";

    date: string | null = null;
    modal = false;
    menu2 = false;
    
    @VModel() innerValue!: string;
    @Prop() value!: [ string ];
    @Prop() min!: [ string ];
    @Prop() max!: [ string ];
    @Prop() label!: [ string ];
    @Prop() placeholder!: [ string ];
    @Prop({ default: false }) readonly!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: 0 }) maxWidth!: number;

    get rules() : any[] {
      let rules: any[] = [];
      if (this.required) {
        rules.push((v: any) => !!v || this.$i18n.t('common.thisFileIsRequired'));
        if (this.min && moment(this.min).isValid()) {
          rules.push((v: any) => (moment(v, "DD.MM.YYYY").isValid() && moment(v, "DD.MM.YYYY").isSameOrAfter(moment(this.min))) || 'Minimální datum ' + moment(this.min).format("DD.MM.YYYY"));
        }
        if (this.max && moment(this.max).isValid()) {
          rules.push((v: any) => (moment(v, "DD.MM.YYYY").isValid() && moment(v, "DD.MM.YYYY").isSameOrBefore(moment(this.max))) || 'Maximální datum ' + moment(this.max).format("DD.MM.YYYY"));
        }
      }
      return rules;
    }

    mounted () {
      if (this.value) {
        this.date = moment(this.value).format("YYYY-MM-DD");
        this.text = moment(this.value).format("DD.MM.YYYY");
      }
    }

    @Watch("innerValue")
    innerValueChanged (v: string, p: string) {
		console.log(v, p);
      if (v == p)
        return;
      this.date = moment(v).format("YYYY-MM-DD");
      this.text = moment(v).format("DD.MM.YYYY");
    }

    @Watch("date")
    dateChanged(v: string, p: string) {
      if (v == p)
        return;
      if (v == null) {
        this.$emit("input", null);
        this.text = "";
      }
      else {
        this.$emit("input", moment(v).format("YYYY-MM-DDT00:00:00"));
        this.text = moment(v).format("DD.MM.YYYY");
      }
    }

    @Watch("text")
    textChanged (v: string) {
      const dateRegEx = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/;
      if (moment(v).isValid() && dateRegEx.test(v)) {
        this.date = moment(v, "DD.MM.YYYY").format("YYYY-MM-DD");
      }
    }

}
