import {
    HttpResponse,
    WorkplaceModel,
    QueryModel,
    RequestParams,
    WorkplaceModelIEnumerableApiResultSuccess,
    WorkplaceModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class WorkplacesDataService implements CrudService<WorkplaceModel, WorkplaceModel> {
    getAll(query: CrudDataQuery<WorkplaceModel>): Promise<CrudEnumerableResponse<WorkplaceModel>> {
        return ApiService.api.getWorkplaceBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<WorkplaceModel>): Promise<CrudEnumerableResponse<WorkplaceModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<WorkplaceModel>> {
        return ApiService.api.getWorkplaceById(id, params);
    }

    create(item: WorkplaceModel, params?: RequestParams): Promise<CrudResponse<WorkplaceModel>> {
        return ApiService.api.insertWorkplace(item, params);
    }

    update(item: WorkplaceModel, params?: RequestParams): Promise<WorkplaceModel> {
        return ApiService.api.updateWorkplace(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<WorkplaceModel> {
        return ApiService.api.patchWorkplace(id, patches, params).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<WorkplaceModel>): Promise<CrudEnumerableResponse<WorkplaceModel>> {
        return ApiService.api.deleteWorkplaces(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        return ApiService.api.aggregationWorkplace(query, params).then((req: any) => req.data.returnValue);
    }
    
    download(query: CrudDataQuery<WorkplaceModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: WorkplaceModel, params?: RequestParams): Promise<CrudResponse<WorkplaceModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        return ApiService.api.getWorkplaceGroupped(query, params);
    }

}
