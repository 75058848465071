

















































import { ApplicationUserModel, WorkplaceModel, PlanItemModel, QuestionGroupModel, ResponseModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { Guid } from '@/libs/common/functions/guid';
import { workplacesStore } from '@/libs/workplaces/+state/store';

@Component({})
export default class WorkplaceDialog extends Page {

    id!: string;
    dialog = false;
    valid = true;
    saving = false;
    loading = false;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    workplace : WorkplaceModel | null = null;
    deleteDialog = false;
    deleting = false;

    @Ref() form!: any;

    get isNewItem(): boolean {
        return this.workplace?.id == Guid.EmptyGuid();
    }
    
    open (id: string) {
        this.dialog = true;
        this.id = id;
        this.load();
    }

    close () {
        this.dialog = false;
    }

    private load (): Promise<any> {
        if (this.id != Guid.EmptyGuid()) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.loadWorkplace().then((e) => {
                    this.loading = false;
                    resolve(e);
                }).catch((e: any) => {
                    reject(e);
                });
            });
        }
        else {
            this.workplace = {
                id: Guid.EmptyGuid(),
                code: "",
                name: ""
            } as WorkplaceModel;
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    }

    private loadWorkplace() {
        return new Promise((resolve, reject) => {
            workplacesStore.dispatch(CrudAction.Get, { id: this.id });
            this.subscribe(workplacesStore, CrudReponse.Get).then((e) => {
                this.workplace = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private save () {
        if (this.form.validate()) {
            const workplace = {...this.workplace};
            this.saving = true;
            if (this.id == Guid.EmptyGuid()) {
                workplacesStore.dispatch(CrudAction.Create, { item: workplace });
                this.subscribe(workplacesStore, CrudReponse.Create).then(() => {
                    this.load().then(() => {
                        this.saving = false;
                        this.close();
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            }
            else {
                workplacesStore.dispatch(CrudAction.Update, { item: workplace });
                this.subscribe(workplacesStore, CrudReponse.Update).then(() => {
                    this.load().then(() => {
                        this.saving = false;
                        this.close();
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            }
        }
    }

    deleteClick () {
        this.deleteDialog = true;
    }

    deleteWorkplace () {
        this.deleting = true;
        workplacesStore.dispatch(CrudAction.Delete, { id: this.id });
        this.subscribe(workplacesStore, CrudReponse.Delete).then((e: any) => {
            this.deleting = false;
            this.deleteDialog = false;
            this.close();
        }).catch((e: any) => {
            this.deleting = false;
        });
    }

}
