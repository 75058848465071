




























import { AuditModel, TenantModel, AdminUserModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { adminUsersStore } from '@/libs/admin-users/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';

@Component({})
export default class AdminTenants extends Page {

    filterCategoryId: string | null = null;
    filterActive = true;
    itemsPerPage = 10;
    tenantDialog = false;
    activeTenant: TenantModel | null = null;
    
    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    get headers() {
        return [
            { text: "Jméno", value: "name", align: "left", sortable: false },
            { text: "Telefon", value: "name", align: "left", sortable: false },
            { text: "Email", value: "name", align: "left", sortable: false },
            { text: "Organizace uživatele", value: "name", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }

    get loading(): boolean {
        return adminUsersStore.useGetter(CrudGetter.Pending);
    }
    
    get users (): TenantModel[] {
        return [...adminUsersStore.useGetter(CrudGetter.Data)];
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.load();
    }

    load () {
        adminUsersStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [];
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<AdminUserModel>({ field: "registrationDate", index: 1, order: "desc" }, this.getFilters());
    }

}
