




































import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

@Component({})
export default class DeleteDialog extends Page {

    dialog = false;
    valid = true;
    deleting = false;

    @Prop() title!: string;
    @Prop({ default: false }) saving!: boolean;

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    open () {
        this.dialog = true;
    }

    close () {
        this.dialog = false;
    }

    deleteClick () {
        this.$emit("delete-click");
    }

}
