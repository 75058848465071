






















import { TenantUserModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { isArray } from 'lodash';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import Autocomplete from '../components/Autocomplete.vue';
import { tenantUsersStore } from '@/libs/tenants/+state/store';

@Component({})
export default class UserSelect extends Vue {

    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) createParty!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop() placeholder!: string;
    @Prop() except!: Array<string>;


    @Ref() autocomplete!: Autocomplete;

    get items(): TenantUserModel[] {
        const except = this.except;
        return [...(tenantUsersStore.useGetter(CrudGetter.Data) as TenantUserModel[])]
                .where(x => {
                    return (except && !except.contains(x.userId!)) || !except || this.innerValue == x.userId;
                })
                .toArray()
                .sort((a: TenantUserModel, b: TenantUserModel) => {
                    if(a.user!.displayName! < b.user!.displayName!) { return -1; }
                    if(a.user!.displayName! > b.user!.displayName!) { return 1; }
                    return 0;
                });
    }

}
