







































import {AuditExplanationTypeEnum, AuditModel, CategoryModel, ResponseTypeEnum, TenantUserModel} from '@/libs/Api';
import Page from '@/Page.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import {auditsStore} from '@/libs/audits/+state/store';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {tenantUsersStore} from '@/libs/tenants/+state/store';

@Component({})
export default class AuditCreateDialog extends Page {

    dialog = false;
    valid = true;
    saving = false;
    cloneAudit = false;
    item: AuditModel | null = null;
    
    get isNewItem() : boolean {
        return this.item?.id == Guid.EmptyGuid();
    }

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Ref() form!: any;
    @Prop() tenantId!: string;

    load() {
        this.item = {
            id: Guid.EmptyGuid(),
            name: "",
            categoryId: undefined,
            explanationType: AuditExplanationTypeEnum.None
        } as AuditModel;
    }

    open () {
        this.dialog = true;
        this.cloneAudit = false;
        this.load();
    }

    clone () {
        var audit = auditsStore.useGetter(CrudGetter.Detail) as AuditModel;
        this.dialog = true;
        this.cloneAudit = true;
        this.item = {
            id: audit.id,
            name: audit.name,
            categoryId: audit.categoryId,
            category: audit.category
        } as AuditModel;
    }
    
    onSelectCategory(category: CategoryModel) {
        this.item!.category = category;
    }

    close () {
        this.dialog = false;
    }

    save () {
        if (this.form.validate()) {
            this.saving = true;
            const tenantId = this.tenantId;
            const meTenantUsers = tenantUsersStore.useGetter(CrudGetter.Data, "me") as TenantUserModel[];
            const tenantUser = meTenantUsers.first();
            this.item!.ownerId = tenantUser.id;
            this.item!.isActive = true;
            if (this.item!.category!.responseType == ResponseTypeEnum.VDA63) {
                this.item!.processSteps = [
                    {
                        isHidden: true,
                        stepNumber: 'Product',
                        position: 1,
                        key: Guid.NewGuid(),
                        name: 'Produkt'
                    },
                    {
                        isHidden: true,
                        stepNumber: 'Process',
                        position: 2,
                        key: Guid.NewGuid(),
                        name: 'Proces'
                    }
                ]
            }
            if (!this.cloneAudit) {
                auditsStore.dispatch(CrudAction.Create, { item: this.item });
                this.subscribe(auditsStore, CrudReponse.Create).then((e: AuditModel) => {
                    this.saving = false;
                    this.close();
                    this.$router.push({ name: "AuditGeneral", params: { tenantId: tenantId, auditId: e.id! } })
                }).catch((e: any) => {
                    this.saving = false;
                });
            }
            else {
                auditsStore.dispatch(CrudAction.Clone, { item: this.item });
                this.subscribe(auditsStore, CrudReponse.Clone).then((e: AuditModel) => {
                    this.saving = false;
                    this.close();
                    this.$router.push({ name: "AuditGeneral", params: { tenantId: tenantId, auditId: e.id! } })
                }).catch((e: any) => {
                    this.saving = false;
                });
            }
        }
    }

}
