













































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { authStore } from '@/libs/auth/+store/store';
import { AuthAction } from '../models/auth-state';
import { ResetPasswordModel } from '@/libs/Api';
import { ConfirmResetPasswordModel } from '@/libs/Api';
import { CrudResponseAction } from '@/libs/core/+state/models/crud-action';

@Component({
  components: {
    
  }
})
export default class PasswordRecovery extends Vue {
    email = "";
    password = null;
    passwordAgain = null;
    passwordValid = true;
    pending = false;
    deactivateMfa = false;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    valid = false;
    emailPassed = false;
    token: string | null = null;
    showToastSuccess = false;
    showToastError = false;

    @Ref() form!: any;

    @Watch("password")
    passwordChanged(v: string, p: string) {
        if (v != p && this.passwordAgain != null && this.passwordAgain != "") {
            if (v == this.passwordAgain) 
                this.passwordValid = true;
            else
                this.passwordValid = false;
        } 
    }

    @Watch("passwordAgain")
    passwordAgainChanged(v: string, p: string) {
        if (v != p) {
            if (v == this.password) 
                this.passwordValid = true;
            else
                this.passwordValid = false;
        } 
    }

    mounted() {
        if (typeof this.$route.query.token != "undefined" && this.$route.query.token != null && typeof this.$route.query.email != "undefined" && this.$route.query.email != null) {
            this.token = String(this.$route.query.token);
            this.email = String(this.$route.query.email);
            this.emailPassed = true;
        }
    }

    submit () {
        if (this.form.validate()) {
            this.pending = true;
            if (!this.emailPassed) {
                const resetPasswordModel : ResetPasswordModel = {
                    email: this.email!
                };
                authStore.dispatch(AuthAction.ResetPassword, resetPasswordModel);
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === authStore.getActionName(AuthAction.ResetPasswordResponse)) {
                        if (mutation.payload.succeeded) {
                            this.showToastSuccess = true;
                            this.email = "";
                        } else if (mutation.payload.errors.length > 0) {
                            mutation.payload.errors.forEach((e: any) => {
                                if (e.code == "UserNotFound") {
                                    this.showToastError = true;
                                }
                            });
                        }
                        self.pending = false;
                        subscribtion();
                    }
                });
            } else {
                const confirmResetPasswordModel : ConfirmResetPasswordModel = {
                    email: this.email!,
                    token: this.token!,
                    password: this.password!,
                    deactivateMfa: this.deactivateMfa
                };
                authStore.dispatch(AuthAction.ResetSetNewPassword, confirmResetPasswordModel);
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    console.log(mutation.payload);
                    if (mutation.type === authStore.getActionName(AuthAction.ResetSetNewPasswordResponse)) {
                        self.$router.push({ name: "SignIn", query: { recoverySuccess: mutation.payload.succeeded } });
                        subscribtion();
                    }
                    self.pending = false;
                });
            }
        }
    }
}
