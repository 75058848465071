



































































































































































































import { ApplicationUserModel, AuditModel, CategoryModel, EscalationTypeEnum, PlanItemModel, QuestionGroupModel, ResponseDescriptionTypeEnum, ResponseModel, ResponseTypeEnum, TenantUserBaseModel, TenantUserModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { questionGroupsStore } from '@/libs/question-groups/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { Guid } from '@/libs/common/functions/guid';
import CorrectionManagerList from '../components/CorrectionManagerList.vue';
import CategoryUserList from '../components/CategoryUserList.vue';
import { categoriesStore } from '@/libs/categories/+state/store';
import { auditsStore } from '@/libs/audits/+state/store';
import { tenantUsersStore } from '@/libs/tenants/+state/store';

@Component({
    components: {
        CorrectionManagerList,
        CategoryUserList
    }
})
export default class CategoryDialog extends Page {

    id!: string;
    dialog = false;
    valid = true;
    saving = false;
    loading = false;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    category : CategoryModel | null = null;
    deleteDialog = false;
    deleting = false;
    canDelete = false;
    useDefinedExplanation = false;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    tenantUsersOfCategory: TenantUserBaseModel[] = [];
    
    @Ref() form!: any;
    
    get isNewItem(): boolean {
        return this.category?.id == Guid.EmptyGuid();
    }
    
    open (id: string) {
        this.dialog = true;
        this.id = id;
        this.load();
    }

    close () {
        this.dialog = false;
    }

    private load (): Promise<any> {
        if (this.id != Guid.EmptyGuid()) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.loadCategory().then((category: CategoryModel) => {
                    this.loadTenantUsersWithAllCategories().then((tenantUsers: TenantUserModel[]) => {
                        this.tenantUsersOfCategory = (category.categoryUsers?.select((x: any) => x.tenantUser).toArray() ?? []).concat(tenantUsers);
                        this.tenantUsersOfCategory = this.tenantUsersOfCategory.groupBy(x => x.id!).select(x => x.first()).orderBy(x => x.user?.displayName ?? "").toArray();
                        this.loading = false;
                        resolve(category);
                    }).catch((e: any) => {
                        reject(e);
                    });
                }).catch((e: any) => {
                    reject(e);
                });
            });
        }
        else {
            this.tenantUsersOfCategory = [];
            this.category = {
                id: Guid.EmptyGuid(),
                name: "",
                correctionManagers: [],
                responseValues: [],
                useExplanationByCategory: false,
                useCommentOnResponse: true,
                categoryUsers: [],
                useNotRatedOption: false,
                enforceReasonForNotRatedOption: true
            } as CategoryModel;
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    }

    private loadCategory(): Promise<CategoryModel> {
        return new Promise((resolve, reject) => {
            categoriesStore.dispatch(CrudAction.Get, { id: this.id });
            this.subscribe(categoriesStore, CrudReponse.Get).then((e) => {
                this.category = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private loadTenantUsersWithAllCategories(): Promise<TenantUserModel[]> {
        return new Promise((resolve, reject) => {
            tenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>([], [
                { field: "accessAllCategories", op: "eq", comparand: true }
            ], undefined, false, "TenantUsersWithAllCategories"));
            this.subscribe(tenantUsersStore, CrudReponse.GetAll, "TenantUsersWithAllCategories").then((e) => {
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

    private save () {
        if (this.form.validate()) {
            const category = {...this.category};
            category.correctionManagers = category.correctionManagers!.where(x => x.tenantUserId != null && x.tenantUserId != Guid.EmptyGuid()).toArray();
            this.saving = true;
            if (this.id == Guid.EmptyGuid()) {
                categoriesStore.dispatch(CrudAction.Create, { item: category });
                this.subscribe(categoriesStore, CrudReponse.Create).then(() => {
                    this.load().then(() => {
                        this.saving = false;
                        this.close();
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            }
            else {
                categoriesStore.dispatch(CrudAction.Update, { item: category });
                this.subscribe(categoriesStore, CrudReponse.Update).then(() => {
                    this.load().then(() => {
                        this.saving = false;
                        this.close();
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            }
        }
    }

    deleteClick () {
        auditsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<AuditModel>(undefined, [
            { field: "categoryId", op: "eq", comparand: this.category!.id! }
        ], undefined, false, "CategoryDelete"));
        this.subscribe(auditsStore, CrudReponse.GetAll, "CategoryDelete").then((e: AuditModel[]) => {
            this.canDelete = e.length == 0;
            this.deleteDialog = true;
        });
    }

    deleteCategory () {
        this.deleting = true;
        categoriesStore.dispatch(CrudAction.Delete, { id: this.id });
        this.subscribe(categoriesStore, CrudReponse.Delete).then((e: any) => {
            this.deleting = false;
            this.deleteDialog = false;
            this.close();
        }).catch((e: any) => {
            this.deleting = false;
        });
    }

    responseTypeChanged () {
        if (this.category!.responseType == ResponseTypeEnum.OkNok) {
            this.category!.responseValues = [
                { value: 1, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation },
                { value: 3, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.OkNokWarning) {
            this.category!.responseValues = [
                { value: 1, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation },
                { value: 2, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 3, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreZeroToFive) {
            this.category!.responseValues = [
                { value: 0, color: "9e0b0b", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 1, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 2, color: "e0890d", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 3, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 4, color: "a2eba2", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 5, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreOneToFive) {
            this.category!.responseValues = [
                { value: 1, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 2, color: "e0890d", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 3, color: "deb40b", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 4, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 5, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreZeroToFour) {
            this.category!.responseValues = [
                { value: 0, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 1, color: "e0890d", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 2, color: "deb40b", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 3, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 4, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreZeroToOne) {
            this.category!.responseValues = [
                { value: 0, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 1, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreZeroToTwo) {
            this.category!.responseValues = [
                { value: 0, color: "1fbf1f", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 1, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 2, color: "e61717", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreOneToThree) {
            this.category!.responseValues = [
                { value: 1, color: "1fbf1f", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 2, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 3, color: "e61717", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreZeroToThree) {
            this.category!.responseValues = [
                { value: 0, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 1, color: "e0890d", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 2, color: "deb40b", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 3, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.ScoreOneToFour) {
            this.category!.responseValues = [
                { value: 1, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 2, color: "e0890d", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 3, color: "deb40b", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 4, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
        else if (this.category!.responseType == ResponseTypeEnum.VDA63) {
            this.category!.responseValues = [
                { value: 0, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 4, color: "e0890d", escalationType: EscalationTypeEnum.RequiredEscalation },
                { value: 6, color: "deb40b", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 8, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
                { value: 10, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation }
            ];
        }
    }

    useNotRatedOptionChanged() {
        if (this.category!.useNotRatedOption) {
            this.category!.enforceReasonForNotRatedOption = true;
        }
    }

}
