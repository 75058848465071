import {registerCrudStore} from '@/libs/core/+state/register-crud-store';
import {BusinessModule} from '@/libs/core/models/business-module';
import { TenantModel } from '@/libs/Api';
import { TenantUserModel } from '@/libs/Api';
import { TenantInvitationModel } from '@/libs/Api';
import { TenantsDataService } from '../tenant-data-service';
import { TenantUsersDataService } from '../tenant-user-data-service';
import { TenantInvitationsDataService } from '../tenant-invitation-data-service';

export const tenantsStore = registerCrudStore<TenantModel, TenantModel>(BusinessModule.Tenant, new TenantsDataService());
export const tenantUsersStore = registerCrudStore<TenantUserModel, TenantUserModel>(BusinessModule.TenantUser, new TenantUsersDataService());
export const tenantInvitationsStore = registerCrudStore<TenantInvitationModel, TenantInvitationModel>(BusinessModule.TenantInvitation, new TenantInvitationsDataService());