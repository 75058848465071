

























































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import BasicFormValidator from "@/libs/common/validators/basic-form-validator";
import { FormValidator } from 'node_modules/@syncfusion/ej2-vue-inputs';

import { CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
import { enableRipple } from '@syncfusion/ej2-base';
import { TenantModel } from '@/libs/Api';
import {tenantsStore} from '@/libs/tenants/+state/store';
import { CrudAction, CrudReponse, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { authStore } from '../+store/store';
import { AuthAction, AuthGetter } from '../models/auth-state';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import Page from '@/Page.vue';

enableRipple(true);
Vue.use(CheckBoxPlugin);

@Component({
  components: {
    
  }
})
export default class SignUpOrganization extends Page {
    organization: TenantModel = {
        name: null,
        domainName: null,
        identificationNumber: null,
        vat: null,
        addressStreet: null,
        addressZip: null,
        addressCity: null
    };
    pending = false;
    formObj: FormValidator | null = null;
    rules = {
        name: {required: true},
        identificationNumber: {required: true},
        vat: {required: true},
        addressStreet: {required: true},
        addressZip: {required: true},
        addressCity: {required: true},
        //country: {required: true},
    };
    agreement = false;
    saving = false;

    get isSingleTenant(): boolean {
        return authStore.useGetter(AuthGetter.GetIsSingleTenant) ?? false;
    }

    @Ref() formSignUp!: any;

    submit () {
        if (this.formSignUp.validate()) {
            tenantsStore.dispatch(CrudAction.Create, { item: this.organization });
            this.saving = true;
            this.subscribe(tenantsStore, CrudReponse.Create).then((item: TenantModel) => {
                tenantsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantModel>({
                    field: 'name',
                    order: 'asc'
                }));
                this.subscribe(tenantsStore, CrudReponse.GetAll).then(() => {
                    this.saving = false;
                    authStore.dispatch(AuthAction.SetTenant, item.id!);
                    this.$router.push({name: "Audits", params: { tenantId: item.id! }});
                    window.location.reload();
                });
            })
        }
    }

}
