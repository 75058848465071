























import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class Reports extends Page {
    
    get currentRouteName(): string | null {
        if (this.$route.name)
            return this.$route.name;
        else 
            return null;
    }

    get mode(): string {
        return this.$route.params.mode;
    }

    @Watch("mode")
    modeChanged(v: string) {
        if (this.currentRouteName) {
            let routeName = this.currentRouteName;
            if (v == "unfinished" && (routeName == "ReportByQuestion" || routeName == "ReportAuditByWorkplace")) {
                routeName = "ReportAuditByUser";
            }
            this.$router.push({
                name: routeName,
                params: { mode: v }
            })
        }  
    }
}
