























import { MeasureTypeEnum, PlanItemStateEnum, RepeatFrequencyEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class PlanItemStateSelect extends Vue {

    get items() {
        if (this.onlyVerification) {
            return [
                { id: PlanItemStateEnum.WaitingForApproval, name: this.$i18n.t("planItems.state.WaitingForApproval") },
                { id: PlanItemStateEnum.Approved, name: this.$i18n.t("planItems.state.Approved") },
                { id: PlanItemStateEnum.Declined, name: this.$i18n.t("planItems.state.Declined") },
                { id: PlanItemStateEnum.Closed, name: this.$i18n.t("planItems.state.Closed") },
            ];
        }
        else if (this.onlyMeasures) {
            return [
                { id: PlanItemStateEnum.NeedsToCreateMeasures, name: this.$i18n.t("planItems.state.NeedsToCreateMeasures") },
                { id: PlanItemStateEnum.NeedsToPerformMeasures, name: this.$i18n.t("planItems.state.NeedsToPerformMeasures") },
                { id: PlanItemStateEnum.NeedsToVerifyMeasures, name: this.$i18n.t("planItems.state.NeedsToVerifyMeasures") },
                { id: PlanItemStateEnum.AllMeasuresVerified, name: this.$i18n.t("planItems.state.AllMeasuresVerified") },
                { id: PlanItemStateEnum.PerformedWithoutFindings, name: this.$i18n.t("planItems.state.PerformedWithoutFindings") },
                { id: PlanItemStateEnum.WaitingForApproval, name: this.$i18n.t("planItems.state.WaitingForApproval") },
                { id: PlanItemStateEnum.Approved, name: this.$i18n.t("planItems.state.Approved") },
                { id: PlanItemStateEnum.Declined, name: this.$i18n.t("planItems.state.Declined") },
            ];
        }
        else {
            return [
                { id: PlanItemStateEnum.ClosedWithoutFindings, name: this.$i18n.t("planItems.state.ClosedWithoutFindings") },
                { id: PlanItemStateEnum.NeedsToCreateMeasures, name: this.$i18n.t("planItems.state.NeedsToCreateMeasures") },
                { id: PlanItemStateEnum.NeedsToPerformMeasures, name: this.$i18n.t("planItems.state.NeedsToPerformMeasures") },
                { id: PlanItemStateEnum.NeedsToVerifyMeasures, name: this.$i18n.t("planItems.state.NeedsToVerifyMeasures") },
                { id: PlanItemStateEnum.AllMeasuresVerified, name: this.$i18n.t("planItems.state.AllMeasuresVerified") },
                { id: PlanItemStateEnum.PerformedWithoutFindings, name: this.$i18n.t("planItems.state.PerformedWithoutFindings") },
                { id: PlanItemStateEnum.WaitingForApproval, name: this.$i18n.t("planItems.state.WaitingForApproval") },
                { id: PlanItemStateEnum.Approved, name: this.$i18n.t("planItems.state.Approved") },
                { id: PlanItemStateEnum.Declined, name: this.$i18n.t("planItems.state.Declined") },
                { id: PlanItemStateEnum.Closed, name: this.$i18n.t("planItems.state.Closed") },
            ];
        }
    }

    @Prop({ default: "" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) white!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) clearable!: boolean;
    @Prop({ default: false }) onlyMeasures!: boolean;
    @Prop({ default: false }) onlyVerification!: boolean;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: false }) noMarginTop!: boolean;
    @Prop() placeholder!: string;
    @Prop() attach!: string;
    @Prop({ default: true }) chips!: boolean;
    @Prop({ default: 0 }) maxWidth!: number;

    @Watch("innerValue")
    innerValueChanged (v: string) {
        this.$emit("input", v);
    }

}
