import {RouteConfig} from 'vue-router';

import Cloud from './pages/Cloud.vue';

export default [
    {
        path: '/:tenantId/cloud',
        name: 'Cloud',
        component: Cloud,
        props: (route) => ({ tenantId: route.params.tenantId })
    }
] as RouteConfig[];
