
































import Page from '@/Page.vue';
import { Component, Prop, PropSync, Ref, VModel, Watch } from 'vue-property-decorator';
import {findingsStore} from '@/libs/findings/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { FindingModel, MeasureModel } from '@/libs/Api';
import WorkTaskDialogFiles from '../components/WorkTaskDialogFiles.vue';

@Component({
    components: {
        WorkTaskDialogFiles
    }
})
export default class WorkTaskFindingItemMeasure extends Page {

    /* Component props */
    @VModel() item!: MeasureModel;

    @Prop({ default: false }) useMeasureType!: boolean;

    removeMeasure() {
        this.$emit("remove");
    }

}
