












































































import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createAggregationQueryPayload } from '@/libs/core/+state/models/aggregation-query-payload';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Constants } from '@/constants';
import { ApplicationUserModel, PlanItemModel, TenantUserModel } from '@/libs/Api';
import { tenantUsersStore } from '@/libs/tenants/+state/store';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import {authStore} from "@/libs/auth/+store/store";
import {AuthGetter} from "@/libs/auth/models/auth-state";

moment.locale("cs");

@Component({
    components: {}
})
export default class DashboardWaitingPlanItems extends Page {

    today = new Date();
    tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    itemsPerPage = 10;

    get headers() {
        return [
            { text: this.$i18n.t("planItems.time"), value: "time", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.audit.name"), value: "audit.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.category.name"), value: "audit.category.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.user.displayName"), value: "user.displayName", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }

    get loading(): boolean {
        return planItemsStore.useGetter(CrudGetter.PendingBasic, "WaitingPlanItems");
    }
    
    get items(): PlanItemModel[] {
        return planItemsStore.useGetter(CrudGetter.DataBasic, "WaitingPlanItems");
    }

    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.loadItems();
    }
    
    loadItems () {
        planItemsStore.dispatch(CrudAction.GetAllBasic, createCrudQueryPayload<PlanItemModel>({ field: "time", index: 1, order: "desc" }, [
            { field: "time", op: "gte", comparand: moment().add(-1, "year").startOf("day").toDate() },
            { field: "time", op: "lte", comparand: moment().add(-1, "days").endOf("day").toDate() },
            { field: "isAnswered", op: "eq", comparand: false },
            //{ field: "responseCount", op: "eq", comparand: 0 },
            { field: "tenantUserId", op: "eq", comparand: this.tenantUser.id! }
        ], undefined, false, "WaitingPlanItems"));
    }

}

