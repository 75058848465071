import {CrudDataQuery, CrudEntity} from '@/libs/core/+state/models/crud-service';
import {GroupByDefinition, GroupByProjectionDefinition, GrouppedQueryModel, PredicateDefinition, QueryModel} from '@/libs/Api';
import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import {CrudOrderBy} from '@/libs/core/+state/models/crud-order-by';
import {CrudQueryPaging} from '@/libs/core/+state/models/crud-query-paging';

export function createCrudQueryPayload<T extends CrudEntity>(orderBy: CrudOrderBy<T> | CrudOrderBy<T>[] = [], predicates: CrudQueryPredicate[] = [], paging: CrudQueryPaging = {
    pageIndex: 1,
    pageSize: 999999
}, useCache = false, key = ""): { query: CrudDataQuery<T>, key: string } {
    return {
        query: {
            ...paging,
            data: {
                predicates: predicates,
                orderby: Array.isArray(orderBy) ? orderBy.map(mapOrderBy) : [mapOrderBy(orderBy)]
            },
            useCache,
        },
        key: key
    }
}

function mapOrderBy<T>(o: CrudOrderBy<T>) {
    return {
        field: o.field,
        index: o.index || 0,
        order: o.order
    }
}

export function createGrouppedQueryPayload<T extends CrudEntity>(predicates: PredicateDefinition[] = [], groupBy: GroupByDefinition[] = [], projections: GroupByProjectionDefinition[] = [], key = ""): { query: GrouppedQueryModel, key: string } {
    return {
        query: {
            groupby: groupBy,
            projections: projections,
            predicates: predicates
        },
        key: key
    }
}