












































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';

import ApiService from "@/libs/core/api-service"
import {authStore} from '@/libs/auth/+store/store';
import { AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { SignInModel } from '@/libs/auth/models/auth-models';

import { ToastPlugin } from '@syncfusion/ej2-vue-notifications';
import { ToastComponent } from '@syncfusion/ej2-vue-notifications';
import Page from '@/Page.vue';
import { tenantsStore } from '@/libs/tenants/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { AadConnectResponseModel, TenantModel } from '@/libs/Api';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { EventTypeEnum } from '@/libs/core/models/event-type-enum';
import { AuthenticationType } from '../models/auth-type';
import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { getAccessToken, setAccessToken, setAuthTokens } from 'axios-jwt';

Vue.use(ToastPlugin);

@Component({
  components: {
    ToastComponent
  }
})
export default class SignIn extends Page {
    email = null;
    password = null;
    pending = false;
    basicRules = [
        (v: any) => !!v || this.$i18n.t('common.thisFileIsRequired')
    ];
    valid = false;
    showToastSuccess = false;
    showToastError = false;
    sending: boolean | null = null;
    requiredMfa = false;
    error: string | null = null;
    mfaCode: string | null = null;
    AuthenticationType: typeof AuthenticationType = AuthenticationType;
    loadingSignInToken = false;

    get isLoggedIn(): boolean {
        return authStore.useGetter(AuthGetter.GetIsLoggedIn);
    }

    get isAadAuthenticationSupported(): boolean {
        return authStore.useGetter(AuthGetter.GetSupportedAuthenticationTypes).contains(AuthenticationType.AzureActiveDirectory);
    }

    get isStandardAuthenticationSupported(): boolean {
        return authStore.useGetter(AuthGetter.GetSupportedAuthenticationTypes).contains(AuthenticationType.Standard);
    }

    get isSingleTenant(): boolean {
        return authStore.useGetter(AuthGetter.GetIsSingleTenant) ?? false;
    }

    @Ref() form!: any;
    
    @Watch("isLoggedIn")
    isLoggedInChanged (v: boolean) {
        if (v) {
            const self = this;
            authStore.dispatch(AuthAction.UserInfo);
            const subscription = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === authStore.getActionName(AuthAction.UserInfo)) {
                    subscription();
                    const user = mutation.payload;
                    (window as any).user = user;
                    self.goToTenantOrDefault();
                    //self.$router.replace({ name: "Home" });
                    //window.location.reload();
                }
            });
        }
        console.log("isLoggedIn", v);
    }

    mounted() {
        const token = getAccessToken();
        if (authStore.useGetter(AuthGetter.GetIsLoggedIn)) {
            this.$router.replace({ name: "Home" });
            return;
        } else {
            if (typeof this.$route.query.recoverySuccess != "undefined" && this.$route.query.recoverySuccess != null) {
                if (this.$route.query.recoverySuccess == "true" || this.$route.query.recoverySuccess) {
                    this.showToastSuccess = true;
                } else {
                    this.showToastError = true;
                }
            }
        }
    }

    submit () {
        if (this.form.validate()) {
            const payload = new SignInModel();
            payload.email = this.email;
            payload.password = this.password;
            payload.mfaCode = this.mfaCode;
            this.sending = true;
            this.error = null;
            authStore.dispatch(AuthAction.SetAuthenticationType, AuthenticationType.Standard);
            authStore.dispatch(AuthAction.SignIn, payload);
            const self = this;
            const createdSubscriber = this.$store.subscribe((mutation, _state) => {
                if(mutation.type === authStore.getActionName(AuthAction.SignInResponse)) {
                    createdSubscriber();
                    self.sending = false;
                    if (typeof mutation.payload.error != undefined && mutation.payload.error == "invalid_grant") {
                        self.error = "Nebylo možné se přihlásit. Prosím zkontrolujte přihlašovací údaje.";
                    }
                    else if (typeof mutation.payload.error != undefined && mutation.payload.error == "requires_mfa") {
                        self.requiredMfa = true;
                    }
                    else if (typeof mutation.payload.error != undefined && mutation.payload.error == "bad_mfa") {
                        self.error = this.$i18n.t("mfa.singInCommandError").toString();
                    }
                    else {
                        self.goToTenantOrDefault();
                        (window as any).Journaly.publish(EventTypeEnum.SignedIn);
                    }
                }
            });
        }
    }

    goToPasswordRecovery () {
        this.$router.replace({ name: "PasswordRecovery" });
    }
    
    goToTenantOrDefault () {
        applicationUserStore.dispatch(CrudAction.Get, {});
        this.subscribe(applicationUserStore, CrudReponse.Get).then((e: any) => {
            tenantsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload());
            this.subscribe(tenantsStore, CrudReponse.GetAll).then((e: TenantModel[]) => {
                if (window.localStorage.CurrentTenant && e.any(x => x.id == window.localStorage.CurrentTenant)) {
                    authStore.dispatch(AuthAction.SetTenant, window.localStorage.CurrentTenant);
                    this.$router.push({ name: "Home", params: { tenantId: window.localStorage.CurrentTenant } });
                }
                else if (e.length > 0) {
                    authStore.dispatch(AuthAction.SetTenant, e[0].id!);
                    this.$router.push({ name: "Home", params: { tenantId: e[0].id! } });
                }
                else {
                    this.$router.push({ name: "UserSettings" });
                }
            }).catch(() => {
                this.$router.push({ name: "UserSettings" });
            });
        });
    }

    async goToAzureSignIn() {
        this.loadingSignInToken = true;
        const azureTenantId = authStore.useGetter(AuthGetter.GetAzureTenantId);
        const azureAppId = authStore.useGetter(AuthGetter.GetAzureAppId);
        const msalConfig = {
            auth: {
                clientId: azureAppId,
                authority: 'https://login.microsoftonline.com/' + azureTenantId
            }
        };
        const msalInstance = new PublicClientApplication(msalConfig);
        msalInstance.handleRedirectPromise();
        await msalInstance.loginPopup({
            scopes: ["user.read"],
            prompt: 'select_account'
        }).then((x) => {
            const account = msalInstance.getAllAccounts().firstOrDefault(a => a.username == x.account?.username);
            ApiService.api.aadConnect({
                token: account!.idToken!
            }).then(response => {
                const connectResponse = response.data as AadConnectResponseModel;
                if (connectResponse.isLoggedIn) {
                    const request = { scopes: ['user.read'], account: account! };
                    msalInstance.acquireTokenSilent(request).then(x => {
                        setTimeout(() => {
                            this.loadingSignInToken = false;
                        }, 5000);
                        const token = x.accessToken;
                        setAuthTokens({
                            accessToken: token,
                            refreshToken: ""
                        });
                        authStore.dispatch(AuthAction.SetAuthenticationType, AuthenticationType.AzureActiveDirectory);
                        setAccessToken(account!.idToken!);
                        authStore.dispatch(AuthAction.UserInfo);
                        this.goToTenantOrDefault();
                        (window as any).Journaly.publish(EventTypeEnum.SignedIn);
                    }).catch(error => {
                        this.loadingSignInToken = false;
                        if (error instanceof InteractionRequiredAuthError) {
                            // fallback to interaction when silent call fails
                            return msalInstance.acquireTokenRedirect(request)
                        }
                        debugger;
                        // handle other errors
                    });
                }
                else {
                    this.loadingSignInToken = false;
                }
            }).catch(() => {
                this.loadingSignInToken = false;
            });
        })
        .catch((error: any) => {
          console.error(`error during authentication: ${error}`);
            this.loadingSignInToken = false;
        });
    }

}
