























import { NotRatedOptionReasonModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { isArray } from 'lodash';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import Autocomplete from '../components/Autocomplete.vue';
import { notRatedOptionReasonsStore } from '@/libs/not-rated-option-reasons/+state/store';
import { createAggregationQueryPayload } from '@/libs/core/+state/models/aggregation-query-payload';

@Component({})
export default class NotRatedOptionReasonSelect extends Vue {

    innerValue = null;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: true }) hideDetails!: boolean;
    @Prop() placeholder!: string;
    @Prop() attach!: string;

    @Ref() autocomplete!: Autocomplete;

    @Watch("innerValue")
    innerValueChanged (v: string | null, p: string | null) {
        if (v != null && v.length > 0 && v != p) {
            this.$emit("select", v);
        }
    }

    get items(): NotRatedOptionReasonModel[] {
        return [...(notRatedOptionReasonsStore.useGetter(CrudGetter.Data) as NotRatedOptionReasonModel[])].sort((a: NotRatedOptionReasonModel, b: NotRatedOptionReasonModel) => {
            if(a.name! < b.name!) { return -1; }
            if(a.name! > b.name!) { return 1; }
            return 0;
        });
    }

    mounted () {
        notRatedOptionReasonsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<NotRatedOptionReasonModel>())
    }

}
