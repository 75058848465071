














































































import { AuditModel, TenantModel, TenantUserModel } from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { adminTenantsStore } from '@/libs/admin-tenants/+state/store';
import { adminTenantUsersStore } from '@/libs/admin-tenant-users/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';

@Component({})
export default class AdminTenants extends Page {

    valid = false;
    itemsPerPage = 10;
    tenantUserHeaders = [
        { text: 'Jméno', value: 'displayName', sortable: false, align: 'left' },
        { text: 'Telefon', value: 'phoneNumber', sortable: false, align: 'left' },
        { text: 'Email', value: 'email', sortable: false, align: 'left' },
        { text: 'Stav', value: 'isActive', sortable: false, align: 'left' },
    ];

    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    @Prop() adminTenantId!: string;
    
    get loading(): boolean {
        return adminTenantsStore.useGetter(CrudGetter.Pending);
    }
    
    get tenant (): TenantModel {
        return {...adminTenantsStore.useGetter(CrudGetter.Detail)};
    }

    get saving(): boolean {
        return adminTenantsStore.useGetter(CrudGetter.Saving);
    }

    get tenantUsers(): TenantUserModel[] {
        return adminTenantUsersStore.useGetter(CrudGetter.Data);
    }

    get activeUserCount(): number {
        const tenantUsers = adminTenantUsersStore.useGetter(CrudGetter.Data) as TenantUserModel[];
        return tenantUsers.where(x => x.isActive == true).count();
    }

    get inactiveUserCount(): number {
        const tenantUsers = adminTenantUsersStore.useGetter(CrudGetter.Data) as TenantUserModel[];
        return tenantUsers.where(x => x.isActive != true).count();
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.load();
    }

    load () {
        adminTenantsStore.dispatch(CrudAction.Get, { id: this.adminTenantId });
        adminTenantUsersStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TenantUserModel>([
            { field: "isActive", index: 1, order: "desc" },
            { field: "user.firstName", index: 2, order: "asc" },
            { field: "user.lastName", index: 3, order: "asc" }
        ], [
            { field: "tenantId", op: "eq", comparand: this.adminTenantId }
        ]));
    }

    save () {
        adminTenantsStore.dispatch(CrudAction.Update, { item: this.tenant });
    }

}
