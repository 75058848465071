












































import { WorkplaceModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { positionsStore } from '@/libs/positions/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import PositionDialog from '../components/PositionDialog.vue';
import { Guid } from '@/libs/common/functions/guid';

@Component({
    components: {
        PositionDialog
    }
})
export default class Positions extends Page {

    get headers() {
        return [
            { text: this.$i18n.t('common.name'), value: "code", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }
    itemsPerPage = 10;

    @Prop() tenantId!: string;
    @Ref() dialog!: PositionDialog;

    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    get loading(): boolean {
        return positionsStore.useGetter(CrudGetter.Pending);
    }
    
    get categories (): WorkplaceModel[] {
        return [...positionsStore.useGetter(CrudGetter.Data)];
    }

    get totalRows (): number {
        return ({...positionsStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.load();
    }

    load () {
        positionsStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [];
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<WorkplaceModel>({ field: "name", index: 1, order: "asc" }, this.getFilters());
    }

    open (item: WorkplaceModel) {
        this.dialog.open(item.id!);
    }

    createItemClick () {
        this.dialog.open(Guid.EmptyGuid());
    }

}
