export default {
    common: {
        done: "Hotovo",
        record: "Záznam",
        show: "Zobrazit",
        activate: "Aktivovat",
        evaluate: "Hodnotit",
        add: "Přidat",
        create: "Vytvořit",
        detail: "Detail",
        admin: "Administrátor",
        manager: "Manažer",
        save: "Uložit",
        month: "Měsíc",
        day: "Den",
        user: "Uživatel",
        week: "Týden",
        yes: "Ano",
        no: "Ne",
        active: "Aktivní",
        inactive: "Neaktivní",
        edit: "Upravit",
        close: "Zavřít",
        clear: "Vymazat",
        delete: "Smazat",
        search: "Hledat...",
        change: "Změnit",
        cancel: "Zrušit",
        noRecords: "Nebyly nalezeny žádné záznamy.",
        deleteItemMessage: "Opravdu chcete smazat tuto položku?",
        isOk: "OK",
        isOkWithWarning: "OK, s výhradami",
        isOkWithWarningShort: "OK, s výhr.",
        isNotOk: "NOK",
        waiting: "Čeká",
        created: "Vytvořeno",
        notCreated: "Nevytvořeno",
        none: "Není",
        hasNone: "Není, bez neshod",
        closed: "Uzavřeno",
        inProgress: "V řešení",
        notClosed: "Neuzavřeno",
        all: "Vše",
        onlyMine: "Pouze moje",
        withNok: "S neshodami",
        withoutNok: "Bez neshod",
        clickAndSelect: "Zde klikněte a vyberte",
        uploadFile: "Nahrát soubor (max. {maxUploadFileSize} MB)",
        finishUntil: "Splnit do",
        fileTooBig: "Soubor je příliš velký",
        score: "Skóre",
        scoreShort: "Skóre",
        requiredEscalation: "Povinná zjištění",
        optionalEscalation: "Volitelná zjištění",
        noEscalation: "Bez zjištění",
        name: "Název",
        copy: "Zkopírovat do schránky",
        copySuccess: "Úspěšně zkopírováno do schránky",
        useDefinedExplanation: "Nastavit vysvětlivky pro všechny audity této kategorie. Tyto vysvětlivky můžete pro konkrétní audit změnit v nastavení auditu.",
        useCommentOnResponse: "Povolit komentáře k odpovědím",
        useNotRatedOption: "Umožnit volbu nehodnotit u otázky",
        enforceReasonForNotRatedOption: "Vyžadovat zadání důvodu nehodnocení při volbě nehodnoceno",
        useMeasureType: "Používat typy opatření (nápravná/preventivní)",
        enforceInstuctionsConfirmation: "Vyžadovat potvrzení přečtení zadání auditu",
        explanation: "Vysvětlivka",
        dateSince: "Datum od",
        dateTill: "Datum do",
        rowsPerPage: "Řádků na stránku",
        workplace: "Pracoviště",
        shiftType: "Směna",
        notRated: "Nehodnoceno",
        unsavedChanges: "Neuložené změny",
        unsavedChangesText: "Zaregistrovali jsme neuložené změny. Chcete je před zavřením uložit?",
        thisFileIsRequired: "Toto pole je povinné.",
        localeProp: "cs-CZ",
        downloadTemplate: "Stáhnout vzor",
        showError: "Zobrazit chybu",
        showWarning: "Zobrazit varování",
        enterAnInteger: "Zadejte celé číslo.",
        mustBeMoreOrEqual: "Hodnota musí být větší nebo rovno {value}.",
        mustBeLessOrEqual: "Hodnota musí být menší nebo rovno {value}.",
        cannotBeUndone: "Tuto akci nelze vrátit zpět.",
        yesDelete: "Ano, smazat",
        downloadFile: "Stáhnout soubor",
        correctMistakes: "Opravit chyby"
    },
    days: {
        mo: "po",
        tu: "út",
        we: "st",
        th: "čt",
        fr: "pá",
        sa: "so",
        su: "ne"
    },
    menu: {
        dashboard: 'Nástěnka',
        audit: 'Šablony auditů',
        tasks: "Kalendář úkolů",
        responses: "Provedené audity",
        unfinishedResponses: "Neprovedené audity",
        settings: "Nastavení",
        issues: "Zjištění",
        issuesLong: "Zjištění (osobní seznam úkolů)",
        solvedIssues: "Opatření",
        solvedIssuesLong: "Opatření (seznam úkolů)",
        verification: "Kontrola účinnosti opatření",
        verificationLong: "Kontrola účinnosti opatření (osobní seznam úkolů)",
        reports: "Statistiky",
        review: "Schvalování auditů",
        reviewLong: "Schvalování auditů (osobní seznam úkolů)",
    },
    audits: {
        name: "Název auditu",
        categoryName: "Kategorie auditů",
        addAudit: "Přidat audit",
        cloneAudit: "Klonovat audit",
        owner: "Vlastník auditu",
        performAudit: "Provést audit",
        done: "Provedené",
        unfinished: "Neprovedené",
        auditDetail: "Detail auditu",
        auditClose: "Uzavření auditu",
        auditDefinition: "Zadání auditu",
        aiReportInstructions: "Dodatečné zadání pro AI při generování reportu provedeného auditu",
        auditLateChangesQuestion: "Chcete později provést úpravy nebo vložit fotografie/soubory?",
        wantToMakeChangesLater: "Chci později provést úpravy",
        wantToFinishAndLock: "Dokončit a uzamknout",
        okAccept: "OK, akceptuji",
        someItemsWereNotTyped: "Některé položky nebyly vyplněny.",
        auditExists: "Audit s tímto evidenčním číslem již existuje.",
        deletePlannedAudit: 'Smazat naplánovaný audit',
        deletePlannedAuditDisabled: 'Smazání lze provést pouze výmazem předpisu plánování v šabloně auditu, záložce Plán. Výmazem budou smazány všechny audity naplánované dle předpisu.',
        deletePlannedAuditMessage: 'Opravdu chcete smazat naplánováný audit {name} na {time}?'
    },
    audit: {
        menu: {
            general: "Obecné",
            questions: "Otázky",
            plan: "Plán",
            calendar: "Kalendář",
            responses: "Odpovědi",
            reports: "Reporty"
        },
        general: {
            name: "Název auditu",
            deleteAudit: "Smazat audit",
            deleteAuditMessage: "Opravdu chcete odstranit audit?",
            auditState: "Stav auditu",
            explanationType: "Způsob používání vysvětlivek",
            explanationTypeNone: "Nepoužívat vysvětlivky",
            explanationTypeByCategory: "Dle nastavení kategorie",
            explanationTypeByAudit: "Nastavit jednotné pro celý audit",
            explanationTypeByQuestionGroup: "Nastavit pro skupiny otázek",
            procestElements: "Kroky procesu",
            procestElementCount: "Počet kroků procesu",
            label: "Označení",
            stepNumber: "Číslo kroku",
            useProductGroups: "Používat skupiny produktů",
            productGroups: "Skupiny produktů",
            productGroupName: "Název skupiny produktů",
            addProductGroup: "Přidat skupinu produktů"
        },
        questionGroups: {
            name: "Název skupiny otázek",
            add: "Přidat skupinu",
            processElement: "Prvek procesu",
            processSubElement: "Dílčí prvek procesu"
        },
        questions: {
            code: "Kód otázky",
            name: "Otázka",
            list: "Seznam otázek",
            add: "Přidat otázku",
            description: "Podrobnosti k otázce",
            disagreementResponse: "Reakce na neshodu",
            explanations: "Vysvětlivky",
            uniformExplanationsCheckbox: "Vysvětlivky pro všechny skupiny otázek jednotné",
            explanatoryPictures: "Vysvětlující obrázky a soubory",
            noImages: "Nebyly nahrány žádné obrázky",
            deleteImage: "Smazat obrázek",
            deleteImageMessage: "Opravdu chcete smazat tento soubor?",
            questionFileTip: "Tip: Přesunutím Drag&Drop položky na jiné místo upravíte pořadí",
            isHighRisk: "Otázka se zvláštním rizikem"
        },
        plan: {
            repeatEvery: "Opakovat každých",
            nextBusinessDayOnHoliday: "V případě svátku plánovat na další pracovní den",
            add: "Přidat",
            dayOfMonth: "Den v měsíci",
            occurrenceCount: "Počet opakování",
            planFromDate: "Plánovat od",
            planToDate: "Plánovat do",
            userGroup: "Skupina uživatelů",
            days: "Dny",
            interval: "Období",
            daysOfWeek: "Dny v týdnu",
            typeOfPlanning: "Typ plánování",
            workplace: "Pracoviště",
            shiftType: "Směna"
        },
        recapitulation: {
            header: "Rekapitulace",
            workplaceNameMissing: "Chybí název pracoviště",
            unansweredQuestionsCount: "Počet nezodpovězených otázek",
            auditorCommentMissing: "Chybí komentář auditora",
            immediateMeasuresDescriptionMissing: "Chybí popis okamžitých opatření",
            auditorEscalationCommentsMissing: "Chybí komentářů auditora pro eskalaci",
            missingImmediateMeasures: "Chybí popis okamžitého odstranění problému",
            missingEscalationDescription: "Chybí popis zjištění",
        }
    },
    planItems: {
        evidenceNumber: "Číslo auditu",
        category: {
            name: "Název kategorie",
        },
        audit: {
            name: "Název auditu",
            number: "Číslo auditu"
        },
        user: {
            displayName: "Auditor",
            department: "Oddělení auditora",
            position: "Pracovní pozice auditora"
        },
        implementerUser: {
            displayName: "Realizátor opatření"
        },
        managerUser: {
            displayName: "Správce opatření"
        },
        findingManagers: "Správci opatření",
        workplace: {
            name: "Pracoviště"
        },
        shiftType: {
            name: "Směna"
        },
        responseCount: "Počet odpovědí",
        okCount: "Počet OK",
        okCountWithWarning: "Počet OK s výhradami",
        nokCount: "Počet NOK",
        scoreValue: "Počet bodů",
        successRate: "Stupeň plnění",
        notAnswered: "Nehodnoceno",
        time: "Datum auditu",
        finishTime: "Datum provedení auditu",
        correctionState: "Opatření",
        implementationState: "Stav opatření",
        planItemState: "Stav provedeného auditu",
        response: "Odpověď",
        userGroup: "Skupina uživatelů",
        state: {
            WaitingToPerform: "Čeká na provedení auditu",
            ClosedWithoutFindings: "Uzavřeno, bez zjištění",
            NeedsToCreateMeasures: "Potřebuje vytvořit opatření",
            NeedsToPerformMeasures: "Potřebuje provést opatření",
            NeedsToVerifyMeasures: "Potřebuje provést kontrolu opatření",
            AllMeasuresVerified: "Provedena kontrola u všech opatření",
            PerformedWithoutFindings: "Audit proveden bez zjištění",
            WaitingForApproval: "Čeká na schválení",
            Approved: "Audit schválen",
            Declined: "Audit neschválen",
            Closed: "Uzavřeno"
        }
    },
    workTask: {
        downloadAudit: "Stáhnout audit",
        download: "Stáhnout",
        sendToApprove: "Odeslat ke schválení",
        sendAgainToApprove: "Znovu odeslat ke schválení",
        declineAuditApproval: "Odmítnout schválení auditu",
        audit: "Audit",
        finding: "Zjištění",
        correction: "Opatření",
        verify: "Účinnost opatření",
        issueDescription: "Popis problému",
        escalate: "Přidat zjištění",
        cancelEscalation: "Odebrat zjištění",
        immediateMeasures: "Popis okamžitého odstranění problému",
        addComment: "Přidat komentář",
        cancelComment: "Odebrat komentář",
        addImmediateMeasures: "Přidat okamžité odstranění problému",
        cancelImmediateMeasures: "Odebrat okamžité odstranění problému",
        implementationControllers: "Kontroloři účinnosti realizace opatření",
        reworkComment: "Komentář k přepracování",
        filesToControl: "Soubory ke kontrole účinnosti realizace",
        sendToControl: "Odeslat ke kontrole účinnosti uživateli",
        willBeSendToControl: "Po uložení bude odesláno ke kontrole účinnosti realizace opatření",
        myControl: "Moje kontrola účinnosti opatření",
        othersControl: "Kontrola účinnosti opatření ostatních uživatelů",
        noCorrectionToControl: "Nejsou dostupné žádné opatření ke kontrole účinnosti realizace.",
        enterControl: "Zadat kontrolu účinnosti opatření",
        editControl: "Upravit kontrolu účinnosti opatření",
        doneEffectively: "Opatření provedeno účinně",
        takeBackToImplementer: "Vrátit realizátorovi",
        sendToImplementer: "Odeslat realizátorovi",
        willBeTakeBackToImplementer: "Bude vráceno zpět realizátorovi opatření.",
        mineMeasures: "Moje opatření k realizaci",
        noMineMeasures: "Nejsou dostupná žádná opatření k realizaci.",
        otherMeasures: "Opatření k realizaci ostatními uživateli",
        noFindings: "Nejsou dostupná žádná zjištění.",
        findigsForOthers: "Zjištění auditora pro ostatní správce",
        approveDialog: {
            auditApproval: "Schválení auditu",
            approveAudit: "Schválit audit",
            auditWillBeApproved: "Audit bude schválen.",
            auditWillBeSend: "Audit bude odeslán ke schválení.",
            auditSendToApprove: "Odeslání auditu ke schválení",
            approver: "Schvalovatel"
        },
        closeDialog: {
            closeAudit: "Uzavřít audit",
            close: "Uzavřít",
            auditWillBeClosed: "Audit bude tímto uzavřen.",
            planControlAudit: "Naplánovat kontrolní audit"
        },
        createDialog: {
            planAudit: "Naplánovat audit",
            periodTitle: "Naplánovat na",
            specificDay: "Konkrétní den",
            period: "Období"
        },
        declineDialog: {
            auditDecline: "Odmítnutí auditu",
            declineAuditApproval: "Odmítnout schválení auditu",
            auditWontBeApproved: "Audit nebude schválen a bude vrácen k přepracování.",
            enterReason: "Zadejte důvod odmítnutí schválení auditu"
        },
        findingItem: {
            correctionManagers: "Správci opatření",
            correction: "Opatření",
            addCorrection: "Přidat opatření",
            deleteCorrection: "Smazat opatření",
            finding: "Příčina",
            findingAndCorrection: "Příčina a opatření",
            finishDate: "Termín pro splnění",
            realizationDate: "Termín pro realizaci",
            newRealizationDate: "Nový termín pro realizaci",
            correctionType: "Typ opatření",
            correctionFiles: "Soubory k opatření",
            correctiveMeasures: "Nápravné opatření",
            preventiveMeasures: "Preventivní opatření",
            enterFindingAndCorrection: "Zadat příčinu a opatření",
            editFindingAndCorrection: "Upravit příčinu a opatření",
            corrective: "Nápravné",
            preventive: "Preventivní"
        },
        verifyMeasureItem: {
            question: "Otázka",
            answer: "Odpověď"
        },
        audits: {
            auditor: "Auditor",
            department: "Oddělení",
            position: "Pozice",
            finishTime: "Datum provedení auditu",
            approver: "Schvalovatel",
            requestReviewOn: "Datum odeslání ke schválení",
            reviewedOn: "Datum schválení auditu",
            declinedOn: "Datum neschválení auditu",
            approvedBy: "Schváleno uživatelem",
            declinedBy: "Neschváleno uživatelem",
            reasonForDecline: "Důvod neschválení",
            showAuditDescription: "Zobrazit zadání auditu",
            auditDescription: "Zadání auditu",
            delete: "Odebrat",
            escalateToManager: "Eskalovat na správce",
            allManagers: "Všichni správci",
            selectedManagers: "Vybraní správci",
            notRatedReason: "Důvod nehodnocení"
        },
        processStep: "Krok procesu",
        deleteWorkTask: "Smazat provedený audit",
        deleteWorkTaskMessage: "Opravdu chcete smazat tento provedený audit {name}?",
        report: "Zpráva z auditu"
    },
    workTasks: {
        showTable: "Tabulka",
        showKanban: "Kanban",
        iCalInfo: "Kliknutím vyvoláte dialog s detaily pro odběr kalendáře do kalendáře Outlook, Google nebo iCloud.",
        iCalUrl: "URL pro odběr kalendáře do kalendáře Outlook, Google nebo iCloud",
        iCalP1: "Odběr bude prováděn pouze pro Vaše úkoly, nikoli úkoly ostatních uživatelů. URL odkaz uchovávejte v tajnosti a nesdělujte jiným osobám.",
        iCalP2: "URL kalendáře zkopírujte a vložte do svého Outlook, Google nebo iCloud kalendáře. V Outlook, Google nebo iCloud kalendáři si nastavte odběr tohoto kalendáře."
    },
    unanswered: {
        noCategory: "V plánu auditu není vyplněna skupina uživatelů",
        noAuditTiming: "Není, naplánováno jednorázově"
    },
    issues: {
        performanceFromDate: "Provést audit od",
        performanceToDate: "Provést audit do",
        time: "Datum auditu",
        audit: {
            name: "Název auditu",
            number: "Číslo auditu"
        },
        user: {
            displayName: "Auditor"
        },
        question: {
            code: "Kód otázky"
        },
        state: "Stav opatření",
        stateOfMeasures: "Stav opatření",
        stateOfMeasure: "Stav opatření",
        repaired: "Opraveno",
        needsRepair: "Potřebuje opravit",
        response: "Komentář auditora",
        responseText: "Do komentáře nezadávejte popis zjištěných neshod",
        escalationDescription: "Zjištění auditora",
        findingsFiles: "Soubory ke zjištění",
        responseFiles: "Soubory k otázce",
        repairment: "Opatření",
        measure: "Opatření",
        implementer: "Realizátor opatření",
        implementerOfMeasure: "Realizátor opatření",
        implementationState: "Stav realizace opatření",
        implementationStateSingle: "Stav opatření",
        implemented: "Realizována všechna opatření",
        needsImplementation: "Potřebuje realizovat opatření",
        disagreements: "Neshody",
        repairmentComment: "Komentář k opatření",
        repairmentDescription: "Popis opatření",
        measureDescription: "Popis opatření",
        implementUntil: "Termín",
        implementationTime: "Datum realizace",
        implementationDelay: "Prodlení (dny)",
        implementationComment: "Komentář k realizaci",
        implementationFiles: "Soubory k realizaci opatření",
        editImplementation: "Upravit realizované opatření",
        enterImplementation: "Zadat realizaci opatření"
    },
    issueDialog: {
        new: "Nové",
        inProgress: "V řešení",
        verified: "Provedena kontrola účinnosti",
        waiting: "Čeká",
        performed: "Provedeno",
        waitingForVerification: "Čeká na kontrolu účinnosti"
    },
    measures: {
        New: "Nové",
        InProgress: "V řešení",
        WaitingForVerification: "Čeká na kontrolu účinnosti",
        Verified: "Provedena kontrola účinnosti",
        unfinished: "K provedení",
        finished: "Provedené"
    },
    settings: {
        menu: {
            users: "Uživatelé",
            catalog: "Katalog úkonů",
            organization: "Firma",
            workplaces: "Pracoviště",
            categories: "Kategorie auditů",
            roles: "Role",
            groups: "Skupiny uživatelů",
            notRatedOptionReasons: "Důvody nehodnocení",
            departments: "Oddělení",
            positions: "Pracovní pozice",
            shiftTypes: "Směny",
            import: "Importy",
        },
        accounts: {
            inviteToOrganization: "Pozvat uživatele",
            invite: "Pozvat",
            deleteInvitation: "Smazat pozvánku",
            deleteInvitationConfimMessage: "Opravdu chcete smazat pozvánku pro uživatele {email}?",
            deleteUser: "Smazat uživatele",
            deleteUserConfirmMessage: "Opravdu chcete odstranit uživatele {email}?",
            invitations: "Pozvánky",
            columnName: "Jméno a příjmení",
            columnPhoneNumber: "Telefon",
            columnEmail: "E-mail",
            columnRoles: "Role",
            avatar: "Profilová fotka",
            addRole: "Přidat roli",
            maxUserCountReached: "Maximální počet aktivních uživatelů byl dosažen.",
            allowedCategories: "Povolené kategorie auditů",
            addAllowedCategory: "Přidat povolenou kategorii auditů",
            all: "Všechny",
            selected: "Vybrané",
            activeUsers: "Aktivní uživatelé",
            inactiveUsers: "Neaktivní uživatelé",
            company: "Firma",
            registeredUsersList: "Seznam registrovaných uživatelů",
            allCategories: "Všechny kategorie",
            department: "Oddělení",
            position: "Pracovní pozice",
            activeUserCount: "Počet aktivních uživatelů",
            inactiveUserCount: "Počet neaktivních uživatelů"
        },
        organization: {
            name: "Název firmy",
            street: "Ulice a číslo popisné",
            city: "Město",
            zip: "PSČ",
            identificationNumber: "IČ",
            vat: "DIČ",
            repeatHeader: "Nastavení opakování",
            repeatEvery: "Opakovat každý",
            licenceToDate: "Platnost licence do",
            licenceUserCount: "Počet aktivních uživatelů v licenci",
            isEnabledCommentOnOkResponse: "Umožnit komentáře a připojování souborů k OK odpovědím",
            organizationIcon: "Ikona firmy",
            upload: "Nahrát",
            registeredOrganizationsList: "Seznam registrovaných firem",
            detail: "Detail firmy",
            twoFactor: "Dvoufaktorové přihlašování",
            isRequiredTwoFactor: "Vyžadovat dvoufaktorové přihlašování",
            isRequiredTwoFactorDescription: "Aktivováním této volby vynutíte u uživatelů nastavení povinného dvoufaktorového přihlášování. Bez dvoufaktorového přihlašování nebudou uživatelé mít přístup v rozsahu svých oprávnění k datům této firmy.",
            imageUploadOptimization: "Optimalizace fotek při nahrání k auditu",
            imageUploadUseCompression: "Používat optimalizaci fotek",
            imageUploadMaxResolution: "Maximální rozlišení (šířka nebo výška)",
            imageUploadQuality: "Snížení kvality fotky na",
            imageUploadMaxFileSize: "Maximální velikost souboru"

        },
        groups: {
            infoText: "Skupiny uživatelů jsou využívány pro plánování prováděných auditů do kalendáře jednotlivých uživatelů."
        },
        categories: {
            infoText: "Správci opatření zařazení do kategorie auditu obdrží emailová upozornění na zjištění z provedených auditů.",
            responseValueType: "Typ odpovědí auditu",
            categorySettings: "Nastavení kategorie",
            addCategory: "Přidat kategorii",
            useAuditApproval: "Povolit proces schvalování auditu pro provedené audity se zjištěními",
            useAuditApprovalWithNoFindings: "Povolit proces schvalování auditu pro provedené audity bez zjištění",
            planItemCloseType: "Kdo uzavírá audit",
            byAuditor: "Auditor",
            byReviewer: "Schvalovatel",
            byAuditorOrReviewer: "Auditor nebo schvalovatel",
            measureVerificationUserType: "Kontrolou účinnosti opatření pověřit",
            auditPerformer: "Auditora, který prováděl audit",
            userSelect: "Umožnit realizátorovi vybrat ze seznamu dostupných auditorů",
            tenantUsersOfCategory: "Seznam uživatelů pro tuto kategorii"
        },
        import: {
            newImport: "Nový import",
            auditsAction: "Zahájit import do šablon auditů",
            audits: "Šablony auditů",
            templates: "Vzory",
            templateName: "Název vzoru",
            previousImports: "Předchozí importy",
            table: {
                templateHeaders: {
                    name: "Název vzoru",
                    importType: "Typ importu"
                },
                previousImportsHeaders: {
                    name: "Název souboru",
                    start: "Zahájení importu",
                    importType: "Typ importu",
                    state: "Dokončení"
                }
            },
            import: "Importovat",
            showPreview: "Zobrazit náhled",
            selectFromPreviousTemplate: "Vyberte ze vzor dřívějších importů",
            orSetNewImport: "nebo nastavte nový import",
            itemsInTable: "Položky v tabulce",
            firstRowContainsHeaders: "První řádek obsahuje názvy sloupců",
            stopIfError: "Pokud nebude možné některý záznam vložit - nepokračovat",
            insertOnlyNewRecords: "Vkládat pouze nové záznamy (stávající nebudou aktualizovány)",
            selectColumnName: "Vyberte název sloupce v datovém souboru",
            primaryKey: "Primární klíč",
            mandatoryItems: "Povinné položky",
            importStart: "Zahájit import",
            importOfFile: "Import souboru",
            canSaveAs: "Import můžete uložit jako vzor:",
            importHasBeenStarted: "Import byl zahájen",
            saveTemplate: "Uložit vzor",
            importError: "Chyba importu",
            started: "Zahájeno",
            completed: "Dokončeno",
            completedWithWarnings: "Dokončeno s varováním",
            errorDetail: "Detail chyby",
            warningDetail: "Detail varování",
            keepStructureInfo: "Importovaný excel soubor musí být ve struktuře stejné jako vzor importu."
        }
    },
    categories: {
        name: "Název kategorie",
        correctionManagers: "Správci opatření",
        deleteCageory: "Smazat kategorii",
        deleteCategoryMessage: "Opravdu chcete smazat kategorii?",
        cantDeleteCategory: "Kategorie je používaná v jednom nebo více auditech, nelze smazat."
    },
    authentification: {
        signIn: "Přihlásit",
        signUp: "Registrovat se",
        registration: "Registrace",
        passwordRecovery: "Obnova hesla",
        createAccount: "Vytvořit účet",
        firstName: "Jméno",
        lastName: "Příjmení",
        email: "Email",
        phoneNumber: "Telefonní číslo",
        registeredEmail: "Registrační email",
        password: "Heslo",
        passwordAgain: "Heslo znovu",
        newPassword: "Nové heslo",
        newPasswordAgain: "Nové heslo znovu",
        weWillSendYouRecoveryEmail: "Odešleme Vám odkaz pro obnovu hesla na registrační email.",
        requestPasswordReset: "Požádat o obnovu hesla",
        changePassword: "Změnit heslo",
        goToSignIn: "Jít na přihlašovací stránku",
        toast: {
            passwordRecovered: "Heslo úspěšně obnoveno",
            passwordRecoveredText: "Heslo bylo úspěšně obnoveno, můžete se přihlásit.",
            passwordNotRecovered: "Heslo se nepodařilo obnovit",
            passwordNotRecoveredText: "Během obnovení hesla se vyskytla chyba. Zkontrolujte token a zkuste znovu.",
            userNotFound: "Uživatel nebyl nalezen",
            userNotFoundText: "Uživatel se zadaným e-mailem nebyl nalezen. Zkuste prosím znovu.",
            passwordRecoveryRequestSent: "Žádost o obnovu hesla byla úspěšně zpracována",
            passwordRecoveryRequestSentText: "Na Vaší e-mailovou adresu byl odeslán potvrzovací odkaz pro obnovu."
        },
        registrationWasSuccessful: "Registrace byla provedena. Na e-mailovou adresu Vám byl zaslán aktivační e-mail.",
        activationLinkVerified: "Aktivační odkaz byl úspěšně ověřen. Nyní se můžete přihlásit.",
        verifyingActivationLink: "Probíhá ověřování aktivačního odkazu...",
        activationLinkFailed: "Aktivační odkaz se nepodařilo ověřit.",
        enteredPasswordsMustMatch: "Zadaná hesla se musí shodovat.",
        invalidLength: "Heslo musí být minimálně 6 znaků dlouhé.",
        errors: {
            signInFailed: "Nebylo možné se přihlásit. Prosím zkontrolujte přihlašovací údaje.",
            duplicateUserName: "Účet s touto e-mailovou adresou již existuje.",
            signUpError: "Při registraci nastala chyba."
        },
        organizationSignUp: {
            create: "Registrace firmy",
            name: "Název firmy",
            identificationNumber: "IČ",
            vat: "DIČ",
            street: "Ulice",
            city: "Město",
            zip: "PSČ",
            submit: "Registrovat firmu"
        }
    },
    groups: {
        name: "Název skupiny",
        groupUsers: "Uživatelé",
        deleteGroup: "Smazat skupinu",
        deleteGroupMessage: "Opravdu chcete smazat skupinu?",
        cantDeleteGroup: "Skupina je používaná u jednoho nebo více uživatelů, nelze smazat."
    },
    account: {
        header: "Uživatelské nastavení",
        firstname: "Jméno",
        lastName: "Příjmení",
        phoneNumber: "Telefonní číslo",
        twoFactor: "Dvoufázové ověření (Google authenticator)",
        email: "E-mail",
        profilePhoto: "Profilová fotka",
        upload: "Nahrát",
        passwordChange: "Změna hesla",
        oldPassword: "Staré heslo",
        password: "Heslo",
        passwordAgain: "Heslo znovu",
        connectedOrganizations: "Připojené firmy",
        disconnectFromOrganization: "Odpojit od firmy",
        disconnect: "Odpojit",
        invitations: "Pozvánky do firmy",
        invitationsLabel: "Máte následující pozvánky k připojení do těchto firem",
        connectToOrganization: "Připojit k firmě",
        deleteInvitation: "Smazat pozvánku",
        deleteOrganization: "Odpojit od firmy",
        deleteOrganizationMessage: "Opravdu se chcete odpojit od firmy {name}?",
        passwordsHasToMatch: "Nová hesla se musejí shodovat.",
        checkPassword: "Nová heslo nemohlo být uloženo, prosím zkontrolujte hesla.",
        passwordSaved: "Nové heslo bylo uloženo.",
        avatar: "Profilová fotka",
        createOrganization: "Vytvořit novou firmu",
        notifyWeeklyAudits: "Týdenní notifikace auditorům",
        notifyIdleMeasures: "Notifikace opatření k provedení",
        notifyCorrectionManagerUpdate: "Notifikace správcům opatření při zadání neshody",
        notifyCorrectionImplementerUpdate: "Notifikace realizátorům opatření po jejich vytvoření",
        selectLanguage: "Vyberte jazyk",
        calendarUrl: "URL pro odběr kalendáře do kalendáře Outlook, Google nebo iCloud",
        urlInfo: "URL kalendáře zkopírujte a vložte do svého Outlook, Google nebo iCloud kalendáře. Ve svém kalendáři si nastavte odběr tohoto kalendáře.",
        tenantDialog: {
            header: "Účet není připojen k žádné firmě",
            p1: "Pokud máte pozvánku k připojení do účtu firmy od jiného uživatele, můžete se k této firmě připojit. Pokud nemáte pozvánku, požádejte své kolegy o zaslání pozvánky.",
            p2: "Nebo můžete vytvořit novou firmu tlačítkem Vytvořit firmu. Bude založen účet firmy s defaultním nastavením, které si můžete upravit v Nastavení. Do tohoto účtu firmy můžete pozvat další uživatele v Nastavení v záložce Uživatelé."
        }
    },
    workplaces: {
        code: "Kód pracoviště",
        name: "Název pracoviště",
        deleteWorkplace: "Smazat pracoviště",
        deleteWorkplaceMessage: "Opravdu chcete smazat pracoviště?"
    },
    notRatedOptionReason: {
        name: "Název",
        deleteNotRatedOptionReason: "Smazat důvod nehodnocení",
        deleteNotRatedOptionReasonMessage: "Opravdu chcete smazat důvod nehodnocení?"
    },
    department: {
        name: "Název",
        deleteDepartment: "Smazat oddělení",
        deleteDepartmentMessage: "Opravdu chcete smazat oddělení?"
    },
    position: {
        name: "Název",
        deletePosition: "Smazat pracovní pozici",
        deletePositionMessage: "Opravdu chcete smazat pracovní pozici?"
    },
    roles: {
        name: "Název role",
        permissions: "Oprávnění",
        deleteRole: "Smazat roli",
        deleteRoleMessage: "Opravdu chcete smazat roli?",
        cantDeleteRole: "Role je používaná u jednoho nebo více uživatelů, nelze smazat."
    },
    permissions: {
        Admin: "Nastavení (editace firmy, rolí a uživatelů)",
        AdminGroups: "Nastavení (editace skupin uživatelů, kategorií auditů, pracovišť, oddělení, pracovních pozic a směn)",
        Audits: "Seznam auditů a jejich nastavení (zobrazení)",
        Plan: "Zobrazení kalendáře všech auditů",
        EditAllAudits: "Editace nastavení všech auditů",
        EditOwnedAudit: "Vytvořit a editovat audit (pouze svoje)",
        PerformAudit: "Provést audit (aplikace pro provádění auditu)",
        Response: "Provedené a neprovedené audity",
        Findings: "Zjištění (s možností zadání příčiny a opatření)",
        Measures: "Realizace opatření (pouze moje)",
        MeasuresAllUsers: "Realizace opatření (všichni)",
        MistakeCorrection: "Oprava chyb ve výsledcích auditů",
        MeasureVerification: "Kontrola účinnosti realizace opatření",
        PlanItemReview: "Schvalování provedených auditů"
    },
    dashboard: {
        donePlanItemsCount: "Počet splněných auditů za poslední rok",
        waitingPlanItemsCount: "Počet nesplněných auditů za poslední rok",
        donePlanItems: "Moje splněné audity za poslední rok",
        notFinished: "Moje dnešní a rozpracované audity",
        waitingPlanItems: "Moje nesplněné audity za poslední rok"
    },
    validation: {
        thisFieldIsRequired: "Požadovaná položka",
        emailIsRequired: "E-mail je vyžadován"
    },
    reports: {
        reportByQuestion: "Dle otázky",
        reportByQuestionTitle: "Graf počtu neshod na jednotlivých otázkách",
        reportAuditByWorkplace: "Dle pracoviště",
        reportAuditByWorkplaceTitle: "Graf počtu odpovědí dle pracoviště",
        reportAuditByUser: "Dle auditora",
        reportAuditByUserTitle: "Graf počtu odpovědí dle auditora",
        reportAuditByYear: "Dle roku",
        reportAuditByYearTitle: "Graf počtu odpovědí dle roku",
        reportAuditByCategory: "Dle kategorie auditu",
        reportAuditByCategoryTitle: "Graf počtu odpovědí dle kategorie auditu",
        question: "Otázka",
        count: "Počet",
        countOk: "Počet OK",
        countOkW: "Počet OK s výhr.",
        countNok: "Počet NOK",
        percent: "Procent",
        percentOk: "Procent OK",
        percentNok: "Procent NOK",
        totalAuditCount: "Celkový počet auditů",
        okAuditCount: "Počet OK auditů",
        nokAuditCount: "Počet NOK auditů",
        nokAuditPercent: "Procento neúspěšnosti auditů",
        filters: {
            category: "Kategorie auditů",
            fromDate: "Provedení od",
            toDate: "Provedení do",
            user: "Auditor",
            audit: "Audit",
            userGroup: "Skupina uživatelů"
        },
        export: "Exportovat do Excel"
    },
    badLicence: {
        licenceExpired: "Platnost Vaší licence vypršela dne",
        contactIDomino: "Kontaktujte prosím společnost iDomino.",
        accountNotActive: "Váš účet u této firmy není aktivní. Kontaktujte prosím svého správce firmy."
    },
    responseValue: {
        ok: "OK",
        okWithWarning: "OK, s výhradami",
        notOk: "NOK",
        scoreZero: "Skóre 0",
        scoreOne: "Skóre 1",
        scoreTwo: "Skóre 2",
        scoreThree: "Skóre 3",
        scoreFour: "Skóre 4",
        scoreFive: "Skóre 5",
        scoreSix: "Skóre 6",
        scoreEight: "Skóre 8",
        scoreTen: "Skóre 10",
        notRated: "Nehodnoceno",
        showScore: "Zobrazit hodnocení",
        customizeInfo: "Přizpůsobit zobrazení",
        hideScore: "Skrýt hodnocení",
        totalScore: "Stupeň plnění"
    },
    mfa: {
        activate: "Aktivovat",
        activateButton: "Aktivovat dvoufaktorové přihlašování",
        deactivate: "Deaktivovat",
        deactivateButton: "Deaktivovat dvoufaktorové přihlašování",
        mfaDialogHeader: "Dvoufaktorové přihlašování",
        mfaActivationInstructionHeader: "Návod na instalaci Google Authenticator na mobilní telefon:",
        mfaActivationInstructionLine1: "Na svůj mobilní telefon instalujte aplikaci Google Authenticator.",
        mfaActivationInstructionLine2: "V aplikaci Google Authenticator zvolte tlačítko „+“.",
        mfaActivationInstructionLine3: "Naskenujte zobrazený QR kód.",
        mfaActivationInstructionLine4: "Při každém přihlášení do aplikace AMS zadejte kód z aplikace Google Authenticator.",
        mfaActivationInstructionQR: "QR kód pro aktivací dvoufaktorového přihlašování:",
        mfaDeactivationInstructionHeader: "Deaktivovat dvoufaktorové přihlašování",
        mfaDeactivationInstructionText: "Pokračováním deaktivujete dvoufaktorové přihlašování. To snižuje bezpečnost Vašeho účtu. V případě, že firma připojená k Vašemu účtu vyžaduje povinné dvoufaktorové přihlašování, nebudete mít do jeho opětovné aktivace přístup k datům této firmy.",
        singInCommand: "Kód z Google Authenticator",
        singInCommandError: "Chybný kód pro dvoufaktorové přihlášení.",
        noAccess: "Pro přístup k datům této organizace je vyžadováno dvoufaktorové přihlašování. Toto přihlašování zapnete ve správě svého účtu.",
        goToAccount: "Přejít do správy účtu"
    },
    files: {
        dropFileHere: "Sem přesuňte soubor (do 50 MB) z počítače.",
        selectFile: "Vyberte soubor",
        uploadFile: "Nahrát soubor"
    },
    shiftTypes: {
        name: "Směna",
        deleteShiftType: "Smazat směnu",
        deleteShiftTypeMessage: "Opravdu chcete smazat směnu?"
    }
}
