
















































import ApiService from "@/libs/core/api-service"
import Page from '@/Page.vue';
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';

@Component({})
export default class TwoFactorDialog extends Page {

    @VModel() dialogValue!: boolean;
    @Prop() type!: string; // deactivate, activate
    saving = false;
    twoFactorValue: string | null = null;
    
    mounted() {
        if (this.type == 'activate') {
            this.generateTwoFactorCode();
        }
    }

    generateTwoFactorCode() {
        ApiService.api.generateTwoFactorKey().then((response) => {
            const key = response.data.returnValue;
            this.twoFactorValue = 'otpauth://totp/Audit Management System?secret=' + key + '&issuer=iDomino';
        });
    }

    activate() {
        this.saving = true;
        ApiService.api.enableTwoFactor().then((response) => {
            this.saving = false;
            this.$emit("updated");
            this.close();
        });
    }

    deactivate() {
        this.saving = true;
        ApiService.api.disableTwoFactor().then((response) => {
            this.saving = false;
            this.$emit("updated");
            this.close();
        });
    }

    close() {
        this.$emit("input", false);
    }
    

}
