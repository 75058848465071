import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import homeRoutes from '@/libs/home/routes';
import userSettingsRoutes from '@/libs/user-settings/routes';
import authentificationRoutes from '@/libs/auth/routes';
import dashboardRoutes from '@/libs/dashboard/routes';
import settingsRoutes from '@/libs/settings/routes';
import cloudRoutes from '@/libs/cloud/routes';
import auditsRoutes from '@/libs/audits/routes';
import workTasksRoutes from '@/libs/work-tasks/routes';
import responsesRoutes from '@/libs/responses/routes';
import issuesRoutes from '@/libs/issues/routes';
import measuresRoutes from '@/libs/measures/routes';
import unansweredRoutes from '@/libs/unanswered/routes';
import verificationRoutes from '@/libs/verification/routes';
import categoriesRoutes from '@/libs/categories/routes';
import workplacesRoutes from '@/libs/workplaces/routes';
import reportsRoutes from '@/libs/reports/routes';
import reviewRoutes from '@/libs/review/routes';
import adminRoutes from '@/libs/admin/routes';

Vue.use(VueRouter)

const baseRoutes = [
    
] as RouteConfig[];

const routes: RouteConfig[] = [
    ...baseRoutes,
    ...homeRoutes,
    ...dashboardRoutes,
    ...authentificationRoutes,
    ...settingsRoutes,
    ...userSettingsRoutes,
    ...cloudRoutes,
    ...auditsRoutes,
    ...workTasksRoutes,
    ...responsesRoutes,
    ...issuesRoutes,
    ...measuresRoutes,
    ...categoriesRoutes,
    ...workplacesRoutes,
    ...reportsRoutes,
    ...adminRoutes,
    ...unansweredRoutes,
    ...verificationRoutes,
    ...reviewRoutes
]

const router = new VueRouter({
    routes
})

export default router
