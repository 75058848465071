








































import { FileModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import Page from '@/Page.vue';
import axios from 'axios';
import { getAccessToken } from 'axios-jwt';
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';

@Component({})
export default class FilePreviewDialog extends Page {

    dialog = false;
    deleteDialog = false;
    // file: FileModel | null = null;
    blobUrl = "";
    zoomed = false;
    loading = false;

    @VModel() file!: { file: FileModel, fileIndex: number, canPrev: boolean, canNext: boolean };
    @Prop({ default: false }) canPrev!: boolean;
    @Prop({ default: false }) canNext!: boolean;
    @Prop({ default: false }) canEdit!: boolean;
    @Prop({ default: true }) persistent!: boolean;

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Watch("file", { immediate: true, deep: true })
    fileChanged() {
        if (this.file)
            this.getImage(this.file);
    }

    mounted() {
        if (this.file)
            this.getImage(this.file);
    }

    open (file: any) {
		this.file = file;
        this.zoomed = false;
        this.dialog = true;
    }

    getImage(file: any) {
		const targetFile = file.file;
        if (targetFile) {
			this.loading = true;
            const tenantId = authStore.useGetter(AuthGetter.GetTenant);
            const token = getAccessToken();
            axios({
                url: (window as any).ApiService.baseUrl + "/api/files/" + targetFile.id! + "/download",
                method: 'GET',
                responseType: 'blob', // important,
                headers: {
                    "TenantId": tenantId,
                    "Authorization": `Bearer ${token}`
                }
            }).then((response) => {
                this.loading = false;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.blobUrl = url;
            }).catch((e: any) => {
                this.loading = false;
                return "";
            });
        }
    }

    close () {
        this.dialog = false;
    }

    prev () {
        this.$emit("prev");
    }

    next () {
        this.$emit("next");
    }

}
