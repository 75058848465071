import {
    HttpResponse,
    TenantModel,
    QueryModel,
    RequestParams,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel,
    TenantUserModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class AdminTenantUsersDataService implements CrudService<TenantModel, TenantModel> {
    getAll(query: CrudDataQuery<TenantUserModel>): Promise<CrudEnumerableResponse<TenantUserModel>> {
        return ApiService.api.adminGetTenantUserBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<TenantModel>): Promise<CrudEnumerableResponse<TenantModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<TenantUserModel>> {
        return ApiService.api.adminGetTenantUserById(id, params);
    }

    create(item: TenantUserModel, params?: RequestParams): Promise<CrudResponse<TenantUserModel>> {
        return ApiService.api.adminInsertTenantUser(item, params);
    }

    update(item: TenantUserModel, params?: RequestParams): Promise<TenantUserModel> {
        return ApiService.api.adminUpdateTenantUser(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<TenantUserModel> {
        throw new Error('NotImplemented');
    }

    delete(query: CrudDataQuery<TenantUserModel>): Promise<CrudEnumerableResponse<TenantUserModel>> {
        return ApiService.api.adminDeleteTenantUsers(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        return ApiService.api.adminAggregationTenantUser(query, params).then((req: any) => req.data.returnValue);
    }
    
    download(query: CrudDataQuery<TenantUserModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: TenantUserModel, params?: RequestParams): Promise<CrudResponse<TenantUserModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error('NotImplemented');
    }

}
