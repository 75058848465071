











import { ImplementationStateEnum, MeasureTypeEnum, RepeatFrequencyEnum } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class ImplementationStateToggle extends Vue {

    get items() {
        return [
            { id: ImplementationStateEnum.New, name: this.$i18n.t("issueDialog.new") },
            { id: ImplementationStateEnum.InProgress, name: this.$i18n.t("issueDialog.inProgress") },
            { id: ImplementationStateEnum.WaitingForVerification, name: this.$i18n.t("issueDialog.performed") }
        ];
    }

    @Prop({ default: "" }) label!: string;
    @VModel() innerValue!: string;
    @Prop({ default: false }) mandatory!: boolean;
    
}
